import { Cell, LabelList, Pie, PieChart, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { palette } from 'styles/Global/globalStyles';

const RADIAN = Math.PI / 180;
const test = [{ title: '심폐 체력', semiTitle: '하위', value: 80 }];

// 위치
const cx = 150;
const cy = 100;
const iR = 50;
const oR = 100;
const value = 15;

const needle = (value: any, data: any, cx: any, cy: any, iR: any, oR: any, color: any, total: number) => {
    const ang = 180 * (1 - value / total); // 각도 계산
    const length = (iR + 2 * oR) / 3.5; // 바늘 길이
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
        <circle cx={x0} cy={y0} r={r} fill={color} stroke='none' />,
        <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke='#none' fill={color} />,
        <circle cx={x0} cy={y0} r={r - 2} fill='white' stroke='none' />,
    ];
};

interface NeedlePieProps {
    cx: number;
    cy: number;
    iR: number;
    oR: number;
    value: number;
    total?: number;
    benchmarkData?: any;
    data?: any;
    useIndexLabel?: boolean;
}

export const NeedlePie = ({ cx, cy, iR, oR, value, data, benchmarkData, useIndexLabel = false, total = 120 }: NeedlePieProps) => {
    let indexLable: any[] = [];

    if (useIndexLabel) {
        indexLable = Array(12)
            .fill(null)
            .map((_, index) => ({
                name: `${index + 1}`,
                value: 1,
            }));
    }
    return (
        <ResponsiveContainer aspect={1.6}>
            <PieChart>
                <Pie
                    dataKey='value'
                    startAngle={180}
                    endAngle={0}
                    data={benchmarkData}
                    cx={cx}
                    cy={cy}
                    innerRadius={iR}
                    outerRadius={oR}
                    fill='#8884d8'
                    stroke='none'
                    labelLine={true}
                    isAnimationActive={false}
                >
                    {benchmarkData && benchmarkData.map((entry: any, index: any) => <Cell key={`cell-${index}`} fill={entry.color} />)}
                    <LabelList dataKey='name' position='inside' fill='#FFFFFF' style={{ fontSize: '10px', fontWeight: 'semiBold' }} />
                </Pie>
                {useIndexLabel && (
                    <Pie
                        dataKey='value'
                        startAngle={180}
                        endAngle={0}
                        data={indexLable}
                        cx={cx}
                        cy={cy}
                        innerRadius={iR}
                        outerRadius={oR}
                        fill='none'
                        stroke='none'
                        labelLine={false}
                        isAnimationActive={false}
                    >
                        <LabelList dataKey='name' position='outside' fill='black' style={{ fontSize: '8px' }} offset={3} />
                        {indexLable.map((_, index) => (
                            <Cell key={`cell-${index}`} stroke='white' strokeWidth={0.1} />
                        ))}
                    </Pie>
                )}
                {needle(value, data, cx, cy, iR, oR, palette.PrimaryRed, total)}
            </PieChart>
        </ResponsiveContainer>
        // <PieChart width={cx * 2} height={cy * 2}>
        //     <Pie dataKey='value' startAngle={180} endAngle={0} data={data} cx={cx} cy={cy} innerRadius={iR} outerRadius={oR} fill='#8884d8' stroke='none'>
        //         {data.map((entry, index) => (
        //             <Cell key={`cell-${index}`} fill={entry.color} />
        //         ))}
        //         <LabelList dataKey='name' position='inside' fill='#FFFFFF' />
        //     </Pie>
        //     {needle(value, data, cx, cy, iR, oR, 'red')}
        // </PieChart>
    );
};

// export const NeedlePieWithDescription = () => {
//     return (
//         <Container>
//             <Wrapper>
//                 <Title>심폐 소생</Title>
//                 <ResponsiveContainer width='100%' height='100%'>
//                     <PieChart>
//                         <Pie dataKey='value' startAngle={180} endAngle={0} data={data} cx={cx} cy={cy} innerRadius={iR} outerRadius={oR} fill='#8884d8' stroke='none'>
//                             {data.map((entry, index) => (
//                                 <Cell key={`cell-${index}`} fill={entry.color} />
//                             ))}
//                             <LabelList dataKey='name' position='inside' fill='#FFFFFF' />
//                         </Pie>
//                         {needle(value, data, cx, cy, iR, oR, 'red')}
//                     </PieChart>
//                 </ResponsiveContainer>
//                 {/* <Absolute>
//                     <span>하위</span>
//                     <span>22%</span>
//                     <span>약함</span>
//                 </Absolute> */}
//             </Wrapper>
//             <TextWrapper>
//                 심폐나이 55세, 심폐체력 수준은 동일 연력 100명 중 78등입니다. 심폐체력을 우선순위 운동으로 기준하세요. 운동처방 리포트에서 제시하는 회복력향상 달리기 속도, 제시되는 150kcal 소비 시간을
//                 목표로 매일 달려보세요.
//                 <br />
//                 달리기 형태로 1회 운동 시 150kcal 이상을 소비할 수 있는 체력을 만드는 것이 1차 목표입니다.
//                 <br />
//                 4주간 운동 후 심폐체력검사를 다시 받아보세요!
//             </TextWrapper>
//         </Container>
//     );
// };

const Container = styled.div`
    display: flex;
    padding: 30px 0;
    width: 1000px;
    height: 300px;
    border: 1px solid black;
`;

const Wrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 300px;
    height: 300px;
    align-items: center;
    justify-content: center;
`;

const TextWrapper = styled.span`
    width: calc(100% - 300px);
`;

const Title = styled.div`
    font-size: 20px;
    line-height: 20px;
`;

const Absolute = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    top: 80px;
    gap: 2px;
`;
