import { VO2MaxBarChart } from 'components/Common/RowBarChart';
import { Vo2MaxDiagnosisType } from 'models/MTE/walkingAssessmentTypes';
import styled from 'styled-components';
import { palette } from 'styles/Global/globalStyles';
import { Body4_SemiBold, Caption2_Medium, Title2_SemiBold } from 'styles/Global/typography';
import { ContainerLayout, SubTitle, Title, TitleWrapper } from 'styles/ReportStyles/walkingAssessmentStyles';

export const Vo2MaxDiagnosis = (props: Vo2MaxDiagnosisType) => {
    return (
        <ContainerLayout>
            <TitleWrapper>
                <Title>진단</Title>
                <SubTitle>Diagnosis</SubTitle>
            </TitleWrapper>
            <InnerLayout>
                <VO2MaxBarChart inputData={props.vo2MaxPercentile} />
                <TableLayout>
                    <TableCell>
                        <TableHeader>
                            <TableHeaderText>심폐나이</TableHeaderText>
                        </TableHeader>
                        <TableBody>
                            <TableBodyText>{props.cardiorespiratoryAge}세</TableBodyText>
                        </TableBody>
                    </TableCell>
                    <TableCell>
                        <TableHeader>
                            <TableHeaderText>종합점수</TableHeaderText>
                        </TableHeader>
                        <TableBody>
                            <TableBodyText>{props.totalScoreResult}</TableBodyText>
                        </TableBody>
                    </TableCell>
                    <TableCell>
                        <TableHeader>
                            <TableHeaderText>메모</TableHeaderText>
                        </TableHeader>
                        <TableBody>{props.memo && <TableMemoText>{props.memo}</TableMemoText>}</TableBody>
                    </TableCell>
                </TableLayout>
            </InnerLayout>
        </ContainerLayout>
    );
};

const InnerLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1.3fr;
    gap: 20px;
    height: 90px;
`;

const TableLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr;
    gap: 10px;
`;
const TableCell = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    border: 1px solid ${palette.PrimaryBlueStrong};
    border-radius: 10px;
    overflow: hidden;
`;

const TableHeader = styled.div`
    display: flex;
    width: 100%;
    padding: 4px 0;
    justify-content: center;
    background-color: ${palette.PrimaryBlueStrong};
    color: white;
    * {
        color: inherit;
    }
`;

const TableBody = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 60px;
    text-overflow: ellipsis;
    padding: 8px 12px;
    align-items: center;
    justify-content: center;
`;

const TableHeaderText = styled(Body4_SemiBold)``;

const TableBodyText = styled(Title2_SemiBold)``;

const TableMemoText = styled(Caption2_Medium)`
    font-size: 10px;
`;
