import { TotalRadarChart } from 'components/TotalAssessment/TotalRadarChart';
import styled from 'styled-components';
import { BackgroundColor, BorderColor } from 'styles/Global/colorSemantic';
import { palette, zIndex } from 'styles/Global/globalStyles';
import { Body3_Bold, Body5_Bold, Caption1_Bold, Caption1_Medium, Caption1_Regular, Caption2_Regular, Caption2_SemiBold } from 'styles/Global/typography';
import { InnerBodyContainer, InnerBodyLayout } from 'styles/ReportStyles/reportStyles';
import HeaderLine from '../../components/Common/HeaderLine';
import MTEReportFooter from '../../components/Common/MTEReportFooter';
import MTEReportHeader from '../../components/Common/MTEReportHeader';
import ReportWarningImage from '../../assets/Imgs/Reports/ReportWarningPerson.png';
import { TotalAssesmentProps } from 'models/MTE/totalAssessmentPageTypes';
import { functionalMotionDescEnum, mobilityAssessmentDescEnum, muscleDescEnum, SPPBDescEnum, tugDescEnum, vo2maxInterpretationDescEnum } from 'models/MTE/reportInterpretationTable';
import { useEffect, useState } from 'react';
import { mobilityCaution, mobilityGood, mobilityWarning, sppbCaution, sppbGood, sppbWarning, vo2maxCaution, vo2maxGood, vo2maxWarning } from 'assets/Imgs/Reports';

const TotalAssesment = ({ handlePrint, userInfo, userAge, vo2maxData, mobilityAssessmentData, tug, sarcopeniaResultScore, SPPBAssessmentList, formattedDate, formattedTime }: TotalAssesmentProps) => {
    const [positiveEvaluation, setPositiveEvaluation] = useState<string[]>(['']);
    const [negativeEvaluation, setNegativeEvaluation] = useState<string[]>(['']);

    useEffect(() => {
        setEvaluation();
    }, [vo2maxData, mobilityAssessmentData, tug, sarcopeniaResultScore, SPPBAssessmentList]);

    // 각 부위별 img src handling
    const handleImgSrc = () => {
        // health report vo2max img src
        const vo2maxImg = () => {
            if (vo2maxData.totalScoreResult === 'Very Poor') {
                return vo2maxWarning;
            } else if (vo2maxData.totalScoreResult === 'Poor') {
                return vo2maxCaution;
            } else {
                return vo2maxGood;
            }
        };

        // health report mobility img src
        const mobilityImg = () => {
            if (mobilityAssessmentGrade()[1] === '교정필요' || mobilityAssessmentData.functionalMotion.functionalMotionGrade === '교정필요') {
                return mobilityWarning;
            } else if (mobilityAssessmentGrade()[1] === '개선필요' || mobilityAssessmentData.functionalMotion.functionalMotionGrade === '개선필요') {
                return mobilityCaution;
            } else {
                return mobilityGood;
            }
        };

        // health report sppb img src
        const sppbImg = () => {
            if (mobilityAssessmentData.muscle.sitStandGrade === '매우 약함' || sarcopeniaResultScore.explainSarcopeniaResult === '매우 약함' || tug.tugState === '위험') {
                return sppbWarning;
            } else if (mobilityAssessmentData.muscle.sitStandGrade === '약함' || sarcopeniaResultScore.explainSarcopeniaResult === '약함' || tug.tugState === '주의') {
                return sppbCaution;
            } else {
                return sppbGood;
            }
        };

        return [vo2maxImg(), mobilityImg(), sppbImg()];
    };

    // health Report 긍정, 부정평가 설정
    const setEvaluation = () => {
        const positive: string[] = [];
        const negative: string[] = [];

        if (vo2maxData.vo2MaxPercentile > 50) {
            positive.push('심폐 체력');
        } else if (vo2maxData.vo2MaxPercentile <= 50) {
            negative.push('심폐 체력');
        }

        if (mobilityAssessmentData.muscle.sitStandRanking > 50) {
            positive.push('근지구력');
        } else if (mobilityAssessmentData.muscle.sitStandRanking <= 50) {
            negative.push('근지구력');
        }

        if (tug.tugState === '양호') {
            positive.push('낙상위험');
        } else if (tug.tugState === '주의' || tug.tugState === '위험') {
            negative.push('낙상위험');
        }

        if (sarcopeniaResultScore.allSPPBScore >= 10) {
            positive.push('근감소증');
        } else if (sarcopeniaResultScore.allSPPBScore < 10) {
            negative.push('근감소증');
        }

        if (mobilityAssessmentData.hip.hipScore + mobilityAssessmentData.neck.neckScore + mobilityAssessmentData.shoulder.shoulderScore > 6) {
            positive.push('관절 가동성');
        } else if (mobilityAssessmentData.hip.hipScore + mobilityAssessmentData.neck.neckScore + mobilityAssessmentData.shoulder.shoulderScore <= 6) {
            negative.push('관절 가동성');
        }

        if (mobilityAssessmentData.functionalMotion.functionalMotionScore === 3) {
            positive.push('기능성 움직임');
        } else if (
            mobilityAssessmentData.functionalMotion.functionalMotionScore === 0 ||
            mobilityAssessmentData.functionalMotion.functionalMotionScore === 1 ||
            mobilityAssessmentData.functionalMotion.functionalMotionScore === 2
        ) {
            negative.push('기능성 움직임');
        }

        setPositiveEvaluation([positive.join(', ')]);
        setNegativeEvaluation([negative.join(', ')]);
    };

    // vo2max percentile에 따른 영어 텍스트 변환
    const exchangeVo2maxPercentileText = () => {
        if (vo2maxData.totalScoreResult === 'Superior') {
            return ['매우 훌륭함', palette.Graph.good];
        } else if (vo2maxData.totalScoreResult === 'Excellent') {
            return ['훌륭함', palette.Graph.good];
        } else if (vo2maxData.totalScoreResult === 'Good') {
            return ['강함', palette.Graph.good];
        } else if (vo2maxData.totalScoreResult === 'Fair') {
            return ['보통', palette.Graph.good];
        } else if (vo2maxData.totalScoreResult === 'Poor') {
            return ['약함', palette.Graph.caution];
        }
        return ['매우 약함', palette.Graph.danger];
    };

    // vo2max 설명
    const vo2maxDesc = () => {
        const commonDesc = `심폐나이 ${vo2maxData.cardiorespiratoryAge}세, 심폐체력 수준은 동일 연령 100명 중 ${
            vo2maxData.vo2MaxPercentile < 50 ? 100 - vo2maxData.vo2MaxPercentile : vo2maxData.vo2MaxPercentile
        }등입니다.`;

        if (vo2maxData.totalScoreResult === 'Superior') {
            return commonDesc + ' ' + vo2maxInterpretationDescEnum['매우 훌륭함'];
        } else if (vo2maxData.totalScoreResult === 'Excellent') {
            return commonDesc + ' ' + vo2maxInterpretationDescEnum['훌륭함'];
        } else if (vo2maxData.totalScoreResult === 'Good') {
            return commonDesc + ' ' + vo2maxInterpretationDescEnum['강함'];
        } else if (vo2maxData.totalScoreResult === 'Fair') {
            return commonDesc + ' ' + vo2maxInterpretationDescEnum['보통'];
        } else if (vo2maxData.totalScoreResult === 'Poor') {
            return commonDesc + ' ' + vo2maxInterpretationDescEnum['약함'];
        }
        return commonDesc + ' ' + vo2maxInterpretationDescEnum['매우 약함'];
    };
    // 근지구력 설명
    const muscleDesc = () => {
        const commonDesc = `근지구력 나이 ${mobilityAssessmentData.muscle.muscleAge}세, 동일 연령 ${mobilityAssessmentData.muscle.sitStandRanking > 50 ? '상위' : '하위'} ${
            mobilityAssessmentData.muscle.sitStandRanking > 50 ? 100 - mobilityAssessmentData.muscle.sitStandRanking : mobilityAssessmentData.muscle.sitStandRanking
        }%의 근지구력 수준입니다.`;

        return commonDesc + ' ' + muscleDescEnum[mobilityAssessmentData.muscle.sitStandGrade];
    };

    // 근지구력 배경색
    const muscleBackgroundColor = () => {
        if (
            mobilityAssessmentData.muscle.sitStandGrade === '매우 훌륭함' ||
            mobilityAssessmentData.muscle.sitStandGrade === '훌륭함' ||
            mobilityAssessmentData.muscle.sitStandGrade === '강함' ||
            mobilityAssessmentData.muscle.sitStandGrade === '보통'
        ) {
            return palette.Graph.good;
        } else if (mobilityAssessmentData.muscle.sitStandGrade === '약함') {
            return palette.Graph.caution;
        }

        return palette.Graph.danger;
    };

    // tug 설명
    const tugDesc = () => {
        const commonDesc = `의자에 앉아 3m표적 돌아오기 시간은 ${tug.tugTime}초입니다.`;
        return commonDesc + ' ' + tugDescEnum[tug.tugState];
    };

    // tug(낙상위험) Health Report PER 계산
    const tugPer = () => {
        switch (tug.tugState) {
            case '위험':
                return 1;
            case '주의':
                return 3;
            case '양호':
                if (tug.tugTime < tug.divideTime - 0.9) {
                    return 9;
                } else {
                    return 7;
                }
            default: {
                return 0;
            }
        }
    };

    // 근감소증 설명
    const sarcopeniaDesc = () => {
        const commonDesc = `SPPB검사는 12점 만점 기준 ${sarcopeniaResultScore.allSPPBScore}점입니다. 정적 균형은 ${SPPBAssessmentList[0].score}/4점, 보행속도는 ${SPPBAssessmentList[1].score}/4점, 앉아 일어서기는 ${SPPBAssessmentList[2].score}/4점입니다.`;

        return commonDesc + ' ' + SPPBDescEnum[sarcopeniaResultScore.allSPPBScoreGrade];
    };

    // 근감소증 그래프 배경색
    const sarcopeniaBackgroundColor = () => {
        if (sarcopeniaResultScore.allSPPBScoreGrade === '위험') {
            return palette.Graph.danger;
        } else if (sarcopeniaResultScore.allSPPBScoreGrade === '주의') {
            return palette.Graph.caution;
        } else {
            return palette.Graph.good;
        }
    };

    // 근감소증 Per
    const sarcopeniaPer = () => {
        switch (sarcopeniaResultScore.allSPPBScore) {
            case 1:
            case 2:
                return 1;
            case 3:
            case 4:
                return 2;
            case 5:
            case 6:
                return 3;
            case 7:
                return 4;
            case 8:
            case 9:
                return 5;
            case 10:
                return 7;
            case 11:
                return 8;
            case 12:
                return 9;
            default:
                return 0;
        }
    };

    // 관절 가동성 점수 및 등급
    const mobilityAssessmentGrade = () => {
        const allScore = mobilityAssessmentData.hip.hipScore + mobilityAssessmentData.neck.neckScore + mobilityAssessmentData.shoulder.shoulderScore;
        const allGrade = allScore > 6 ? '정상' : allScore > 3 ? '개선필요' : '교정필요';
        return [allScore, allGrade];
    };
    // 관절 가동성 PER
    const mobilityAssessmentPer = () => {
        switch (mobilityAssessmentGrade()[0]) {
            case 0:
            case 1:
                return 1;
            case 2:
                return 2;
            case 3:
                return 3;
            case 4:
            case 5:
                return 4;
            case 6:
                return 5;
            case 7:
                return 7;
            case 8:
                return 8;
            case 9:
                return 9;
            default:
                return 0;
        }
    };

    // 관절 가동성 배경색
    const mobilityAssessmentBackgroundColor = () => {
        if (mobilityAssessmentGrade()[1] === '정상') {
            return palette.Graph.good;
        } else if (mobilityAssessmentGrade()[1] === '개선필요') {
            return palette.Graph.caution;
        }
        return palette.Graph.danger;
    };

    // 관절 가동성 설명
    const mobilityAssessmentDesc = () => {
        const commonDesc = `관절 가동성은 9점 만점 기준 ${mobilityAssessmentGrade()[0]}점입니다. 경추 가동성은 ${mobilityAssessmentData.neck.neckScore}/3점(${
            mobilityAssessmentData.neck.neckGrade
        }), 어깨 가동성은 ${mobilityAssessmentData.shoulder.shoulderScore}/3점 (${mobilityAssessmentData.shoulder.shoulderGrade}), 고관절 가동성은 ${mobilityAssessmentData.hip.hipScore}/3점(${
            mobilityAssessmentData.hip.hipGrade
        })입니다.`;

        return commonDesc + ' ' + mobilityAssessmentDescEnum[mobilityAssessmentGrade()[1]];
    };

    // 관절 가동성 그래프 값 설정
    const mobilityAssessmentGraphValue = () => {
        if (mobilityAssessmentData.functionalMotion.functionalMotionScore === 0) {
            return '4';
        } else if (mobilityAssessmentData.functionalMotion.functionalMotionScore === 1) {
            return '33.33';
        } else if (mobilityAssessmentData.functionalMotion.functionalMotionScore === 2) {
            return '66.66';
        } else return '100';
    };

    // 기능성 움직임 컬러
    const functionalMotionBackgroundColor = () => {
        if (mobilityAssessmentData.functionalMotion.functionalMotionScore === 0 || mobilityAssessmentData.functionalMotion.functionalMotionScore === 1) {
            return palette.Graph.danger;
        } else if (mobilityAssessmentData.functionalMotion.functionalMotionScore === 2) {
            return palette.Graph.caution;
        }
        return palette.Graph.good;
    };

    // 기능성 움직임 Per
    const functionalMotionPer = () => {
        if (mobilityAssessmentData.functionalMotion.functionalMotionScore === 0) {
            return 1;
        } else if (mobilityAssessmentData.functionalMotion.functionalMotionScore === 1) {
            return 2;
        } else if (mobilityAssessmentData.functionalMotion.functionalMotionScore === 2) {
            return 5;
        }
        return 8;
    };

    return (
        <InnerBodyLayout>
            <HeaderLine />
            <InnerBodyContainer>
                <MTEReportHeader
                    $title='TLC MTE 검사 리포트 | 종합평가'
                    handlePrint={handlePrint}
                    $type='All'
                    userInfo={userInfo}
                    userAge={userAge}
                    formattedDate={formattedDate}
                    formattedTime={formattedTime}
                />
                <GridLayout>
                    <LeftGridContainer>
                        <LeftGridFlexContainer>
                            <LeftGridLeftWrapper>
                                <GraphTitle>심폐 체력</GraphTitle>
                                <GraphContainer>
                                    <Triangle $value={String(vo2maxData.vo2MaxPercentile)} />
                                    <GraphBase>
                                        <GraphBar $backgroundColor={exchangeVo2maxPercentileText()[1]} $value={String(vo2maxData.vo2MaxPercentile)} />
                                    </GraphBase>
                                </GraphContainer>
                                <GraphDesc>
                                    {vo2maxData.vo2MaxPercentile > 50 ? '상위' : '하위'} {vo2maxData.vo2MaxPercentile > 50 ? 100 - vo2maxData.vo2MaxPercentile : vo2maxData.vo2MaxPercentile}%
                                    <DescAlart $color={exchangeVo2maxPercentileText()[1]}>{exchangeVo2maxPercentileText()[0]}</DescAlart>
                                </GraphDesc>
                            </LeftGridLeftWrapper>
                            <LeftGridRightWrapper>{vo2maxDesc()}</LeftGridRightWrapper>
                        </LeftGridFlexContainer>
                        <LeftGridFlexContainer>
                            <LeftGridLeftWrapper>
                                <GraphTitle>근지구력</GraphTitle>
                                <GraphContainer>
                                    <Triangle $value={String(mobilityAssessmentData.muscle.sitStandRanking)} />
                                    <GraphBase>
                                        <GraphBar $backgroundColor={muscleBackgroundColor()} $value={String(mobilityAssessmentData.muscle.sitStandRanking)} />
                                    </GraphBase>
                                </GraphContainer>
                                <GraphDesc>
                                    {mobilityAssessmentData.muscle.sitStandRanking > 50 ? '상위' : '하위'}{' '}
                                    {mobilityAssessmentData.muscle.sitStandRanking > 50 ? 100 - mobilityAssessmentData.muscle.sitStandRanking : mobilityAssessmentData.muscle.sitStandRanking}%
                                    <DescAlart>{mobilityAssessmentData.muscle.sitStandGrade}</DescAlart>
                                </GraphDesc>
                            </LeftGridLeftWrapper>
                            <LeftGridRightWrapper>{muscleDesc()}</LeftGridRightWrapper>
                        </LeftGridFlexContainer>
                        <LeftGridFlexContainer>
                            <LeftGridLeftWrapper>
                                <GraphTitle>낙상위험</GraphTitle>
                                <VisibleTriangleGraphBase>
                                    <Triangle $value={tug.tugState === '위험' ? '16.67' : tug.tugState === '주의' ? '50' : '83.34'} />
                                    <GraphSegment $color={palette.Graph.danger} $flexValue='1'>
                                        위험
                                    </GraphSegment>
                                    <GraphSegment $color={palette.Graph.caution} $flexValue='1'>
                                        주의
                                    </GraphSegment>
                                    <GraphSegment $color={palette.Graph.good} $flexValue='1'>
                                        양호
                                    </GraphSegment>
                                </VisibleTriangleGraphBase>
                                <GraphDesc>
                                    {tug.tugTime} sec
                                    <DescAlart $color={tug.tugState === '위험' ? palette.Graph.danger : tug.tugState === '주의' ? palette.Graph.caution : palette.Graph.good}>{tug.tugState}</DescAlart>
                                </GraphDesc>
                            </LeftGridLeftWrapper>
                            <LeftGridRightWrapper>{tugDesc()}</LeftGridRightWrapper>
                        </LeftGridFlexContainer>
                        <LeftGridFlexContainer>
                            <LeftGridLeftWrapper>
                                <GraphTitle>근감소증</GraphTitle>
                                <VisibleTriangleGraphBase>
                                    {Array.from({ length: 12 }).map((_, index) => (
                                        <GraphDivider key={index} $idx={index}>
                                            {index === sarcopeniaResultScore.allSPPBScore - 1 && <Triangle $type='divider' $value='50' />}
                                            {/* <Triangle $type='divider' $value='50' /> */}
                                            <GraphNumber>{index + 1}</GraphNumber>
                                        </GraphDivider>
                                    ))}
                                    <GraphSegmentText $top='20%' $left='20%'>
                                        위험
                                    </GraphSegmentText>
                                    <GraphSegmentText $top='20%' $left='56%'>
                                        주의
                                    </GraphSegmentText>
                                    <GraphSegmentText $top='20%' $left='81%'>
                                        양호
                                    </GraphSegmentText>
                                </VisibleTriangleGraphBase>
                                <GraphSegmentValueTextWrapper>
                                    <GraphDesc style={{ gap: '5px', marginTop: '10px' }}>
                                        {sarcopeniaResultScore.allSPPBScore}
                                        <Caption1_Regular>/ 12</Caption1_Regular>
                                        <DescAlart $color={sarcopeniaBackgroundColor()}>{sarcopeniaResultScore.allSPPBScoreGrade}</DescAlart>
                                    </GraphDesc>
                                </GraphSegmentValueTextWrapper>
                            </LeftGridLeftWrapper>
                            <LeftGridRightWrapper>{sarcopeniaDesc()}</LeftGridRightWrapper>
                        </LeftGridFlexContainer>
                        <LeftGridFlexContainer>
                            <LeftGridLeftWrapper>
                                <GraphTitle>관절 가동성</GraphTitle>
                                <VisibleTriangleGraphBase>
                                    {Array.from({ length: 9 }).map((_, index) => (
                                        <GraphNineDivider key={index} $idx={index}>
                                            {index === mobilityAssessmentData.hip.hipScore + mobilityAssessmentData.neck.neckScore + mobilityAssessmentData.shoulder.shoulderScore - 1 && (
                                                <Triangle $type='divider' $value='50' />
                                            )}
                                            <GraphNumber>{index + 1}</GraphNumber>
                                        </GraphNineDivider>
                                    ))}
                                    <GraphSegmentText $top='20%' $left='3%'>
                                        교정필요
                                    </GraphSegmentText>
                                    <GraphSegmentText $top='20%' $left='37%'>
                                        개선필요
                                    </GraphSegmentText>
                                    <GraphSegmentText $top='20%' $left='77%'>
                                        정상
                                    </GraphSegmentText>
                                </VisibleTriangleGraphBase>
                                <GraphSegmentValueTextWrapper>
                                    <GraphDesc style={{ gap: '5px', marginTop: '10px' }}>
                                        {mobilityAssessmentGrade()[0]}
                                        <Caption1_Regular>/ 9</Caption1_Regular>
                                        <DescAlart $color={mobilityAssessmentBackgroundColor()}>{mobilityAssessmentGrade()[1]}</DescAlart>
                                    </GraphDesc>
                                </GraphSegmentValueTextWrapper>
                            </LeftGridLeftWrapper>
                            <LeftGridRightWrapper>{mobilityAssessmentDesc()}</LeftGridRightWrapper>
                        </LeftGridFlexContainer>
                        <LeftGridFlexContainer>
                            <LeftGridLeftWrapper>
                                <GraphTitle>기능성 움직임</GraphTitle>
                                <GraphContainer>
                                    <Triangle $value={mobilityAssessmentGraphValue()} />
                                    <GraphBase>
                                        <GraphBar $value={mobilityAssessmentGraphValue()} $backgroundColor={functionalMotionBackgroundColor()} />
                                    </GraphBase>
                                </GraphContainer>
                                <GraphSegmentValueTextWrapper>
                                    <GraphDesc style={{ gap: '5px' }}>
                                        {mobilityAssessmentData.functionalMotion.functionalMotionScore}
                                        <Caption1_Regular>/ 3</Caption1_Regular>
                                        <DescAlart $color={functionalMotionBackgroundColor()}>{mobilityAssessmentData.functionalMotion.functionalMotionGrade}</DescAlart>
                                    </GraphDesc>
                                </GraphSegmentValueTextWrapper>
                            </LeftGridLeftWrapper>
                            <LeftGridRightWrapper>
                                {
                                    functionalMotionDescEnum[
                                        mobilityAssessmentData.functionalMotion.functionalMotionGrade === '통증' ? '개선필요' : mobilityAssessmentData.functionalMotion.functionalMotionGrade
                                    ]
                                }
                            </LeftGridRightWrapper>
                        </LeftGridFlexContainer>
                    </LeftGridContainer>
                    <RightGridContainer>
                        <RightGridFlexContainer>
                            <HealthReportTitleWrapper>
                                <TotalRadarChart
                                    vo2maxPer={vo2maxData.vo2MaxPercentile / 10}
                                    musclePer={mobilityAssessmentData.muscle.sitStandRanking / 10}
                                    tugPer={tugPer()}
                                    functionalMotionPer={functionalMotionPer()}
                                    mobilityAssessmentPer={mobilityAssessmentPer()}
                                    sarcopeniaPer={sarcopeniaPer()}
                                />
                                Health Report
                            </HealthReportTitleWrapper>
                            <PositiveWrapper>
                                <AssemssmentTitle>
                                    <span>긍정평가</span>
                                </AssemssmentTitle>
                                {/* <ListWrapper>{positiveEvaluation}</ListWrapper> */}
                                <ListWrapper>{positiveEvaluation}</ListWrapper>
                            </PositiveWrapper>
                            <PositiveWrapper>
                                <AssemssmentTitle>
                                    <span>부정평가</span>
                                </AssemssmentTitle>
                                <ListWrapper>{negativeEvaluation}</ListWrapper>
                            </PositiveWrapper>
                        </RightGridFlexContainer>
                        <RightGridFlexContainer $type='image'>
                            <ImgWrapper src={ReportWarningImage} />
                            <Vo2maxImgWrapper src={handleImgSrc()[0]} />
                            <MobilityImgWrapper src={handleImgSrc()[1]} $top='47px' $left='97px' />
                            <MobilityImgWrapper src={handleImgSrc()[1]} $top='56px' $left='80px' />
                            <MobilityImgWrapper src={handleImgSrc()[1]} $top='117px' $left='84px' />
                            <MobilityImgWrapper src={handleImgSrc()[1]} $top='186px' $left='82px' />
                            <MobilityImgWrapper src={handleImgSrc()[1]} $top='240px' $left='80px' />
                            <SBBPImgWrapper src={handleImgSrc()[2]} />
                        </RightGridFlexContainer>
                    </RightGridContainer>
                </GridLayout>
            </InnerBodyContainer>
            <MTEReportFooter />
        </InnerBodyLayout>
    );
};

export default TotalAssesment;

const GraphContainer = styled.div`
    position: relative;
    width: 100%;
`;

const GraphTitle = styled(Body5_Bold)`
    display: flex;
    width: 100%;
    color: ${palette.basic.gray[90]};
    margin-bottom: 10px;
`;

const GraphDesc = styled(Body3_Bold)`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 5px;
`;

const DescAlart = styled(Body3_Bold)<{ $color?: string }>`
    color: ${(props) => props.$color || palette.Graph.good};
`;

const GridLayout = styled.section`
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 100%;
    height: 100%;
    gap: 28px;
    padding: 6px 0;
`;

const LeftGridContainer = styled.div`
    display: grid;
    grid-template-rows: repeat(6, 6fr);
    width: 100%;
    height: 100%;
`;

const LeftGridFlexContainer = styled.div`
    display: flex;
    height: 100%;
    padding: 5px 0;
    border-bottom: 1px solid ${BorderColor.color_border_light};
    gap: 32px;
    &:last-child {
        border: none;
    }
`;

const LeftGridLeftWrapper = styled(Caption2_Regular)`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    flex: 1;
    padding-top: 18px;
`;

const LeftGridRightWrapper = styled(Caption2_Regular)`
    display: flex;
    flex: 1.6;
    height: 100%;
    padding: 0;
    line-height: 18px;
    align-items: center;
`;

const RightGridContainer = styled.div`
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    gap: 12px;
    padding: 4px 0;
`;

const RightGridFlexContainer = styled.div<{ $type?: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: ${(props) => (props.$type === 'image' ? '0' : '16px 12px 8px 12px')};
    background-color: ${palette.PrimaryBlueWeak};
    border: 1px solid ${BorderColor.color_border_neutral};
    border-radius: 6px;
    gap: 12px;
`;

const ImgWrapper = styled.img`
    width: 100%;
    height: 418px;
    object-fit: contain;
    padding: 12px;
`;

const Vo2maxImgWrapper = styled.img`
    position: absolute;
    width: 15px;
    flex: 1;
    right: 28px;
    top: 75px;
    object-fit: contain;
    z-index: ${zIndex.floating};
`;
const SBBPImgWrapper = styled.img`
    position: absolute;
    width: 15px;
    flex: 1;
    right: 28px;
    top: 169px;
    object-fit: contain;
    z-index: ${zIndex.floating};
`;
const MobilityImgWrapper = styled.img<{ $top: string; $left: string }>`
    position: absolute;
    width: 10px;
    flex: 1;
    top: ${(props) => props.$top};
    left: ${(props) => props.$left};
    object-fit: contain;
    z-index: ${zIndex.floating};
`;

const HealthReportTitleWrapper = styled(Body3_Bold)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: ${palette.basic.gray[80]};
    gap: 4px;
`;

const PositiveWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const AssemssmentTitle = styled(Caption1_Bold)`
    display: flex;
    gap: 4px;
    align-items: center;
    padding-left: 6px;
`;

const ListWrapper = styled(Caption1_Medium)`
    display: flex;
    align-items: center;
    width: 100%;
    background-color: ${BackgroundColor.color_background_white};
    padding: 8px 10px;
    border-radius: 6px;
    min-height: 34px;
`;

const GraphBase = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 30px;
    background-color: ${BackgroundColor.color_background_heavy};
    margin-bottom: 8px;
    border-radius: 6px;
    overflow: hidden;
`;
const VisibleTriangleGraphBase = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 30px;
    background-color: ${BackgroundColor.color_background_heavy};
    margin-bottom: 10px;
    border-radius: 6px;
`;

const GraphSegment = styled(Caption1_Bold)<{ $color: string; $flexValue: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex: ${(props) => props.$flexValue};
    background-color: ${(props) => props.$color};
    &:first-of-type {
        border-radius: 6px 0 0 6px;
    }

    &:last-of-type {
        border-radius: 0 6px 6px 0;
    }
`;
const GraphSegmentText = styled(Caption2_SemiBold)<{ $top: string; $left: string }>`
    position: absolute;
    top: ${(props) => props.$top};
    left: ${(props) => props.$left};
    color: white;
    z-index: 1;
`;

const GraphDivider = styled.div<{ $idx: number }>`
    position: relative;
    flex: 1;
    background-color: ${(props) => (props.$idx < 6 ? palette.Graph.danger : props.$idx < 9 ? palette.Graph.caution : palette.Graph.good)};

    &:first-of-type {
        border-radius: 6px 0 0 6px;
    }
    &:last-of-type {
        border-radius: 0 6px 6px 0;
    }
`;
const GraphNineDivider = styled.div<{ $idx: number }>`
    position: relative;
    flex: 1;
    background-color: ${(props) => (props.$idx < 3 ? palette.Graph.danger : props.$idx < 6 ? palette.Graph.caution : palette.Graph.good)};

    &:first-of-type {
        border-radius: 6px 0 0 6px;
    }
    &:last-of-type {
        border-radius: 0 6px 6px 0;
    }
`;

const GraphNumber = styled.span`
    position: absolute;
    font-size: 9px;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    color: ${palette.basic.gray[60]};
`;

const GraphBar = styled.div<{ $value: string; $backgroundColor?: string }>`
    position: absolute;
    width: ${(props) => `${props.$value}%`};
    height: 100%;
    background-color: ${(props) => props.$backgroundColor || palette.Graph.good};
`;

const Triangle = styled.div<{ $type?: string; $value: string }>`
    position: absolute;
    top: -10px;
    left: ${(props) => (props.$type ? `calc(${props.$value}% - 4px)` : `calc(${props.$value}% - 4px)`)};
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 7px solid #6c757d;
    transform: rotate(180deg);
`;

const GraphSegmentValueTextWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 5px;
`;
