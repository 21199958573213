import styled from 'styled-components';
import { BackgroundColor, BorderColor, InverseColor, OpacityColor, PrimaryColor } from 'styles/Global/colorSemantic';
import Mo_ico_logo from '../../Icons/Mo_ico_logo';
import { Body1_Bold, Caption1_SemiBold, Caption2_Medium } from '../../styles/Global/typography';
import { MTEHeaderPropsType } from 'models/MTE/MTEHeaderTypes';
import { font } from 'styles/Global/globalStyles';
import { useNavigate } from 'react-router-dom';
import { pagePaths } from 'constants/path';

const MTEReportHeader = ({ $title, handlePrint, $type, userInfo, userAge, formattedDate, formattedTime }: MTEHeaderPropsType) => {
    const navigate = useNavigate();

    return (
        <Layout>
            <TitleWrapper>
                <Title>{$title}</Title>
                {$type === 'All' && <Button onClick={() => navigate(pagePaths.userInformation, { replace: true })}>초기 화면으로 이동</Button>}
                {$type === 'All' && <Button onClick={handlePrint}>PDF 저장</Button>}
                <Mo_ico_logo />
            </TitleWrapper>
            <InfoContainer>
                <ColumnWrapper>
                    <RowWrapper>
                        <InfoTitle>이름</InfoTitle>
                        <ColumnDivider />
                        <InfoValue>{userInfo.name}</InfoValue>
                    </RowWrapper>
                    <RowWrapper>
                        <InfoTitle>연령</InfoTitle>
                        <ColumnDivider />
                        <InfoValue>{userAge}</InfoValue>
                    </RowWrapper>
                </ColumnWrapper>
                <ColumnWrapper>
                    <RowWrapper>
                        <InfoTitle>신장</InfoTitle>
                        <ColumnDivider />
                        <InfoValue>{String(userInfo.height)}cm</InfoValue>
                    </RowWrapper>
                    <RowWrapper>
                        <InfoTitle>체중</InfoTitle>
                        <ColumnDivider />
                        <InfoValue>{String(userInfo.weight)}kg</InfoValue>
                    </RowWrapper>
                </ColumnWrapper>
                <ColumnWrapper>
                    <RowWrapper>
                        <InfoTitle>성별</InfoTitle>
                        <ColumnDivider />
                        <InfoValue>{userInfo.gender === 'female' ? '여' : '남'}</InfoValue>
                    </RowWrapper>
                    <RowWrapper>
                        <InfoTitle>시각</InfoTitle>
                        <ColumnDivider />
                        <InfoValue>{formattedTime}</InfoValue>
                    </RowWrapper>
                </ColumnWrapper>
                <ColumnWrapper>
                    <RowWrapper>
                        <InfoTitle $type='date'>날짜</InfoTitle>
                        <ColumnDivider />
                        <InfoValue>{formattedDate}</InfoValue>
                    </RowWrapper>
                    <RowWrapper>
                        <InfoTitle>연락처</InfoTitle>
                        <ColumnDivider />
                        <InfoValue>{userInfo.mobile}</InfoValue>
                    </RowWrapper>
                </ColumnWrapper>
                <ColumnWrapper>
                    <RowWrapper>
                        <ColumnDivider />
                        <InfoValue>{userInfo.managerName}</InfoValue>
                        <InfoTitle>선생님</InfoTitle>
                    </RowWrapper>
                </ColumnWrapper>
            </InfoContainer>
        </Layout>
    );
};

export default MTEReportHeader;

const Title = styled(Body1_Bold)``;

const ColumnDivider = styled.div`
    width: 1px;
    height: 100%;
    background-color: ${BorderColor.color_border_normal};
`;

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const InfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 68px;
    align-items: center;
    border: 1px solid ${BorderColor.color_border_strong};
    border-radius: 6px;
    padding: 8px 20px;
    margin: 12px 0;
`;

const InfoTitle = styled(Caption2_Medium)<{ $type?: string }>`
    width: ${(props) => props.$type && '31.4px'};
`;

const InfoValue = styled(Caption1_SemiBold)``;

const RowWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    gap: 10px;
`;

const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2px;
    height: 100%;
`;

const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    height: 100%;
    border-radius: 4px;
    font-size: ${font.size[16]};
    font-weight: ${font.weight.medium};
    background-color: ${PrimaryColor.color_primary_normal};
    color: ${InverseColor.color_inverse_white};
    &:hover {
        background-color: ${PrimaryColor.color_primary_strong};
        color: ${InverseColor.color_inverse_white};
    }
    &:active {
        background-color: ${PrimaryColor.color_primary_heavy};
        color: ${InverseColor.color_inverse_white};
    }
    &:focus {
        background-color: ${PrimaryColor.color_primary_heavy};
        color: ${InverseColor.color_inverse_white};
    }
    &:disabled {
        background-color: ${BackgroundColor.color_background_primary_disabled}; // disabled 상태일 때의 배경색
        color: ${OpacityColor.color_opacity_white_80};
        border: none;
        cursor: not-allowed;
    }

    @media print {
        display: none;
    }
`;
