import styled from 'styled-components';
import { BorderColor } from 'styles/Global/colorSemantic';

const Divider = styled.div<{ color?: string }>`
    width: 100%;
    height: 1px;
    background-color: ${({ color }) => color || BorderColor.color_border_neutral};
`;

export const MarginDivider14Px = styled(Divider)`
    margin-top: 14px;
    margin-bottom: 14px;
`;

export default Divider;
