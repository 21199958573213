import styled from 'styled-components';
import { palette } from 'styles/Global/globalStyles';

export interface RadarDataModel {
    label?: string;
    value: number;
    key: string;
}

export interface RadarProps {
    size: number;
    data: RadarDataModel[];
    // style?: React.CSSProperties;
}

///그래프 최대 값
const MAX_VALUE = 11;

/// 백그라운드 폴리곤 갯수
const BACKGROUND_POLYGON = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];

export const RadarWithLabel = ({ size, data }: RadarProps) => {
    const center = size / 2;
    const centerX = center;
    const centerY = center;
    const radius = center * 0.7;

    // 데이터 갯수에 따른 꼭지점 위치 각도
    const angleStep = (Math.PI * 2) / data.length;

    const getPolygonPoints = (scale: number) => {
        return data
            .map((_, index) => {
                const angle = index * angleStep - Math.PI / 2;
                const x = centerX + radius * Math.cos(angle) * scale;
                const y = centerY + radius * Math.sin(angle) * scale;
                return `${x},${y}`;
            })
            .join(' ');
    };

    // 데이터 갯수에 따른 꼭지점 위치
    const points = data.map((item, index) => {
        const angle = index * angleStep - Math.PI / 2;
        const x = centerX + radius * Math.cos(angle) * (item.value / MAX_VALUE);
        const y = centerY + radius * Math.sin(angle) * (item.value / MAX_VALUE);
        return { x, y };
    });

    const polygonPoints = points.map((p) => `${p.x},${p.y}`).join(' ');

    // 축 위치
    const axisPoints = data.map((_, index) => {
        const angle = index * angleStep - Math.PI / 2;
        const x = centerX + radius * Math.cos(angle);
        const y = centerY + radius * Math.sin(angle);
        return { x, y };
    });

    return (
        <HealthReportPolygonLayout $size={size}>
            <svg width={size} height={size}>
                {/* Background polygons */}
                {BACKGROUND_POLYGON.map((scale, index) => (
                    <polygon key={index} points={getPolygonPoints(scale)} fill='none' stroke='#000' strokeWidth='0.5' />
                ))}

                {/* Axis lines */}
                {axisPoints.map((point, index) => (
                    <line key={index} x1={centerX} y1={centerY} x2={point.x} y2={point.y} stroke='#000' strokeWidth='0.5' strokeDasharray={`${1 * (size / 200)} ${1 * (size / 200)}`} />
                ))}

                {/* center dot */}
                <circle key='centerDot' cx={centerX} cy={centerY} r={size * 0.008} fill='#000'></circle>

                {/* Data polygon */}
                <polygon points={polygonPoints} fill={`${palette.PrimaryBlue}22`} stroke='#000' strokeWidth='1' />

                {/* Dots at vertices */}
                {/* {points.map((point, index) => (
        <circle key={index} cx={point.x} cy={point.y} r="4" fill="#8884d8" />
      ))} */}

                {/* Labels */}
                {data.map((item, index) => {
                    const angle = index * angleStep - Math.PI / 2;
                    const lines = item.label?.split('\n') ?? [];
                    const isContainNewLine = lines.length > 1;
                    const x = centerX + (radius + (isContainNewLine ? 12 : 13) * (size / 100)) * Math.cos(angle);
                    const y = centerY + (radius + (isContainNewLine ? 3 : 7) * (size / 100)) * Math.sin(angle);
                    return (
                        <text key={index} x={x} y={y} textAnchor='middle' dominantBaseline='middle' fontSize='10' fontWeight='bold' fontFamily='Pretendard'>
                            {lines.map((line, i) => (
                                <tspan key={i} x={x} dy={i === 0 ? 0 : '1.1em'}>
                                    {line}
                                </tspan>
                            ))}
                        </text>
                    );
                })}

                {data.map((value, index) => {
                    const angle = index * angleStep - Math.PI / 2;
                    const x = centerX + radius * Math.cos(angle);
                    const y = centerY + radius * Math.sin(angle);
                    return (
                        <g key={value.key}>
                            <circle cx={x} cy={y} r={size * 0.007} fill={palette.basic.black} />
                        </g>
                    );
                })}
            </svg>
        </HealthReportPolygonLayout>
    );
};

export const Radar = ({ size, data }: RadarProps) => {
    const centerX = size / 2;
    const centerY = size / 1.8;
    const radius = (size / 2) * 0.95;
    const angleStep = (Math.PI * 2) / data.length;

    const getPolygonPoints = (scale: number) => {
        return data
            .map((_, index) => {
                const angle = index * angleStep - Math.PI / 2;
                const x = centerX + radius * Math.cos(angle) * scale;
                const y = centerY + radius * Math.sin(angle) * scale;
                return `${x},${y}`;
            })
            .join(' ');
    };

    const points = data.map((item, index) => {
        const angle = index * angleStep - Math.PI / 2;
        const x = centerX + radius * Math.cos(angle) * (item.value / MAX_VALUE);
        const y = centerY + radius * Math.sin(angle) * (item.value / MAX_VALUE);
        return { x, y };
    });

    const polygonPoints = points.map((p) => `${p.x},${p.y}`).join(' ');

    const axisPoints = data.map((_, index) => {
        const angle = index * angleStep - Math.PI / 2;
        const x = centerX + radius * Math.cos(angle);
        const y = centerY + radius * Math.sin(angle);
        return { x, y };
    });

    return (
        <RadarPolygonLayout $size={size}>
            <svg width={size} height={size}>
                {/* Background polygons */}
                {BACKGROUND_POLYGON.map((scale, index) => (
                    <polygon key={index} points={getPolygonPoints(scale)} fill='none' stroke='#000' strokeWidth='0.5' />
                ))}

                {/* Axis lines */}
                {axisPoints.map((point, index) => (
                    <line key={index} x1={centerX} y1={centerY} x2={point.x} y2={point.y} stroke='#000' strokeWidth='0.5' strokeDasharray={`${1 * (size / 100)} ${1 * (size / 100)}`} />
                ))}

                <circle key='centerDot' cx={centerX} cy={centerY} r={size * 0.008} fill='#000'></circle>

                {/* Data polygon */}
                <polygon points={polygonPoints} fill={`${palette.PrimaryBlue}33`} stroke='#000' strokeWidth='1.3' />

                {/* 그래프 꼭지점 */}
                {data.map((value, index) => {
                    const angle = index * angleStep - Math.PI / 2;
                    const x = centerX + radius * Math.cos(angle);
                    const y = centerY + radius * Math.sin(angle);
                    return (
                        <g key={value.key}>
                            <circle cx={x} cy={y} r={size * 0.025} fill={palette.PrimaryBlue} />
                            <text x={x} y={y} fill='#fff' fontWeight='bold' fontSize={size * 0.04} textAnchor='middle' dominantBaseline='central'>
                                {value.key}
                            </text>
                        </g>
                    );
                })}
            </svg>
        </RadarPolygonLayout>
    );
};

const HealthReportPolygonLayout = styled.div<{ $size: number }>`
    display: flex;
    junstify-content: flex-start;
    align-items: center;
    width: ${(props) => props.$size}px;
    height: ${(props) => props.$size}px;
`;

const RadarPolygonLayout = styled.div<{ $size: number }>`
    position: absolute;
    display: flex;
    top: 8px;
    left: 178px;
    junstify-content: flex-start;
    align-items: center;
    width: ${(props) => props.$size}px;
    height: ${(props) => props.$size}px;
`;
