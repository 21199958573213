export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]{1,})\S*$/i;
export const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*_=+-]{8,}$/;
export const NUMBER_REGEX = /^[0-9]{0,}$/;
export const HEIGHT_REGEX = /^\d{0,3}(\.\d{0,1})?$/;
export const CHANGE_MOBILE_NUMBER_11_REGEX = /(\d{3})(\d{4})(\d{4})/; // /(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"
export const CHANGE_MOBILE_NUMBER_10_REGEX = /(\d{3})(\d{3})(\d{4})/; // /(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"
export const SPACE_REGEX = /\s/;

export const NAME_REGEX = /^[a-zA-Z가-힣0-9\s]*$/;
export const SIGN_UP_NAME_REGEX = /^[a-zA-Z가-힣0-9ㄱ-ㅎㅏ-ㅣ~`!@#$%^&*()-_=+[\]{}|;:'",.<>/?]*$/;
export const MOBILE_REGEX = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/; // /(^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/;
export const MOBILE_REGEX_EDIT_INFO = /^010([0-9]{4})([0-9]{4})$/;
export const PHONE_REGEX = /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/; // /(^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/;
export const DECIMAL_POINT_NUMBER_REGEX = /^[0-9.]{0,}$/;
// 소수점 둘째 자리까지만 허용하는 정규표현식
export const DECIMAL_RESTRICTION = /^[\d]*\.?[\d]{0,2}$/;

//정수 4자리, 소수점 2자리, 공백 까지만 허용하는 정규표현식
export const NUMBER_4_DIGIT_1_REGEX = /^[0-9]{0,4}(\.[0-9]{0,2})?$/;

export const NUMBER_3_REGEX = /^[0-9]{0,3}$/;

export const BIRTHDATE_REGEX = /^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/;

// export const BIRTHDATE_REGEX = /^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/;
