import { InnerBodyContainer, InnerBodyLayout } from 'styles/ReportStyles/reportStyles';
import HeaderLine from '../../components/Common/HeaderLine';
import Header from '../../components/Common/MTEReportHeader';
import MTEReportFooter from '../../components/Common/MTEReportFooter';
import MobilityAssessmentLists from 'components/MobilityAssessment/MobilityAssessmentResult';
import MobilityAssessmentGraph from 'components/MobilityAssessment/MobilityAssessmentGraph';
import MobilityAssessmentSitUp from 'components/MobilityAssessment/MobilityAssessmentSitUp';
import { MobilityAssessmentProps } from 'models/MTE/mobilityAssessmentTypes';

const MobilityAssessment = ({ data, userInfo, userAge, formattedDate, formattedTime }: MobilityAssessmentProps) => {
    return (
        <InnerBodyLayout>
            <HeaderLine />
            <InnerBodyContainer>
                <Header $title='TLC MTE 검사 리포트 | 움직임/근지구력 평가' userInfo={userInfo} userAge={userAge} formattedDate={formattedDate} formattedTime={formattedTime} />
                <MobilityAssessmentLists mobilityData={data} />
                <MobilityAssessmentGraph mobilityData={data} />
                <MobilityAssessmentSitUp mobilityData={data} />
            </InnerBodyContainer>
            <MTEReportFooter />
        </InnerBodyLayout>
    );
};

export default MobilityAssessment;
