import { ChartTypes } from 'models/MTE/walkingAssessmentTypes';
import { Bar, BarChart, Cell, LabelList, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { palette } from 'styles/Global/globalStyles';

export const ColumnBarChart = ({ averageStandard = 12, ...props }: ChartTypes.ChartInputDataType) => {
    const LineData = Array.from({ length: averageStandard }, (data, index) => Math.ceil((index * 100) / averageStandard));
    const axisColor = '#E0E0E0';
    const colors = [`${palette.PrimaryBlueStrong}`, `${palette.PrimaryBlue}`];

    const standardDataArray: ChartTypes.StandardArrayType[] = props.overallData
        .sort((a, b) => a - b)
        .map((data, index) => {
            return {
                index: index + index + 1,
                value: data,
            };
        });

    const speedData = [
        {
            name: '평균',
            data: 50,
            label: props.averageData.toFixed(2),
        },
        {
            name: '기록',
            data: props.percentile,
            label: props.inputData.toFixed(2),
        },
    ];

    const subCategoryData = [
        {
            name: 'G',
            data: 85,
        },
        {
            name: 'F',
            data: 50,
        },
        {
            name: 'P',
            data: 15,
        },
    ];

    const categoryTick = (props: any) => {
        const { x, y, payload } = props;
        return (
            <text x={x} y={y} dy={10} textAnchor='middle' fill={`${palette.PrimaryBlueStrong}`} fontSize={12} fontFamily='Pretendard' fontWeight={700}>
                {payload.value}
            </text>
        );
    };

    const groupRangeTick = (props: any) => {
        const { x, y, payload } = props;
        const index = LineData.findIndex((data) => data === payload.value);
        const selectedData = standardDataArray.find((data) => data.index === index);

        return (
            <text x={x} y={y} dy={5} textAnchor='end' fill={`${palette.PrimaryBlueStrong}`} fontSize={10} fontFamily='Pretendard' fontWeight={500}>
                {selectedData?.value}
            </text>
        );
    };

    const subCategoryTick = (props: any) => {
        const { x, y, payload } = props;
        const result = subCategoryData.find((data) => data.data === payload.value);
        return (
            <text x={x} y={y} dy={5} textAnchor='middle' fill={`${palette.PrimaryBlueStrong}`} fontSize={12} fontFamily='Pretendard' fontWeight={700}>
                {result?.name}
            </text>
        );
    };

    const CustomLabel = (props: any) => {
        const { x, y, value } = props;

        return (
            <text
                x={x + 12}
                y={y + 15}
                fill='#FFFFFF'
                style={{
                    fontSize: '8px',
                    fontFamily: 'Pretendard',
                    fontWeight: 400,
                    textAlign: 'center',
                    alignContent: 'center',
                }}
                textAnchor='middle'
            >
                {value}
            </text>
        );
    };

    return (
        <ResponsiveContainer width='100%' height='100%'>
            <BarChart layout='horizontal' margin={{ top: 0, right: 0, left: 0, bottom: 0 }} data={speedData} barSize={25}>
                {LineData.map((data, _) => {
                    return <ReferenceLine y={data} stroke={axisColor} key={data} />;
                })}
                <YAxis type='number' ticks={LineData} height={0} tickLine={false} tickMargin={0} axisLine={{ stroke: axisColor }} minTickGap={1} width={30} tick={groupRangeTick} />
                <YAxis
                    type='number'
                    yAxisId={1}
                    ticks={[15, 50, 85]}
                    domain={[0, 100]}
                    minTickGap={1}
                    tickLine={false}
                    tickMargin={0}
                    tick={subCategoryTick}
                    axisLine={false}
                    width={15}
                    padding={{ bottom: 0, top: 0 }}
                />
                <XAxis dataKey='name' type='category' height={20} axisLine={{ stroke: axisColor }} tickLine={false} orientation='bottom' tick={categoryTick} />

                <Bar dataKey='data'>
                    {speedData.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} />
                    ))}
                    <LabelList dataKey='label' content={<CustomLabel />} />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};
