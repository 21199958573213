import { calculateAge } from 'functions/calculateAge';
import { findApproximationIndex, removeDecimal, roundDecimal } from 'functions/calculateFunctions';
import { getDistanceFromVo2Max } from 'functions/calculateVO2max';
import { getReportResponseType } from 'models/MTE/reportApiTypes';
import {
    AgeGroup,
    AgeToAgeGroupFunc,
    GetAvgVo2MaxFromAgeFunc,
    GetMETFunc,
    GetPercentileFunc,
    gradeIndexEnum,
    KH6minuteSpeedFunc,
    OptimizedProgramType,
    PercentileToGradeFunc,
    RunningIntensityType,
    Vo2MaxDiagnosisType,
    vo2MaxTable,
    WalkingAssessmentTableType,
    WalkingSpeedAverageType,
} from 'models/MTE/walkingAssessmentTypes';
import { Dispatch, SetStateAction } from 'react';

// 심폐지구력 진단 쪽 구하는 함수
export const Vo2MaxDiagnosisTypeFunc = (reportData: getReportResponseType, setstate: Dispatch<SetStateAction<Vo2MaxDiagnosisType>>) => {
    const respiratory = reportData.data[0].respiratory;
    const age = parseInt(calculateAge(reportData.birthday.slice(2)).justAgeString.replace('세', ''));
    const vo2MaxPercentile = getPercentile(respiratory.vo2Max, age, reportData.gender);
    const totalScoreResult = percentileToGrade(vo2MaxPercentile);
    setstate({
        memo: reportData.memo,
        cardiorespiratoryAge: getCardiorespiratoryAge(respiratory.vo2Max, reportData.gender),
        totalScoreResult: totalScoreResult,
        vo2MaxPercentile: vo2MaxPercentile,
        vo2Max: respiratory.vo2Max,
    });
};

// 심폐지구력 평균치 비교 쪽 함수
export const WalkingSpeedAverageFunc = (reportData: getReportResponseType, setstate: Dispatch<SetStateAction<WalkingSpeedAverageType>>) => {
    const respiratory = reportData.data[0].respiratory;
    const gender = reportData.gender;
    const age = parseInt(calculateAge(reportData.birthday.slice(2)).justAgeString.replace('세', ''));
    const walkDistance = respiratory.walkDistance
        ? respiratory.walkDistance
        : getDistanceFromVo2Max({ age: age, gender: gender, height: reportData.height, vo2Max: respiratory.vo2Max, weight: reportData.weight });
    const vo2MaxAvg = getAvgVo2MaxFromAge(age, gender);
    const distanceAvg = removeDecimal(getDistanceFromVo2Max({ age: age, gender: gender, height: reportData.height, vo2Max: vo2MaxAvg, weight: reportData.weight }));
    const walkingSpeed = getKHSpeedFrom6minute(walkDistance);
    const avgWalkingSpeed = removeDecimal((distanceAvg / 360) * 3.6, 1);
    const percentile = getPercentile(respiratory.vo2Max, age, gender);
    const distanceOverallData = ageGroupVo2MaxOverallData(age, gender, (data) =>
        removeDecimal(getDistanceFromVo2Max({ age: age, gender: gender, height: reportData.height, vo2Max: data, weight: reportData.weight }))
    );
    const speedOverallData = distanceOverallData.map((data) => removeDecimal((data / 360) * 3.6));
    setstate({
        percentile: percentile,
        speedChartProps: {
            averageData: avgWalkingSpeed,
            percentile: percentile,
            overallData: speedOverallData,
            inputData: walkingSpeed,
        },
        distanceChartProps: {
            averageData: distanceAvg,
            percentile: percentile,
            overallData: distanceOverallData,
            inputData: walkDistance,
        },
    });
};

// 6분 걷기 검사 셀 구하는 함수
export const WalkingAssessmentTableFunc = (reportData: getReportResponseType, setstate: Dispatch<SetStateAction<WalkingAssessmentTableType>>) => {
    const respiratory = reportData.data[0].respiratory;
    const gender = reportData.gender;
    const age = parseInt(calculateAge(reportData.birthday.slice(2)).justAgeString.replace('세', ''));
    const mets = getMET(respiratory.vo2Max);
    const walkDistance = respiratory.walkDistance
        ? respiratory.walkDistance
        : getDistanceFromVo2Max({ age: age, gender: gender, height: reportData.height, vo2Max: respiratory.vo2Max, weight: reportData.weight });
    const speed = getKHSpeedFrom6minute(walkDistance);
    const vo2Average = getAvgVo2MaxFromAge(age, reportData.gender);
    setstate({
        distance: walkDistance,
        bidAfter: respiratory.bdiAfter,
        bidBefore: respiratory.bdiBefore,
        hrAfter: respiratory.hrAfter,
        hrBefore: respiratory.hrBefore,
        mets: mets,
        speed: speed,
        spo2After: respiratory.spo2After,
        spo2Before: respiratory.spo2Before,
        vo2Average: vo2Average,
        vo2Max: respiratory.vo2Max,
    });
};

export const RunningIntensityFunc = (reportData: getReportResponseType, setState: Dispatch<SetStateAction<RunningIntensityType>>) => {
    const respiratory = reportData.data[0].respiratory;
    const burnFatExKH = getTrademillBurnFatExerciseKH(respiratory.vo2Max);
    const aerobicExKH = getTrademillAerobicExerciseKH(respiratory.vo2Max);
    const highIntensityExKH = getTrademillHighIntensityExerciseKH(respiratory.vo2Max);
    const circulatorySafezoneGuide = getSafezoneGuide({ vo2Max: respiratory.vo2Max, safezoneType: 'circulatory' });
    const metabolicSafezoneGuide = getSafezoneGuide({ vo2Max: respiratory.vo2Max, safezoneType: 'metabolic' });
    setState({
        circulatorySafezoneGuide: {
            min: circulatorySafezoneGuide.min,
            max: circulatorySafezoneGuide.max,
        },
        metabolicSafezoneGuide: {
            min: metabolicSafezoneGuide.min,
            max: metabolicSafezoneGuide.max,
        },
        outdoorGoalHigh: {
            kilometer: roundDecimal(getTrademillHighIntensityExerciseKH(respiratory.vo2Max + 3.5) / 4, 1),
            minute: 15,
        },
        outdoorGoalLow: {
            minute: 30,
            kilometer: roundDecimal(burnFatExKH / 2, 1),
        },
        outdoorGoalMid: {
            kilometer: roundDecimal(aerobicExKH / 3, 1),
            minute: 20,
        },
        targetAerobicExercise: {
            kcalPerMinute: getTrademillExerciseKcalPerMinute({ kiometerPerHour: aerobicExKH, weight: reportData.weight }),
            kilometerPerHour: aerobicExKH,
        },
        targetBurnFatExercise: {
            kcalPerMinute: getTrademillExerciseKcalPerMinute({ kiometerPerHour: burnFatExKH, weight: reportData.weight }),
            kilometerPerHour: burnFatExKH,
        },
        targetHighIntensityExercise: {
            kcalPerMinute: getTrademillExerciseKcalPerMinute({ kiometerPerHour: highIntensityExKH, weight: reportData.weight }),
            kilometerPerHour: highIntensityExKH,
        },
    });
};

export const OptimizedProgramFunc = (reportData: getReportResponseType, setState: Dispatch<SetStateAction<OptimizedProgramType>>) => {
    const respiratory = reportData.data[0].respiratory;
    const burnFatExKH = getTrademillBurnFatExerciseKH(respiratory.vo2Max);
    const highIntensityExKH = getTrademillHighIntensityExerciseKH(respiratory.vo2Max);
    const repetition = getRunningIntensityRepetitions({ burnFatEx: burnFatExKH, highIntensityEx: highIntensityExKH });
    const age = parseInt(calculateAge(reportData.birthday.slice(2)).justAgeString.replace('세', ''));
    const zone1HR = getGoalHR({ beforeHR: respiratory.hrBefore, age: age, zoneType: 'zoneOne' });
    const zone2HR = getGoalHR({ beforeHR: respiratory.hrBefore, age: age, zoneType: 'zoneTwo' });
    setState({
        recoveryRun: burnFatExKH,
        heartRateZone: {
            zone1: {
                min: zone1HR.min,
                max: zone1HR.max,
                kcalPerMinute: getZoneKcalPerMinute({ weight: reportData.weight, vo2Max: respiratory.vo2Max, zoneType: 'zoneOne' }),
            },
            zone2: {
                min: zone2HR.min,
                max: zone2HR.max,
                kcalPerMinute: getZoneKcalPerMinute({ weight: reportData.weight, vo2Max: respiratory.vo2Max, zoneType: 'zoneTwo' }),
            },
        },
        highIntensityRun: {
            burnFat: {
                kilometerPerHour: burnFatExKH,
                kcalPerMinute: getTrademillExerciseKcalPerMinute({ kiometerPerHour: burnFatExKH, weight: reportData.weight }),
            },
            highIntensity: {
                kilometerPerHour: highIntensityExKH,
                kcalPerMinute: getTrademillExerciseKcalPerMinute({ kiometerPerHour: highIntensityExKH, weight: reportData.weight }),
            },
            repetition: {
                min: repetition.minRep,
                max: repetition.maxRep,
            },
        },
    });
};

/// 해당 나이 그룹의 VO2Max 백분위 값 반환(1 ~ 99)
const findAgeGroupVo2MaxArray = (ageGroup: string, gender: 'male' | 'female'): number[] => {
    switch (gender) {
        case 'male':
            return vo2MaxTable.male[ageGroup].vo2MaxArray;
        case 'female':
            return vo2MaxTable.female[ageGroup].vo2MaxArray;
    }
};

const ageToAgeGroup: AgeToAgeGroupFunc = (age) => {
    if (age < 20) return '2';
    if (age > 79) return '7';
    const tenDigitNumber = age.toString().slice(0, 1);
    const result = AgeGroup.find((ageGroup) => ageGroup === tenDigitNumber);
    return result ? result : '7';
};

// 백분위 값에 따른 등급 반환 함수
export const percentileToGrade: PercentileToGradeFunc = (percentile) => {
    if (percentile >= 95) return 'Superior';
    if (percentile >= 80) return 'Excellent';
    if (percentile >= 60) return 'Good';
    if (percentile >= 40) return 'Fair';
    if (percentile >= 20) return 'Poor';
    return 'Very Poor';
};

/// 백분위 값 === 배열 인덱스 + 1
/// ex:) 50% === 인덱스 + 1
// 백분위 값 반환 함수
export const getPercentile: GetPercentileFunc = (vo2Max, age, gender) => {
    const ageGroup = ageToAgeGroup(age);
    const array = findAgeGroupVo2MaxArray(ageGroup, gender);
    return findApproximationIndex(array, vo2Max) + 1;
};

// 나이에 따른 VO2Max 평균치 반환 함수
export const getAvgVo2MaxFromAge: GetAvgVo2MaxFromAgeFunc = (age, gender) => {
    const ageGroup = ageToAgeGroup(age);
    const avgIndex = 49;
    const array = findAgeGroupVo2MaxArray(ageGroup, gender);
    return array[avgIndex];
};

// VO2Max 값에 따른 MET 값 반환 함수
export const getMET: GetMETFunc = (vo2Max) => {
    const metConstant = 3.5;
    const met = vo2Max / metConstant;
    return removeDecimal(met, 1);
};

///KH = Kilometer per Hour
export const getKHSpeedFrom6minute: KH6minuteSpeedFunc = (distance) => {
    const sixMinute = 360;
    const speed = (distance / sixMinute) * 3.6;
    return removeDecimal(speed, 1);
};

// 선형 보간법을 사용하여 나이 그룹에 따른 VO2Max 세그먼트를 계산하는 함수
const calculateVo2MaxSegment = (vo2MaxX: number, vo2MaxY: number, ageX: number, ageY: number): number[] => {
    const vo2MaxSegment: number[] = [];
    for (let age = ageX; age <= ageY; age++) {
        const vo2Max = vo2MaxX + ((vo2MaxY - vo2MaxX) / (ageY - ageX)) * (age - ageX);
        vo2MaxSegment.push(vo2Max);
    }
    return vo2MaxSegment;
};

//심폐나이 계산 함수
/// 테이블의 각 나이대별 60퍼센트 값과 vo2Max를 비교하여 가장 가까운 나이대를 찾고
/// 이전 나잇대 또는 이후 나잇대와의 gap을 찾아서 10분할 후 가까운 값의 인덱스를 찾아서 심폐나이를 계산
export const getCardiorespiratoryAge = (vo2Max: number, gender: 'male' | 'female'): number => {
    const nearVo2MaxArray: number[] = [];
    let cardiorespiratoryAge = 0;
    const table = gender === 'male' ? vo2MaxTable.male : vo2MaxTable.female;
    for (const index in AgeGroup) {
        nearVo2MaxArray.push(table[AgeGroup[index]].vo2MaxArray[59]);
    }

    const sortedNearVo2MaxArray = [...nearVo2MaxArray].sort((a, b) => a - b);
    const sortedNearVo2MaxArrayIndex = findApproximationIndex(sortedNearVo2MaxArray, vo2Max);
    const cardiorespiratoryAgeGroup = AgeGroup[nearVo2MaxArray.findIndex((vo2Max) => vo2Max === sortedNearVo2MaxArray[sortedNearVo2MaxArrayIndex])];
    const nearVo2Max = table[cardiorespiratoryAgeGroup].vo2MaxArray[59];

    nearVo2MaxArray.forEach((targetVo2Max, index) => {
        if (targetVo2Max !== nearVo2Max) return;
        const isLast = index === nearVo2MaxArray.length - 1;
        const isFirst = index === 0;
        const ageGroupVo2MaxSegments = [];
        const minimumVo2Max = 29.06; ///79세의 vo2Max;
        const maximumVo2Max = 46.3; /// 20세의 vo2Max;
        const ageX = (index + 2) * 10;
        const ageY = ageX + 10;

        if (vo2Max <= minimumVo2Max) {
            cardiorespiratoryAge = 79;
            return;
        }
        if (vo2Max >= maximumVo2Max) {
            cardiorespiratoryAge = 20;
            return;
        }

        let unitDigit = 0; // 나이의 1의 자리
        let tenDigit = 0; // 나이의 10의 자리

        if (isFirst) {
            ageGroupVo2MaxSegments.push(...calculateVo2MaxSegment(targetVo2Max, maximumVo2Max, ageX, ageY));
        } else if (isLast) {
            ageGroupVo2MaxSegments.push(...calculateVo2MaxSegment(targetVo2Max, minimumVo2Max, ageX, ageY));
        } else if (nearVo2Max < vo2Max) {
            ageGroupVo2MaxSegments.push(...calculateVo2MaxSegment(targetVo2Max, nearVo2MaxArray[index - 1], ageX, ageY));
        } else if (nearVo2Max >= vo2Max) {
            ageGroupVo2MaxSegments.push(...calculateVo2MaxSegment(targetVo2Max, nearVo2MaxArray[index + 1], ageX, ageY));
        }

        unitDigit = findApproximationIndex([...ageGroupVo2MaxSegments], vo2Max);

        tenDigit = ageX;
        cardiorespiratoryAge = tenDigit + unitDigit + 5;
    });

    return cardiorespiratoryAge;
};

/// 해당나이대의 Vo2Max 전체 분포 데이터 추출
export const ageGroupVo2MaxOverallData = <T>(age: number, gender: 'male' | 'female', convertCallback: (data: number) => T): T[] => {
    const ageGroup = ageToAgeGroup(age);
    const vo2MaxOverallGroup = gradeIndexEnum;
    const vo2MaxArray = findAgeGroupVo2MaxArray(ageGroup, gender);
    return vo2MaxArray
        .filter((_, index) => {
            switch (index) {
                case vo2MaxOverallGroup.Superior:
                case vo2MaxOverallGroup.Excellent:
                case vo2MaxOverallGroup.Good:
                case vo2MaxOverallGroup.Fair:
                case vo2MaxOverallGroup.Poor:
                case vo2MaxOverallGroup.VeryPoor:
                    return true;
                default:
                    return false;
            }
        })
        .map((data) => convertCallback(data));
};

const trademillConstant = {
    burnFat: 0.5,
    aerobic: 0.8,
};

/// 질환 타입에 따라서 분기
const safezoneConstant = {
    metabolic: {
        min: 0.4,
        max: 0.6,
    },
    circulatory: {
        min: 0.4,
        max: 0.8,
    },
};

export type SafezoneConstant = typeof safezoneConstant;

///KH = Kilometer per Hour

/// 트레드 밀 지방 연소 목표 계산 함수
export const getTrademillBurnFatExerciseKH = (vo2Max: number): number => {
    const burnFatEx = ((vo2Max - 3.5) * trademillConstant.burnFat + 3.5 - 3.5) / 3.35;
    return roundDecimal(burnFatEx, 1);
};

/// 트레드 밀 유산소 목표 계산 함수
export const getTrademillAerobicExerciseKH = (vo2Max: number): number => {
    const aerobicEx = ((vo2Max - 3.5) * trademillConstant.aerobic + 3.5 - 3.5) / 3.35;
    return roundDecimal(aerobicEx, 1);
};

/// 트레드 밀 고강도 목표 계산 함수
export const getTrademillHighIntensityExerciseKH = (vo2Max: number): number => {
    const highIntensityEx = (vo2Max - 3.5) / 3.35;
    return roundDecimal(highIntensityEx, 1);
};

/// 트레드 밀 운동량 분당 소모 칼로리 계산 함수
export const getTrademillExerciseKcalPerMinute = ({ kiometerPerHour, weight }: { kiometerPerHour: number; weight: number }): number => {
    return roundDecimal((kiometerPerHour * 3.35 * weight) / 200, 1);
};

/// 질병 별 안전구간 가이드 계산 함수(질환자 가이드 테이블용)
export const getSafezoneGuide = ({ vo2Max, safezoneType }: { vo2Max: number; safezoneType: keyof SafezoneConstant }): { min: number; max: number } => {
    return {
        min: roundDecimal(((vo2Max - 3.5) * safezoneConstant[safezoneType].min + 3.5 - 3.5) / 3.35, 1),
        max: roundDecimal(((vo2Max - 3.5) * safezoneConstant[safezoneType].max + 3.5 - 3.5) / 3.35, 1),
    };
};

/// 고강도 인터벌 러닝 rep 계산 함수
export const getRunningIntensityRepetitions = ({ burnFatEx, highIntensityEx }: { burnFatEx: number; highIntensityEx: number }): { minRep: number; maxRep: number } => {
    return {
        minRep: roundDecimal(400 / (burnFatEx * 2 + highIntensityEx * 2), 1),
        maxRep: roundDecimal(500 / (burnFatEx * 2 + highIntensityEx * 2), 1),
    };
};

/// ----------------- 심박 수 zone 계산 --------------------
/// heart rate -> bpm단위 사용

/// zone1에 있는 추천 심박 수
const zoneOneConstant = {
    min: 0.5,
    max: 0.6,
};

/// zone2에 있는 추천 심박 수
const zoneTwoConstant = {
    min: 0.6,
    max: 0.7,
};

/// 최대 심박수 상수
const maxHeartRate = 220;

/// zone1, zone2의 min, max 사이 값
const zoneConstant = {
    zoneOne: 0.55,
    zoneTwo: 0.65,
};

export type ZoneConstant = typeof zoneConstant;

/// zone1, zone2의 kcal/min 계산 함수
export const getZoneKcalPerMinute = ({ weight, vo2Max, zoneType }: { weight: number; vo2Max: number; zoneType: keyof ZoneConstant }): number => {
    return roundDecimal(((vo2Max - 3.5) * zoneConstant[zoneType] * weight) / 200, 1);
};

/// zone1, zone2의 목표 심박 수 계산 함수
export const getGoalHR = ({ beforeHR, age, zoneType }: { beforeHR: number; age: number; zoneType: keyof ZoneConstant }): { min: number; max: number } => {
    const recommededHR = maxHeartRate - age - beforeHR;
    let min = 0;
    let max = 0;
    switch (zoneType) {
        case 'zoneOne':
            min = zoneOneConstant.min;
            max = zoneOneConstant.max;
            break;
        case 'zoneTwo':
            min = zoneTwoConstant.min;
            max = zoneTwoConstant.max;
            break;
    }
    return {
        min: Math.round(beforeHR + recommededHR * min),
        max: Math.round(beforeHR + recommededHR * max),
    };
};
