import { good, normal, veryGood, veryWeak, weak } from 'assets/Imgs/Reports';
import { NeedlePie } from 'components/Common/NeedlePie';
import { TUGBarChart } from 'components/Common/RowBarChart';
import { AssessmentResult, walkingSpeedDataType } from 'models/MTE/sarcopeniaAssessmentTypes';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BorderColor } from 'styles/Global/colorSemantic';
import { palette } from 'styles/Global/globalStyles';
import { Body1_Bold, Body2_Bold, Body4_Regular, Body4_SemiBold, Body5_Medium, Body5_SemiBold, Caption2_Medium, Caption2_Regular, Caption2_SemiBold, Title2_Bold } from 'styles/Global/typography';
import { DescAndListTitle } from 'styles/ReportStyles/sarcopeniaAssessmentStyles';

// 보행 평가에 대한 설명 컴포넌트
const WalkingAssessmentDescription = () => {
    return (
        <WalkingAssessmentDescriptionContainer>
            <DiscrpitionWrapper>
                <DiscrpitionEmphasisText>*1ms 초과 :&nbsp;</DiscrpitionEmphasisText>
                <DiscrpitionText>양호</DiscrpitionText>
            </DiscrpitionWrapper>
            <DiscrpitionWrapper>
                <DiscrpitionEmphasisText>*0.6초과 ~ 1.0이하 :&nbsp;</DiscrpitionEmphasisText>
                <DiscrpitionText>보행 훈련 필요</DiscrpitionText>
            </DiscrpitionWrapper>
            <DiscrpitionWrapper>
                <DiscrpitionEmphasisText>*0.4초과 ~ 0.6이하 :&nbsp;</DiscrpitionEmphasisText>
                <DiscrpitionText>보행 위험 예측</DiscrpitionText>
            </DiscrpitionWrapper>
            <DiscrpitionWrapper>
                <DiscrpitionEmphasisText>*0.4미만 :&nbsp;</DiscrpitionEmphasisText>
                <DiscrpitionText>보조자 필수 보행</DiscrpitionText>
            </DiscrpitionWrapper>
        </WalkingAssessmentDescriptionContainer>
    );
};

/// 낙상 위험도 평가에 대한 설명 컴포넌트
const FallRiskAssessmentDescription = () => {
    return (
        <>
            <DiscrpitionWrapper $center={true}>
                <DiscrpitionText>*3m 표적 돌아오기 기준</DiscrpitionText>
            </DiscrpitionWrapper>
        </>
    );
};

export const WalkingAssessmentResult = ({ walkingSpeedData }: { walkingSpeedData: walkingSpeedDataType }) => {
    const [imgName, setImgName] = useState(weak);

    useEffect(() => {
        if (walkingSpeedData.walkingGrade === '훌륭함') {
            setImgName(veryGood);
        } else if (walkingSpeedData.walkingGrade === '좋음') {
            setImgName(good);
        } else if (walkingSpeedData.walkingGrade === '보통') {
            setImgName(normal);
        } else if (walkingSpeedData.walkingGrade === '약함') {
            setImgName(weak);
        } else if (walkingSpeedData.walkingGrade === '매우약함') {
            setImgName(veryWeak);
        }
    }, [walkingSpeedData]);

    return (
        <ResultWrapper>
            <TitleWrapper>
                <MainTitle>보행 평가</MainTitle>
                <SubTitle>보행 속도 반영</SubTitle>
            </TitleWrapper>
            <HeightSpacer />
            <RadiusConatiner>
                <ImgWrapper>
                    <IconImage src={imgName} />
                </ImgWrapper>
                <HeightSpacer />
                <InnerMainTitle>{walkingSpeedData.walkingGrade}</InnerMainTitle>
                <InnerSubTitle>보행 나이 {walkingSpeedData.walkingAge}</InnerSubTitle>
                <HeightSpacer />
                <TableContainer>
                    <TableHeader>
                        <TableHeaderTitle>4m 보행 속도</TableHeaderTitle>
                    </TableHeader>
                    <TableBody>
                        <TableBodyMainText>{walkingSpeedData.walkingMeterPerSecond}</TableBodyMainText>
                        <TableBodySubText>m/s</TableBodySubText>
                    </TableBody>
                </TableContainer>
                <HeightSpacer height={15} />
                <WalkingAssessmentDescription />
            </RadiusConatiner>
        </ResultWrapper>
    );
};

export const FallRiskAssessmentResult = ({ tug }: { tug: { tugTime: number; explainTugTime: string; tugState: string; divideTime: number } }) => {
    const [fallRiskValue, setFallRiskValue] = useState(0);

    useEffect(() => {
        switch (tug.tugState) {
            case '위험':
                setFallRiskValue(15);
                break;
            case '주의':
                setFallRiskValue(50);
                break;
            case '양호':
                setFallRiskValue(85);
                break;
        }
    }, [tug]);
    return (
        <ResultWrapper>
            <TitleWrapper>
                <MainTitle>낙상 위험도</MainTitle>
                <SubTitle>TUG 검사 반영</SubTitle>
            </TitleWrapper>
            <HeightSpacer />
            <RadiusConatiner>
                <NeedlePieContainer>
                    <NeedlePie cx={85} cy={100} iR={50} oR={80} value={fallRiskValue} total={100} benchmarkData={AssessmentResult.fallRiskBenchMarkData} />
                </NeedlePieContainer>
                <HeightSpacer />
                <InnerMainTitleWrapper>
                    <InnerMainTitle>{tug.tugState}</InnerMainTitle>
                    <InnerMainTitleDesc>&nbsp;({tug.tugTime}초)</InnerMainTitleDesc>
                </InnerMainTitleWrapper>
                <InnerSubTitle>{tug.explainTugTime}</InnerSubTitle>
                <HeightSpacer />
                <FallRiskAssessmentDescription />
            </RadiusConatiner>
        </ResultWrapper>
    );
};

export const SarcopeniaAssessmentResult = ({ sarcopeniaResultScore }: { sarcopeniaResultScore: { allSPPBScore: number; allSPPBScoreGrade: string; explainSarcopeniaResult: string } }) => {
    return (
        <ResultWrapper>
            <TitleWrapper>
                <MainTitle>근감소증</MainTitle>
                <SubTitle>SPPB 검사 반영</SubTitle>
            </TitleWrapper>
            <HeightSpacer />
            <RadiusConatiner>
                <NeedlePieContainer>
                    <NeedlePie cx={85} cy={100} iR={45} oR={75} value={(sarcopeniaResultScore.allSPPBScore - 0.5) * 10} benchmarkData={AssessmentResult.sarcopeniaBenchMarkData} useIndexLabel={true} />
                </NeedlePieContainer>
                <HeightSpacer />
                <InnerMainTitleWrapper>
                    <InnerMainTitle>{sarcopeniaResultScore.allSPPBScoreGrade}</InnerMainTitle>
                    <InnerMainTitleDesc>&nbsp;({sarcopeniaResultScore.allSPPBScore}/12)</InnerMainTitleDesc>
                </InnerMainTitleWrapper>
                <InnerSubTitle>{sarcopeniaResultScore.explainSarcopeniaResult}</InnerSubTitle>
                <HeightSpacer />
                <HeightSpacer height={15} />
            </RadiusConatiner>
        </ResultWrapper>
    );
};

export const TUGAssessmentResult = ({ tug, userType }: { tug: { tugTime: number; explainTugTime: string; tugState: string; divideTime: number }; userType?: string | boolean }) => {
    return (
        <RowContainer>
            <ResultWrapper>
                <DescContainer>
                    <BottomTugTitleWrapper>
                        <DescAndListTitle>TUG검사</DescAndListTitle>
                        <TitleDesc>Timed Up and Go</TitleDesc>
                    </BottomTugTitleWrapper>
                    <SubTitle>일어나 걷기 검사로 일상생활에서의 기능적 능력을 측정하는 간단한 검사</SubTitle>
                </DescContainer>
                <HeightSpacer />
                <ResultWrapper>
                    <TugAssessmentContainer>
                        <TitleWrapper>
                            <ChartTitle>의자 앉아 3m 표적 돌아오기</ChartTitle>
                            <TUGBarChart inputData={tug.tugTime} divideTime={tug.divideTime} userType={userType} />
                        </TitleWrapper>
                        <TableContainer $width={100}>
                            <TableHeader>
                                <TableHeaderTitle>TUG 시간</TableHeaderTitle>
                            </TableHeader>
                            <TableBody>
                                <TableBodyMainText>{tug.tugTime}</TableBodyMainText>
                                <TableBodySubText>초</TableBodySubText>
                            </TableBody>
                        </TableContainer>
                    </TugAssessmentContainer>
                </ResultWrapper>
            </ResultWrapper>
        </RowContainer>
    );
};

interface HeightSpacerProps {
    height?: number;
}

const TugAssessmentContainer = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    width: 100%;
    height: 100%;
    gap: 32px;
    background-color: ${palette.PrimaryBlueWeak};
    padding: 16px 20px;
    border: 1px solid ${BorderColor.color_border_neutral};
    border-radius: 6px;
`;

const WalkingAssessmentDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 8px 4px 8px;
    color: ${palette.basic.gray[70]};
`;

const HeightSpacer = styled.div<HeightSpacerProps>`
    display: flex;
    height: ${(props) => (props.height ? `${props.height}px` : '8px')};
`;
const DescContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 12px;
`;

const ResultWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 12px;
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 170px;
    width: 100%;
    gap: 10px;
    align-items: bottom;
    justify-content: space-between;
    // align-items: flex-end;
`;

const TitleWrapper = styled.div`
    width: 100%;
`;
const BottomTugTitleWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 6px;
`;
const SubTitleWrapper = styled.div`
    width: 100%;
    align-items: start;
`;

const MainTitle = styled(Body2_Bold)`
    margin-right: 6px;
`;

const TitleDesc = styled(Caption2_Regular)`
    align-self: flex-end;
    padding: 4px 0;
    color: ${palette.basic.gray[70]};
`;

const SubTitle = styled(Caption2_Medium)<{ $center?: boolean }>`
    color: ${palette.basic.gray[90]};
`;

const InnerMainTitleWrapper = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: center;
`;

const InnerMainTitle = styled(Body1_Bold)`
    // color: ${palette.PrimaryBlueStrong};
    color: ${palette.PrimaryBlueStrong};
    text-align: center;
`;
const InnerMainTitleDesc = styled(Body4_SemiBold)`
    color: ${palette.basic.black};
    text-align: center;
    padding: 6px 0 0 0;
    color: ${palette.basic.gray[80]};
`;

const InnerSubTitle = styled(Body5_Medium)`
    color: ${palette.basic};
    text-align: center;
    padding: 4px 0 16px 0;
`;

const ChartTitle = styled(Body5_SemiBold)`
    color: ${palette.basic.black};
    text-align: center;
`;

const RadiusConatiner = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: ${palette.PrimaryBlueWeak};
    padding: 15px 15px;
    flex-direction: column;
    border: 1px solid ${BorderColor.color_border_neutral};
`;

const TableContainer = styled.div<{ $width?: number; $height?: number }>`
    display: flex;
    border: 1px solid ${palette.PrimaryBlueStrong};
    border-radius: 6px;
    flex-direction: column;
    align-items: center;
    width: ${(props) => (props.$width ? `${props.$width}px` : '100%')};
    background-color: #fff;
    justify-content: start;
    height: ${(props) => (props.$height ? `${props.$height}px` : '100%')};
    max-height: 108px;
`;

const TableHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 8px 0 8px 0;
    background-color: ${palette.PrimaryBlueStrong};
    align-items: center;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid ${palette.PrimaryBlueStrong};
    justify-content: center;
`;

const TableHeaderTitle = styled(Body4_SemiBold)`
    color: #fff;
`;

const TableBody = styled.div`
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px 0;
`;

const TableBodyMainText = styled(Title2_Bold)``;

const TableBodySubText = styled(Body4_Regular)`
    padding-left: 2px;
    color: ${palette.basic.gray[80]};
`;

const DiscrpitionWrapper = styled.div<{ $center?: boolean }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: ${(props) => (props.$center ? 'center' : 'flex-start')};
    padding-bottom: 4px;
`;

const DiscrpitionText = styled(Caption2_Regular)``;

const DiscrpitionEmphasisText = styled(Caption2_SemiBold)`
    color: ${palette.basic.gray[80]};
`;

const IconImage = styled.img`
    height: 128px;
    padding: 12px 12px 12px 20px;
`;

const ImgWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

//--------------------------------NeedlePie--------------------------------

const NeedlePieContainer = styled.div`
    display: flex;
    width: 100%;
    height: 128px;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
`;
