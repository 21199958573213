import React from 'react';

const Mo_ico_logo = () => {
    return (
        <svg width='116' height='22' viewBox='0 0 116 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_5_322)'>
                <path d='M3.47791 13.1968H0V11.1961H9.46988V13.1968H5.99197V21.8117H3.47791V13.1968Z' fill='#231916' />
                <path d='M11.1325 11.1961H13.6466V19.811H19.1004V21.8117H11.1325V11.1961Z' fill='#231916' />
                <path
                    d='M19.9518 16.5078C19.9518 13.3067 22.4658 11.0157 25.8554 11.0157C27.7349 11.0157 29.3012 11.6826 30.3293 12.8987L28.7148 14.3581C27.9839 13.5421 27.0682 13.1184 25.9839 13.1184C23.9518 13.1184 22.506 14.515 22.506 16.5157C22.506 18.5164 23.9518 19.913 25.9839 19.913C27.0682 19.913 27.9839 19.4893 28.7148 18.6498L30.3293 20.1091C29.3012 21.3409 27.7349 22.0078 25.8393 22.0078C22.4658 22.0078 19.9518 19.7168 19.9518 16.5157V16.5078Z'
                    fill='#231916'
                />
                <path d='M44.1916 11.714V21.8196H43.4366V17.01H36.5209V21.8196H35.7659V11.714H36.5209V16.3588H43.4366V11.714H44.1916Z' fill='#231916' />
                <path
                    d='M54.3768 18.218H47.7423C47.7584 18.8065 47.911 19.3243 48.2001 19.7872C48.4893 20.2423 48.8748 20.5953 49.3568 20.8464C49.8467 21.0975 50.3929 21.223 51.0034 21.223C51.5094 21.223 51.9752 21.1367 52.4009 20.9563C52.8266 20.7758 53.1881 20.5169 53.4692 20.1717L53.8949 20.6503C53.5576 21.0426 53.1399 21.3485 52.6339 21.5604C52.1279 21.7722 51.5736 21.8742 50.9873 21.8742C50.2162 21.8742 49.5335 21.7095 48.9311 21.3878C48.3287 21.0661 47.8628 20.611 47.5174 20.0304C47.1801 19.4498 47.0034 18.7908 47.0034 18.0533C47.0034 17.3157 47.164 16.6645 47.4853 16.0839C47.8066 15.5033 48.2483 15.0483 48.8106 14.7187C49.3728 14.3892 49.9993 14.2244 50.6981 14.2244C51.3969 14.2244 52.0234 14.3892 52.5857 14.7109C53.1399 15.0326 53.5817 15.4798 53.903 16.0604C54.2242 16.641 54.3849 17.2922 54.3849 18.0219L54.3688 18.2259L54.3768 18.218ZM49.2524 15.2052C48.8186 15.4406 48.4732 15.7701 48.2082 16.2016C47.9431 16.6331 47.7985 17.1117 47.7584 17.6531H53.67C53.6379 17.1117 53.4933 16.6331 53.2283 16.2095C52.9632 15.7858 52.6098 15.4563 52.176 15.213C51.7423 14.9698 51.2524 14.8521 50.7142 14.8521C50.176 14.8521 49.6861 14.9698 49.2524 15.2052Z'
                    fill='#231916'
                />
                <path
                    d='M61.5574 14.9389C62.0715 15.4175 62.3285 16.1158 62.3285 17.0416V21.8198H61.6217V20.4782C61.3727 20.9175 61.0193 21.2628 60.5454 21.5138C60.0715 21.7649 59.5093 21.8826 58.8426 21.8826C57.9832 21.8826 57.3085 21.6864 56.8105 21.2941C56.3125 20.9018 56.0635 20.3762 56.0635 19.7328C56.0635 19.0894 56.2964 18.603 56.7542 18.2107C57.2121 17.8184 57.943 17.6222 58.947 17.6222H61.5896V17.0181C61.5896 16.312 61.3968 15.7785 61.0032 15.4175C60.6177 15.0488 60.0474 14.8683 59.3004 14.8683C58.7864 14.8683 58.2964 14.9546 57.8305 15.1272C57.3647 15.2999 56.9631 15.5352 56.6418 15.8177L56.2723 15.2999C56.6578 14.9625 57.1157 14.7036 57.6619 14.5153C58.2 14.327 58.7703 14.2328 59.3647 14.2328C60.3205 14.2328 61.0514 14.4682 61.5655 14.9468L61.5574 14.9389ZM60.5775 20.8548C61.0273 20.5645 61.3647 20.1486 61.5896 19.5994V18.1872H58.955C58.2 18.1872 57.6458 18.3205 57.3085 18.5873C56.9711 18.8541 56.7944 19.2307 56.7944 19.7014C56.7944 20.1722 56.9791 20.5802 57.3566 20.8626C57.7342 21.1451 58.2562 21.2863 58.939 21.2863C59.5815 21.2863 60.1197 21.1451 60.5695 20.8548H60.5775Z'
                    fill='#231916'
                />
                <path d='M65.6602 11.1021H66.3992V21.8118H65.6602V11.1021Z' fill='#231916' />
                <path
                    d='M73.5406 21.3411C73.3559 21.5137 73.1229 21.6471 72.8418 21.7413C72.5607 21.8354 72.2715 21.8746 71.9663 21.8746C71.2996 21.8746 70.7776 21.6942 70.4161 21.3333C70.0547 20.9724 69.8699 20.4702 69.8699 19.8268V14.9074H68.4482V14.2876H69.8699V12.64H70.6089V14.2876H73.0587V14.9074H70.6089V19.7562C70.6089 20.2427 70.7294 20.6193 70.9703 20.8782C71.2113 21.1371 71.5727 21.2627 72.0386 21.2627C72.5045 21.2627 72.9221 21.1214 73.2354 20.8468L73.5486 21.349L73.5406 21.3411Z'
                    fill='#231916'
                />
                <path
                    d='M81.9508 15.0486C82.513 15.5978 82.7942 16.3902 82.7942 17.418V21.8196H82.0552V17.473C82.0552 16.6256 81.8383 15.9822 81.3966 15.535C80.9548 15.0878 80.3444 14.8681 79.5572 14.8681C78.6496 14.8681 77.9347 15.1349 77.4126 15.6605C76.8905 16.1862 76.6255 16.9002 76.6255 17.7946V21.8118H75.8865V11.1021H76.6255V15.8488C76.8906 15.331 77.2841 14.9309 77.8062 14.6406C78.3283 14.3581 78.9468 14.2169 79.6536 14.2169C80.6175 14.2169 81.3805 14.4915 81.9428 15.0407L81.9508 15.0486Z'
                    fill='#231916'
                />
                <path
                    d='M87.1951 21.38C86.6008 21.0505 86.1429 20.5954 85.8056 20.0148C85.4682 19.4342 85.2996 18.7752 85.2996 18.0455C85.2996 17.3158 85.4682 16.6411 85.8056 16.0605C86.1429 15.4799 86.6008 15.0248 87.1951 14.7031C87.7895 14.3815 88.4562 14.2167 89.2032 14.2167C89.8216 14.2167 90.3839 14.3344 90.898 14.5698C91.404 14.8051 91.8136 15.1504 92.1349 15.6054L91.5887 15.9977C91.3156 15.6211 90.9702 15.3387 90.5606 15.1504C90.151 14.9621 89.7012 14.8679 89.2112 14.8679C88.6088 14.8679 88.0706 15.0013 87.5967 15.268C87.1148 15.5348 86.7453 15.9036 86.4723 16.39C86.1992 16.8686 86.0626 17.4257 86.0626 18.0534C86.0626 18.681 86.1992 19.2302 86.4723 19.7167C86.7453 20.1953 87.1148 20.5719 87.5967 20.8387C88.0706 21.1054 88.6168 21.2388 89.2112 21.2388C89.7012 21.2388 90.151 21.1446 90.5606 20.9563C90.9702 20.768 91.3156 20.4856 91.5887 20.109L92.1349 20.5013C91.8217 20.9563 91.412 21.2937 90.898 21.5369C90.3919 21.7723 89.8297 21.89 89.2032 21.89C88.4562 21.89 87.7815 21.7252 87.1951 21.4036V21.38Z'
                    fill='#231916'
                />
                <path
                    d='M99.453 14.9389C99.9671 15.4175 100.224 16.1158 100.224 17.0416V21.8198H99.5173V20.4782C99.2683 20.9175 98.9149 21.2628 98.441 21.5138C97.9671 21.7649 97.4048 21.8826 96.7382 21.8826C95.8787 21.8826 95.204 21.6864 94.706 21.2941C94.208 20.9018 93.959 20.3762 93.959 19.7328C93.959 19.0894 94.192 18.603 94.6498 18.2107C95.1076 17.8184 95.8386 17.6222 96.8426 17.6222H99.4851V17.0181C99.4851 16.312 99.2924 15.7785 98.8988 15.4175C98.5133 15.0488 97.943 14.8683 97.196 14.8683C96.6819 14.8683 96.192 14.9546 95.7261 15.1272C95.2602 15.2999 94.8586 15.5352 94.5374 15.8177L94.1679 15.2999C94.5534 14.9625 95.0113 14.7036 95.5574 14.5153C96.0956 14.327 96.6659 14.2328 97.2602 14.2328C98.2161 14.2328 98.947 14.4682 99.4611 14.9468L99.453 14.9389ZM98.4731 20.8548C98.9229 20.5645 99.2602 20.1486 99.4851 19.5994V18.1872H96.8506C96.0956 18.1872 95.5414 18.3205 95.204 18.5873C94.8667 18.8541 94.69 19.2307 94.69 19.7014C94.69 20.1722 94.8747 20.5802 95.2522 20.8626C95.6297 21.1451 96.1518 21.2863 96.8345 21.2863C97.4771 21.2863 98.0153 21.1451 98.4651 20.8548H98.4731Z'
                    fill='#231916'
                />
                <path
                    d='M105.373 14.6644C105.879 14.3741 106.49 14.225 107.205 14.225V14.9312L107.028 14.9155C106.169 14.9155 105.502 15.1744 105.02 15.7001C104.538 16.2258 104.297 16.9554 104.297 17.8891V21.8121H103.558V14.28H104.265V15.9276C104.498 15.3784 104.867 14.9547 105.373 14.6644Z'
                    fill='#231916'
                />
                <path
                    d='M115.99 18.2186H109.355C109.371 18.8071 109.524 19.3249 109.813 19.7878C110.102 20.2429 110.488 20.5959 110.97 20.847C111.46 21.0981 112.006 21.2236 112.616 21.2236C113.122 21.2236 113.588 21.1373 114.014 20.9568C114.44 20.7764 114.801 20.5175 115.082 20.1723L115.508 20.6509C115.171 21.0432 114.753 21.3491 114.247 21.561C113.741 21.7728 113.187 21.8748 112.6 21.8748C111.829 21.8748 111.147 21.7101 110.544 21.3884C109.942 21.0667 109.476 20.6116 109.131 20.031C108.793 19.4504 108.616 18.7914 108.616 18.0539C108.616 17.3163 108.777 16.6651 109.098 16.0845C109.42 15.5039 109.861 15.0489 110.424 14.7193C110.986 14.3898 111.612 14.225 112.311 14.225C113.01 14.225 113.637 14.3898 114.199 14.7115C114.753 15.0332 115.195 15.4804 115.516 16.061C115.837 16.6416 115.998 17.2928 115.998 18.0225L115.982 18.2265L115.99 18.2186ZM110.865 15.2058C110.432 15.4412 110.086 15.7707 109.821 16.2022C109.556 16.6337 109.412 17.1123 109.371 17.6537H115.283C115.251 17.1123 115.106 16.6337 114.841 16.2101C114.576 15.7864 114.223 15.4568 113.789 15.2136C113.355 14.9704 112.865 14.8527 112.327 14.8527C111.789 14.8527 111.299 14.9704 110.865 15.2058Z'
                    fill='#231916'
                />
                <path
                    d='M26.4267 -0.000270091C25.511 -0.0473457 24.6516 0.17234 23.9126 0.572483C22.8283 1.16877 21.503 1.16093 20.4267 0.572483C19.7439 0.21157 18.9729 -0.000270091 18.1455 -0.000270091C16.5953 -0.000270091 15.2299 0.737248 14.3785 1.86706C13.7439 2.71442 12.7319 3.20087 11.6636 3.20087C10.5552 3.20087 9.47888 2.71442 8.82024 1.84352C7.89655 0.627405 6.36241 -0.125805 4.66763 0.0154218C2.38651 0.203724 0.563217 2.03183 0.426671 4.26007C0.257996 6.90415 2.40257 9.10101 5.07727 9.10101C6.58731 9.10101 7.92868 8.40272 8.78008 7.31214C9.47085 6.4334 10.5231 5.89203 11.6636 5.89203C12.7399 5.89203 13.752 6.38632 14.3865 7.23368C15.2299 8.36349 16.6034 9.10101 18.1455 9.10101C18.9729 9.10101 19.7439 8.88917 20.4186 8.52041C21.503 7.92412 22.8283 7.92412 23.9126 8.52041C24.5873 8.88917 25.3584 9.10101 26.1857 9.10101C28.8604 9.10101 31.005 6.90415 30.8363 4.26007C30.6917 1.96906 28.7721 0.117419 26.4267 0.00757584V-0.000270091Z'
                    fill='#41667C'
                />
            </g>
            <defs>
                <clipPath id='clip0_5_322'>
                    <rect width='116' height='22' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Mo_ico_logo;
