import styled from 'styled-components';
import { BorderColor } from 'styles/Global/colorSemantic';
import { Caption1_SemiBold, Caption2_Regular, Caption2_SemiBold } from 'styles/Global/typography';

const MTEReportFooter = () => {
    return (
        <Layout>
            <OfficeName>(주) 티엘씨 헬스케어</OfficeName>
            <ColumnDivider />
            <OfficeDescContainer>
                <OfficeDesc>(13488) 경기도 성남시 분당구 판교로 289번길 20(삼평동) 판교로교테크노밸리스타트업캠퍼스 2동 2층</OfficeDesc>
                <OfficeDescWrapper>
                    <OfficeDescTitle>문의.</OfficeDescTitle>
                    <OfficeDesc>031-717-1425</OfficeDesc>
                    <OfficeDescTitle>이메일.</OfficeDescTitle>
                    <OfficeDesc>info@tlchealthcare.co.kr</OfficeDesc>
                    <OfficeDescTitle>홈페이지.</OfficeDescTitle>
                    <OfficeDesc>www.tlchealthcare.co.kr</OfficeDesc>
                    <OfficeDescTitle>MTM몰.</OfficeDescTitle>
                    <OfficeDesc>www.tlcmtm.com</OfficeDesc>
                </OfficeDescWrapper>
            </OfficeDescContainer>
        </Layout>
    );
};

export default MTEReportFooter;

const Layout = styled.section`
    display: flex;
    align-items: center;
    width: 100%;
    border-top: 1px solid ${BorderColor.color_border_neutral};
    padding: 16px 36px 20px 36px;
    gap: 16px;
    height: 68px;
`;

const OfficeName = styled(Caption1_SemiBold)``;

const OfficeDescContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const OfficeDescWrapper = styled.div`
    display: flex;
    gap: 2px;
`;

const OfficeDescTitle = styled(Caption2_SemiBold)`
    font-size: 11px;
`;

const OfficeDesc = styled(Caption2_Regular)`
    font-size: 11px;
`;

const ColumnDivider = styled.div`
    width: 1px;
    height: 100%;
    background-color: ${BorderColor.color_border_normal};
`;
