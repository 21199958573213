import { DescAndListTitle } from 'styles/ReportStyles/sarcopeniaAssessmentStyles';
import styled from 'styled-components';
import { walkAndRunGraph } from 'assets/Imgs/Reports';
import { palette } from 'styles/Global/globalStyles';
import { BorderColor } from 'styles/Global/colorSemantic';

const WalkAndRunningExerciseGraph = () => {
    return (
        <div>
            <DescAndListTitle>걷기 & 달리기 운동프로그램 설계 그래표</DescAndListTitle>
            <ImgWrapper>
                <WalkAndRunningExerciseGraphImg src={walkAndRunGraph} />
            </ImgWrapper>
        </div>
    );
};

export default WalkAndRunningExerciseGraph;

const ImgWrapper = styled.div`
    background-color: ${palette.PrimaryBlueWeak};
    border-radius: 6px;
    border: 1px solid ${BorderColor.color_border_neutral};
    margin: 8px 0 12px 0;
`;

const WalkAndRunningExerciseGraphImg = styled.img`
    width: 100%;
    height: 280px;
    object-fit: contain;
`;
