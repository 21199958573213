interface TriangleProps {
    $reverse?: boolean;
}

const MTETriangle = ({ $reverse }: TriangleProps) => {
    return (
        <svg width='20' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g transform={$reverse ? 'scale(1, -1) translate(0, -17)' : undefined}>
                <path d='M10 17L0.473722 0.499998L19.5263 0.5L10 17Z' fill='#D9D9D9' />
            </g>
        </svg>
    );
};

export default MTETriangle;
