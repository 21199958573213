import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { palette } from 'styles/Global/globalStyles';
import { Body1_Bold, Caption1_Regular, Display2_Bold } from 'styles/Global/typography';

export const DoughnutChart = ({ inputData }: { inputData: number }) => {
    const data = [
        { value: inputData, color: palette.PrimaryBlue },
        { value: 100 - inputData, color: '#ccc' },
    ];
    return (
        <Layout>
            <ChartWrapper>
                <ResponsiveContainer width='100%' height='100%'>
                    <PieChart>
                        <Pie data={data} dataKey='value' fill={palette.PrimaryBlue} innerRadius={30} outerRadius={50} startAngle={90} endAngle={-360} strokeWidth={0}>
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                <TitleWrapper>
                    <Desc>{inputData > 50 ? '상위' : '하위'}</Desc>
                    <Title>{inputData > 50 ? 100 - inputData : inputData}%</Title>
                </TitleWrapper>
            </ChartWrapper>
        </Layout>
    );
};

export const WalkingAssessmentDoughnutChart = ({ inputData }: { inputData: number }) => {
    const data = [
        { value: inputData, color: palette.PrimaryBlue },
        { value: 100 - inputData, color: '#ccc' },
    ];
    return (
        <Layout>
            <ChartWrapper>
                <ResponsiveContainer width='100%' height='100%'>
                    <PieChart margin={{ top: 0, bottom: 0, left: 0, right: 0 }}>
                        <Pie data={data} dataKey='value' fill={palette.PrimaryBlue} innerRadius={40} outerRadius={80} startAngle={90} endAngle={-360} strokeWidth={0}>
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                <TitleWrapper>
                    <BigTitle>{inputData}%</BigTitle>
                </TitleWrapper>
            </ChartWrapper>
        </Layout>
    );
};

const Layout = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ChartWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const TitleWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Title = styled(Body1_Bold)`
    color: ${palette.PrimaryBlueStrong};
`;

const BigTitle = styled(Display2_Bold)`
    color: ${palette.PrimaryBlueStrong};
`;

const Desc = styled(Caption1_Regular)``;
