import { circleIndicator } from 'assets/Imgs/Reports';
import { MTEArrowDown } from 'assets/MTEArrowDown';
import { MTEArrowRight } from 'assets/MTEArrowRIght';
import { OptimizedProgramType } from 'models/MTE/walkingAssessmentTypes';
import styled from 'styled-components';
import { palette } from 'styles/Global/globalStyles';
import { Body3_Bold, Body4_SemiBold, Caption1_Bold, Caption1_Regular, Caption1_SemiBold, Caption2_Medium, Caption2_SemiBold, Title1_SemiBold } from 'styles/Global/typography';
import { SubTitle, Title, TitleWrapper } from 'styles/ReportStyles/walkingAssessmentStyles';

export const OptimizedProgram = ({ heartRateZone, highIntensityRun, recoveryRun }: OptimizedProgramType) => {
    const { burnFat, highIntensity, repetition } = highIntensityRun;
    const { zone1, zone2 } = heartRateZone;

    return (
        <BottomContainerLayout>
            <TitleWrapper>
                <Title>최적화 프로그램</Title>
                <SubTitle>Optimized Program</SubTitle>
            </TitleWrapper>
            <BodyLayout>
                <ProgramContainer>
                    <ProgramTitleWrapper>
                        <ProgramTitle>심박수 ZONE 달리기</ProgramTitle>
                        <ProgramSubTitleColumnWrapper>
                            <ProgramSubTitle>Zone 1/2 Running</ProgramSubTitle>
                            <ProgramSubTitle>HRR 50 ~ 70%</ProgramSubTitle>
                        </ProgramSubTitleColumnWrapper>
                    </ProgramTitleWrapper>
                    <ProgramBodyContainer $paddingBottom='4px' $paddingTop='7px'>
                        <ProgramBodyRowWrapper alignItem='center' gap='6px'>
                            <ProgramRoundContainer>
                                <ProgramZoneTitle>ZONE 1</ProgramZoneTitle>
                            </ProgramRoundContainer>
                            <MTEArrowRight />
                            <ProgramRoundContainer>
                                <ProgramZoneTitle>ZONE 2</ProgramZoneTitle>
                            </ProgramRoundContainer>
                        </ProgramBodyRowWrapper>
                        <ProgramZoneBodyGridWrapper>
                            <ProgramBodyColumnWrapper>
                                <ProgramZoneBodyText>
                                    {zone1.min}~{zone1.max}&nbsp;
                                    <ProgramBodyCaption>bpm</ProgramBodyCaption>
                                </ProgramZoneBodyText>
                                <ProgramBodyCaption>={zone1.kcalPerMinute.toFixed(1)} kcal/min</ProgramBodyCaption>
                            </ProgramBodyColumnWrapper>
                            <ProgramBodyColumnWrapper>
                                <ProgramZoneBodyText>
                                    {zone2.min}~{zone2.max}&nbsp;
                                    <ProgramBodyCaption>bpm</ProgramBodyCaption>
                                </ProgramZoneBodyText>
                                <ProgramBodyCaption>={zone2.kcalPerMinute.toFixed(1)} kcal/min</ProgramBodyCaption>
                            </ProgramBodyColumnWrapper>
                        </ProgramZoneBodyGridWrapper>
                        <ProgramBodyCaption>( 운동시간 : 최소 20분 / 권장 60분 )</ProgramBodyCaption>
                    </ProgramBodyContainer>
                    <ProgramBottomTextWrapper>
                        <ProgramBottomText>* 노인의 건강 상태에 맞춰 채력과 건강을 개선하는데 적합</ProgramBottomText>
                        <ProgramBottomText>ZONE 1: 기초적인 일상 활동 수행 능력 유지</ProgramBottomText>
                        <ProgramBottomText>ZONE 2: 대사 건강 개선, 지구력 및 회복력, 심폐기능 향상</ProgramBottomText>
                    </ProgramBottomTextWrapper>
                </ProgramContainer>
                <ProgramContainer>
                    <ProgramTitleWrapper>
                        <ProgramTitle>회복 달리기</ProgramTitle>
                        <ProgramSubTitleColumnWrapper>
                            <ProgramSubTitle>Recovery Run</ProgramSubTitle>
                        </ProgramSubTitleColumnWrapper>
                    </ProgramTitleWrapper>
                    <ProgramBodyContainer $flexDirection='row'>
                        <ProgramBodyColumnWrapper>
                            <ProgramBodyTitle>{recoveryRun.toFixed(1)}</ProgramBodyTitle>
                            <ProgramBodySubTitle>km/h</ProgramBodySubTitle>
                        </ProgramBodyColumnWrapper>
                        <ProgramBodyColumnWrapper>
                            <TextRowWrapper>
                                <ProgramCategoryCircle />
                                <ProgramBodyMiddleText $color={palette.PrimaryBlue}>30 분</ProgramBodyMiddleText>
                            </TextRowWrapper>
                            <MTEArrowDown />
                            <TextRowWrapper>
                                <ProgramCategoryCircle />
                                <ProgramBodyMiddleText $color={palette.PrimaryBlue}>60 분</ProgramBodyMiddleText>
                            </TextRowWrapper>
                            <ProgramBodyDescription>(운동 최대 효과)</ProgramBodyDescription>
                        </ProgramBodyColumnWrapper>
                    </ProgramBodyContainer>
                    <ProgramBottomTextWrapper>
                        <ProgramBottomText>* 처방된 속도로 걷기 / 달리기 1시간 이상 수행 시,</ProgramBottomText>
                        <ProgramBottomText>빠른 피로 회복력 증진 효과를 얻게 됩니다.</ProgramBottomText>
                    </ProgramBottomTextWrapper>
                </ProgramContainer>
                <ProgramContainer>
                    <ProgramTitleWrapper>
                        <ProgramTitle>고강도 인터벌 달리기</ProgramTitle>
                        <ProgramSubTitleColumnWrapper>
                            <ProgramSubTitle>High Intensity</ProgramSubTitle>
                            <ProgramSubTitle>Interval Run</ProgramSubTitle>
                        </ProgramSubTitleColumnWrapper>
                    </ProgramTitleWrapper>
                    <ProgramBodyContainer $paddingTop='15px' $paddingBottom='2px'>
                        <ProgramBodyRowWrapper>
                            <ProgramCategoryCircle $size={8} $color={palette.PrimaryGreen} />
                            <ProgramBodyColumnWrapper>
                                <ProgramBodyTitle>{highIntensity.kilometerPerHour.toFixed(1)}</ProgramBodyTitle>
                                <ProgramBodySubTitle>km/h</ProgramBodySubTitle>
                                <ProgramBodyCaption>={highIntensity.kcalPerMinute.toFixed(1)}kcal/min</ProgramBodyCaption>
                            </ProgramBodyColumnWrapper>
                            <CircleImageWrapper>
                                <CircleImage src={circleIndicator} />
                                <OffsetText>2분</OffsetText>
                            </CircleImageWrapper>
                            <ProgramCategoryCircle $size={8} $color={palette.SecondaryBlue} />
                            <ProgramBodyColumnWrapper>
                                <ProgramBodyTitle>{burnFat.kilometerPerHour.toFixed(1)}</ProgramBodyTitle>
                                <ProgramBodySubTitle>km/h</ProgramBodySubTitle>
                                <ProgramBodyCaption>={burnFat.kcalPerMinute.toFixed(1)}kcal/min</ProgramBodyCaption>
                            </ProgramBodyColumnWrapper>
                        </ProgramBodyRowWrapper>
                        <ProgramBodyCaption>
                            {repetition.min.toFixed(1)}rep = 400 / {repetition.max.toFixed(1)}rep = 500
                        </ProgramBodyCaption>
                    </ProgramBodyContainer>
                    <ProgramBottomTextWrapper>
                        <ProgramBottomText>* 유산소 능력 향상을 위한 달리기 프로그램입니다.</ProgramBottomText>
                        <ProgramBottomText>400kcal 소비를 위한 반복 횟수를 모두 채워보세요.</ProgramBottomText>
                    </ProgramBottomTextWrapper>
                </ProgramContainer>
            </BodyLayout>
        </BottomContainerLayout>
    );
};

const ProgramCategoryCircle = styled.div<{ $size?: number; $color?: string }>`
    width: ${({ $size }) => $size || 5}px;
    height: ${({ $size }) => $size || 5}px;
    display: flex;
    border-radius: 50%;
    background-color: ${(props) => props.$color || palette.PrimaryBlue};
`;

const ProgramRoundContainer = styled.div`
    display: flex;
    padding: 2px 14px;
    justy-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: ${palette.PrimaryBlue};
`;

const ProgramTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 6px;
`;
const ProgramSubTitleColumnWrapper = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
`;

const ProgramBottomTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    & > :nth-child(2) {
        margin-left: 8px;
    }
    & > :nth-child(3) {
        margin-left: 8px;
    }
`;

const ProgramTitle = styled(Body4_SemiBold)`
    color: ${palette.PrimaryBlueAccent};
`;
const ProgramSubTitle = styled(Caption2_Medium)`
    color: ${palette.PrimaryBlueAccent};
    line-height: 10px;
    font-size: 10px;
`;

const ProgramBodyTitle = styled(Title1_SemiBold)<{ $color?: string }>`
    line-height: 26px;
    ${(props) => props.$color && `color: ${props.$color};`}
`;
const ProgramBodySubTitle = styled(Caption1_SemiBold)`
    color: ${palette.PrimaryBlueStrong};
`;

const ProgramBodyMiddleText = styled(Body4_SemiBold)<{ $color?: string }>`
    ${(props) => props.$color && `color: ${props.$color};`}
`;

const ProgramBottomText = styled(Caption1_Bold)`
    font-size: 10px;
    line-height: 10px;
    color: ${palette.PrimaryBlueAccent};
`;

const ProgramBodyDescription = styled(Caption1_Bold)`
    font-size: 10px;
    line-height: 12px;
    color: ${palette.PrimaryBlue};
`;

const ProgramBodyCaption = styled(Caption1_Regular)<{ $color?: string }>`
    font-size: 8px;
    line-height: 10px;
    ${(props) => props.$color && `color: ${props.$color};`}
`;

const ProgramZoneTitle = styled(Caption2_SemiBold)`
    color: white;
`;

const ProgramZoneBodyText = styled(Body3_Bold)``;

const ProgramContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

const ProgramBodyContainer = styled.div<{ $paddingTop?: string; $flexDirection?: string; $paddingBottom?: string }>`
    display: flex;
    flex-direction: ${(props) => props.$flexDirection || 'column'};
    align-items: center;
    justify-content: space-evenly;
    border-radius: 8px;
    overflow: hidden;
    padding: ${(props) => props.$paddingTop || '8px'} 0 ${(props) => props.$paddingBottom || '8px'};
    border: 1px solid ${palette.PrimaryBlue};
    gap: 6px;
`;

const ProgramBodyRowWrapper = styled.div<{ alignItem?: string; gap?: string }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    ${(props) => props.alignItem && `align-items: ${props.alignItem};`}
    ${(props) => props.gap && `gap: ${props.gap};`}
`;

const ProgramZoneBodyGridWrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    align-items: center;
`;

const TextRowWrapper = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
    flex-direction: row;
`;

const ProgramBodyColumnWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const BottomContainerLayout = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
`;

const BodyLayout = styled.div`
    display: grid;
    grid-template-columns: 1.05fr 1fr 1fr;
    gap: 20px;
`;

/// ----------------ImageWrap----------------

const CircleImageWrapper = styled.div`
    display: flex;
    position: relative;
`;

const OffsetText = styled(Caption1_Bold)`
    display: flex;
    position: absolute;
    top: 15px;
    left: 22px;
    z-index: 1;
`;

const CircleImage = styled.img`
    width: 50px;
    height: 50px;
    margin: 0 6px;
    object-fit: cover;
`;
