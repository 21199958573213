import styled from 'styled-components';
import { BorderColor } from 'styles/Global/colorSemantic';
import { Body1_SemiBold, Body3_SemiBold } from 'styles/Global/typography';

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
`;

export const Title = styled(Body1_SemiBold)``;

export const SubTitle = styled(Body3_SemiBold)``;

export const ContainerLayout = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${BorderColor.color_border_neutral};
    margin-bottom: 4px;
    width: 100%;
`;
