import Divider from 'components/Common/Divider';
import styled from 'styled-components';
import { Common } from 'styles/Global/colorPrimitive';
import { BackgroundColor, TextColor } from 'styles/Global/colorSemantic';
import { Body2_Medium, Title1_SemiBold, Title2_SemiBold } from 'styles/Global/typography';

export const Spacer = styled.div`
    flex: 1;
`;

export const InnerDivider = styled(Divider)<{ $height?: number }>`
    background-color: #6f6f6f;
    height: ${({ $height }) => $height || 1}px;
`;

export const ContainerLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ContainerHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
    width: 100%;
`;

export const ContainerBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 23px;
    padding: 40px 20px;
`;

export const AssessmentRadiusTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px 0 8px 37px;
    min-width: 580px;
    color: ${TextColor.color_text_placeholder};
    background-color: #8c8c8c;
    border-radius: 10px;
`;

export const AssessmentTableLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 50px;
`;

export const AssessmentTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 0.5px solid #6f6f6f;
    border-radius: 5px;
    padding: 20px 78px 20px 28px;
`;

export const AssessmentTableHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 5px;
`;

export const AssessmentTableContentLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 22px;
`;

export const Title = styled(Title1_SemiBold)``;

export const AssesssmentTargetText = styled(Title1_SemiBold)`
    color: ${Common.color_white};
`;

export const SubTitle = styled(Title2_SemiBold)`
    color: ${TextColor.color_text_placeholder};
`;

export const AssessmentName = styled(Title1_SemiBold)`
    color: ${BackgroundColor.color_background_primary};
`;

export const HeightSpacer = styled.div<{ $height?: number }>`
    height: ${({ $height }) => $height || 20}px;
    width: 100%;
`;

export const WidthSpacer = styled.div<{ $width?: number }>`
    width: ${({ $width }) => $width || 20}px;
    height: 100%;
`;

export const AsssessmentInputWrapper = styled.div<{ $width?: number; $maxWidth?: number }>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth}px;`}
    width: ${({ $width }) => $width || 594}px;
    justify-content: space-between;
`;

export const AsssessmentFlexInputWrapper = styled(AsssessmentInputWrapper)`
    display: flex;
    flex: 1;
    align-items: flex-start;
`;

export const AssessmentSelectBoxArea = styled.div<{ $width?: number }>`
    display: flex;
    flex-direction: column;
`;

export const AssessmentItemTitle = styled(Title1_SemiBold)``;

export const AsssessmentMultiInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
`;

export const BottomButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 54px;
    gap: 148px;
`;

// ---------------------- Objective Test Style ----------------------
export const AssessmentObjectiveTestWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;

export const AssessmentObjectiveTestTitle = styled(Title1_SemiBold)``;

export const AssessmentObjectiveTestScoreWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

export const AssessmentObjectiveTestScore = styled.div<{ $isSelected?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    cursor: pointer;
    background-color: ${({ $isSelected }) => ($isSelected ? BackgroundColor.color_background_primary : BackgroundColor.color_background_primary_disabled)};
    box-shadow: var(--Shadow-0, 0px) var(--Shadow-2, 2px) var(--Shadow-8, 8px) var(--Shadow-0, 0px) var(--shadow-12, rgba(16, 16, 16, 0.12));
`;

export const AssessmentObjectiveTestScoreText = styled(Body2_Medium)`
    color: ${Common.color_white};
`;
