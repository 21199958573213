import { cervicalMobility, functionalMovement, hipMobility, mobility, muscularEndurance, shoulderMobility } from 'assets/Imgs/Reports';
import { Radar } from 'graph/Radar';
import { mobilityAssessmentType } from 'models/MTE/mobilityAssessmentTypes';
import styled from 'styled-components';
import { palette } from 'styles/Global/globalStyles';
import { Body1_Bold, Body4_SemiBold, Caption1_SemiBold } from 'styles/Global/typography';

/// Todo: 입력 데이터 1 ~ 10 사이로 평준화 필요
/// Todo: 입력 데이터(value) 기준으로 디스크립션 변경 로직 필요
/// Todo: 목데이터 대신 실제 데이터 사용

interface MobilityAssessmentResultItemProps {
    title: string;
    dataKey: string;
    imageAsset: string;
    description: string;
    subDescription: string;
    top: string;
    right: string;
}

const MobilityAssessmentResultItem = ({ title, imageAsset, description, dataKey, subDescription, top, right }: MobilityAssessmentResultItemProps) => {
    return (
        <AssesementResultInnerLayout $top={top} $right={right}>
            <TextWrapper>
                <AssseesmentResultCircle>{dataKey}</AssseesmentResultCircle>
                <AssesementResultItemTitle>{title}</AssesementResultItemTitle>
            </TextWrapper>
            <AssesementResultItemDescription>{description}</AssesementResultItemDescription>
            <AssesementResultItemRanking>{subDescription}</AssesementResultItemRanking>
        </AssesementResultInnerLayout>
    );
};

const MobilityAssessmentGraph = ({ mobilityData }: { mobilityData: mobilityAssessmentType }) => {
    const data = [
        {
            key: 'A',
            value: mobilityData.muscle.sitStandRanking / 10 < 1 ? 1 : mobilityData.muscle.sitStandRanking / 10,
            label: '근지구력',
            imageAsset: muscularEndurance,
            description: mobilityData.muscle.sitStandGrade,
            subDescription: mobilityData.muscle.sitStandRanking <= 50 ? `하위 ${mobilityData.muscle.sitStandRanking}%` : `상위 ${100 - mobilityData.muscle.sitStandRanking}%`,
        },
        {
            key: 'B',
            value: mobilityData.neck.neckScore === 0 ? 1 : mobilityData.neck.neckScore === 1 ? 2 : mobilityData.neck.neckScore === 2 ? 5 : 8,
            label: '경추 가동성',
            imageAsset: cervicalMobility,
            description: mobilityData.neck.neckGrade,
            subDescription: `${mobilityData.neck.neckScore}/3`,
        },
        {
            key: 'C',
            value: mobilityData.hip.hipScore === 0 ? 1 : mobilityData.hip.hipScore === 1 ? 2 : mobilityData.hip.hipScore === 2 ? 5 : 8,
            label: '고관절 가동성',
            imageAsset: hipMobility,
            description: mobilityData.hip.hipGrade,
            subDescription: `${mobilityData.hip.hipScore}/3`,
        },
        {
            key: 'D',
            value: mobilityData.shoulder.shoulderScore === 0 ? 1 : mobilityData.shoulder.shoulderScore === 1 ? 2 : mobilityData.shoulder.shoulderScore === 2 ? 5 : 8,
            label: '어깨 가동성',
            imageAsset: shoulderMobility,
            description: mobilityData.shoulder.shoulderGrade,
            subDescription: `${mobilityData.shoulder.shoulderScore}/3`,
        },
        {
            key: 'E',
            value:
                mobilityData.functionalMotion.functionalMotionScore === 0
                    ? 1
                    : mobilityData.functionalMotion.functionalMotionScore === 1
                    ? 2
                    : mobilityData.functionalMotion.functionalMotionScore === 2
                    ? 5
                    : 8,
            label: '기능성 움직임',
            imageAsset: functionalMovement,
            description: mobilityData.functionalMotion.functionalMotionGrade,
            subDescription: `${mobilityData.functionalMotion.functionalMotionScore}/3`,
        },
    ];

    return (
        <GraphLayout>
            <MobilityImg src={mobility} />
            <Radar size={350} data={data} />

            {data.map((data, index) => {
                return (
                    <MobilityAssessmentResultItem
                        title={data.label}
                        imageAsset={data.imageAsset}
                        description={data.description}
                        dataKey={data.key}
                        subDescription={data.subDescription}
                        top={index === 0 ? '16px' : index === 4 ? '16px' : index === 1 ? '172px' : index === 2 ? '402px' : '160px'}
                        right={index === 0 ? '122px' : index === 1 ? '46px' : index === 2 ? '332px' : index === 3 ? '570px' : '480px'}
                    />
                );
            })}
        </GraphLayout>
    );
};

export default MobilityAssessmentGraph;

const GraphLayout = styled.div`
    position: relative;
    width: 100%;
    // height: 100%;
    display: flex;
    margin: 16px 0 24px 0;
    gap: 20px;
    flex-direction: row;
    align-items: center;
    background-color: ${palette.PrimaryBlueWeak};
    border-radius: 6px;
`;

const MobilityImg = styled.img`
    width: 100%;
    object-fit: fill;
`;

const AssesementResultInnerLayout = styled.div<{ $top: string; $right: string }>`
    position: absolute;
    top: ${(props) => props.$top};
    right: ${(props) => props.$right};
    display: flex;
    flex-direction: column;
`;

/// --------------------------------- Item Styles ---------------------------------
const AssseesmentResultCircle = styled(Caption1_SemiBold)`
    width: 20px;
    height: 20px;
    background-color: ${palette.PrimaryBlue};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
`;

const AssesementResultItemTitle = styled(Body4_SemiBold)`
    color: ${palette.PrimaryBlue};
`;

const AssesementResultItemDescription = styled(Body1_Bold)`
    color: ${palette.PrimaryBlueStrong};
    padding: 0 0 0 24px;
`;

const AssesementResultItemRanking = styled(Body4_SemiBold)`
    padding-left: 24px;
    color: ${palette.PrimaryBlueStrong};
`;

const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
`;
