import styled from 'styled-components';

export const MTEMembershipLayout = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1600px;
    // max-width: 1600px;
    // height: 100vh;
    padding: 79px 117px 0 117px;
    scroll: auto;
    gap: 82px;
`;
