import HeaderLine from 'components/Common/HeaderLine';
import MTEReportFooter from 'components/Common/MTEReportFooter';
import MTEReportHeader from 'components/Common/MTEReportHeader';
import { OptimizedProgram } from 'components/WalkingAssessment/OptimizedProgram';
import { RunningIntensity } from 'components/WalkingAssessment/RunningIntensity';
import { Vo2MaxDiagnosis } from 'components/WalkingAssessment/Vo2MaxDiagnosis';
import { WalkingAssessmentTable } from 'components/WalkingAssessment/WalkingAssessmentTable';
import { WalkingSpeedAverage } from 'components/WalkingAssessment/WalkingSpeedAverage';
import { UserInfo } from 'models/MTE/totalAssessmentPageTypes';
import { WalkingAssessmentProps } from 'models/MTE/walkingAssessmentTypes';
import { InnerBodyContainer, InnerBodyLayout } from 'styles/ReportStyles/reportStyles';

export const WalkingAssessement = ({
    chartProps,
    diagnosisProps,
    tableProps,
    optimizedProgramProps,
    runningIntensityProps,
    userInfo,
    userAge,
    formattedDate,
    formattedTime,
}: WalkingAssessmentProps & { userInfo: UserInfo; userAge: string; formattedDate: string; formattedTime: string }) => {
    const { speedChartProps, distanceChartProps, percentile } = chartProps;
    const { distance, vo2Max, vo2Average, mets, speed, bidBefore, bidAfter, hrBefore, hrAfter, spo2Before, spo2After } = tableProps;
    const { cardiorespiratoryAge, memo, totalScoreResult } = diagnosisProps;
    const {
        outdoorGoalHigh,
        outdoorGoalLow,
        outdoorGoalMid,
        circulatorySafezoneGuide: safezoneGuideHigh,
        metabolicSafezoneGuide: safezoneGuideLow,
        targetAerobicExercise,
        targetBurnFatExercise,
        targetHighIntensityExercise,
    } = runningIntensityProps;

    const { heartRateZone, highIntensityRun, recoveryRun } = optimizedProgramProps;

    return (
        <InnerBodyLayout>
            <HeaderLine />
            <InnerBodyContainer>
                <MTEReportHeader $title='TLC MTE 처방 리포트 | 6분 걷기 검사' userInfo={userInfo} userAge={userAge} formattedDate={formattedDate} formattedTime={formattedTime} />
                <WalkingAssessmentTable
                    distance={distance}
                    vo2Max={vo2Max}
                    vo2Average={vo2Average}
                    mets={mets}
                    speed={speed}
                    bidBefore={bidBefore}
                    bidAfter={bidAfter}
                    hrBefore={hrBefore}
                    hrAfter={hrAfter}
                    spo2Before={spo2Before}
                    spo2After={spo2After}
                />
                <Vo2MaxDiagnosis cardiorespiratoryAge={cardiorespiratoryAge} memo={memo} totalScoreResult={totalScoreResult} vo2MaxPercentile={diagnosisProps.vo2MaxPercentile} vo2Max={vo2Max} />
                <WalkingSpeedAverage distanceChartProps={distanceChartProps} speedChartProps={speedChartProps} percentile={percentile} />
                <RunningIntensity
                    outdoorGoalHigh={outdoorGoalHigh}
                    outdoorGoalLow={outdoorGoalLow}
                    outdoorGoalMid={outdoorGoalMid}
                    circulatorySafezoneGuide={safezoneGuideHigh}
                    metabolicSafezoneGuide={safezoneGuideLow}
                    targetAerobicExercise={targetAerobicExercise}
                    targetBurnFatExercise={targetBurnFatExercise}
                    targetHighIntensityExercise={targetHighIntensityExercise}
                />
                <OptimizedProgram heartRateZone={heartRateZone} highIntensityRun={highIntensityRun} recoveryRun={recoveryRun} />
            </InnerBodyContainer>
            <MTEReportFooter />
        </InnerBodyLayout>
    );
};
