import styled from 'styled-components';

export const InnerBodyLayout = styled.section`
    display: flex;
    flex-direction: column;
    width: 210mm;
    height: 297mm;
    background-color: white;
    border: 1px solid black;

    @media print {
        width: 210mm;
        height: 297mm;
        margin: 0 auto;
    }
`;

export const InnerBodyContainer = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 20px 36px 16px 36px;
`;

export const ReportCircle = styled.div`
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 501%;
    background-color: #101010;
`;
