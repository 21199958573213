import MTETriangle from 'assets/MTETriangle';
import { BDIAssessmentType } from 'models/MTE/ReportInput/assessmentInputTypes';
import { ChangeEvent, Dispatch, FocusEventHandler, forwardRef, HTMLAttributes, SetStateAction, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Neutral } from 'styles/Global/colorPrimitive';
import { BackgroundColor, BorderColor, TextColor, WarningColor } from 'styles/Global/colorSemantic';
import { font } from 'styles/Global/globalStyles';
import { Body2_Medium, Body3_Medium_Input, Caption1_Regular } from 'styles/Global/typography';
import { DropdownCell, DropdownLayout } from './Dropdown';

export namespace Assessment {
    interface InputProps extends HTMLAttributes<HTMLInputElement> {
        unit?: string;
        width?: number;
        value?: string;
        disabled?: boolean;
        onBlur?: () => void;
    }

    /// YN => Yes or No, SF => Success or Fail
    export type selectType = 'YN' | 'SF';

    const SFKeys = ['성공', '실패'];
    const YNKeys = ['네', '아니오'];

    interface SelectProps extends HTMLAttributes<HTMLButtonElement> {
        selectType: selectType;
        onTap: (key: boolean) => void;
        selctedKey?: boolean | undefined;
        disabled?: boolean;
    }

    export interface ErrMsgProps {
        $marginTop?: string;
    }

    export interface BasicInputProps {
        desc?: string;
        label: string;
        placeholder?: string;
        onChange?: (e: ChangeEvent<HTMLInputElement>, id: string) => void;
        id: string;
        disabled?: boolean;
        focus?: boolean;
        errorMsg?: string;
        value: string;
        onFocus?: FocusEventHandler<HTMLInputElement>;
        onClick?: () => void;
        onBlur?: () => void;
        onBlurValue?: (id: string) => void;
        enterKey?: (e: any) => void;
        onKeyDown?: (e: any, id: string) => void;
        setSelectIdx?: Dispatch<SetStateAction<number | undefined>>;
        $labelfontWeight?: number;
    }

    export interface InputWithUnitProps extends BasicInputProps {
        unit?: string;
    }

    export interface InputContainerStyleProps {
        $height?: string;
        $width?: string;
        $warning?: string | undefined;
        $cursor?: string;
    }

    export interface DropdownWithTitleProps<T extends BDIAssessmentType> {
        $children: T[];
        $title: string;
        $onSelect: (value: T) => void;
        $selectedChild?: T | undefined;
    }

    //--------------------------------Components--------------------------------

    export const DropdownWithTitle = ({ $children, $title, $onSelect, $selectedChild }: DropdownWithTitleProps<BDIAssessmentType>) => {
        const [dropdownState, setDropdownState] = useState(false);
        const dropdownRef = useRef<HTMLDivElement>(null);

        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownState(false);
            }
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return (
            <AssessmentDropdownLayout>
                <AssessmentMediumTitle>{$title}</AssessmentMediumTitle>
                <AsssessmentDropdownContainer
                    onClick={() => {
                        setDropdownState(!dropdownState);
                    }}
                    ref={dropdownRef}
                >
                    {$selectedChild && (
                        <UnitText>
                            {$selectedChild.score} {$selectedChild.name}
                        </UnitText>
                    )}
                    {!$selectedChild && <UnitText></UnitText>}
                    <MTETriangle $reverse={dropdownState} />
                    {dropdownState && (
                        <DropdownLayout
                            $height='160px'
                            $position='absolute'
                            $width='264px'
                            $padding='10px'
                            $gap='10px'
                            $top='50px'
                            $left='0px'
                            children={$children.map((child, index) => (
                                <DropdownCell
                                    key={index}
                                    $height='40px'
                                    $padding='20px 0'
                                    $selected={false}
                                    onClick={(e) => {
                                        $onSelect(child);
                                        setDropdownState(false);
                                    }}
                                >
                                    {child.score} {child.name}
                                </DropdownCell>
                            ))}
                        />
                    )}
                </AsssessmentDropdownContainer>
            </AssessmentDropdownLayout>
        );
    };

    export const WithOutSubLabelInputWithLabel = forwardRef<HTMLInputElement, InputWithUnitProps>((props, ref) => {
        const { label, placeholder, onChange, id, disabled, focus, errorMsg, value, onFocus, enterKey, onBlur, unit } = props;

        return (
            <WithOutBasicInputLayout $maxWidth={264}>
                {label && <AssessmentMediumTitle>{label}</AssessmentMediumTitle>}
                <InputRelativeWrapper $width='264px'>
                    <BasicInput
                        ref={ref}
                        id={id}
                        $height='40px'
                        $width='264px'
                        disabled={disabled}
                        onChange={(e) => onChange && onChange(e, id === 'mobile' && isNaN(Number(e.target.value)) ? 'error' : id)}
                        placeholder={placeholder}
                        autoFocus={focus ?? false}
                        value={value}
                        $warning={errorMsg}
                        onFocus={onFocus}
                        onBlur={() => {
                            onBlur && onBlur();
                        }}
                        onKeyUp={enterKey}
                    />
                    {unit && <AbsoluteUnitText>{unit}</AbsoluteUnitText>}
                </InputRelativeWrapper>
                {errorMsg && <ErrorMsg $marginTop='0'>{errorMsg}</ErrorMsg>}
            </WithOutBasicInputLayout>
        );
    });

    export const Input = ({ unit, onChange, width, value, disabled, onBlur }: InputProps) => {
        return (
            <AsssessmentInputContainer $width={width} $disabled={disabled}>
                <InputText onChange={onChange} value={value ?? ''} disabled={disabled} onBlur={onBlur} />
                {unit && <UnitText>{unit}</UnitText>}
            </AsssessmentInputContainer>
        );
    };

    export const Selects = ({ selectType, onTap, selctedKey, disabled }: SelectProps) => {
        const keys = selectType === 'SF' ? SFKeys : YNKeys;
        return (
            <SelectButtonLayout>
                {keys.map((key, index) => (
                    <SelectButton
                        key={key}
                        $width='87px'
                        $height='40px'
                        $disabled={disabled}
                        onClick={() => {
                            if (disabled) return;
                            if (selectType === 'SF') key === '성공' ? onTap(true) : onTap(false);
                            if (selectType === 'YN') key === '네' ? onTap(true) : onTap(false);
                        }}
                        $selected={
                            (selectType === 'SF' && (selctedKey === undefined ? undefined : selctedKey ? key === '성공' : key === '실패')) ||
                            (selectType === 'YN' && (selctedKey === undefined ? undefined : selctedKey ? key === '네' : key === '아니오'))
                        }
                    >
                        {key}
                    </SelectButton>
                ))}
            </SelectButtonLayout>
        );
    };

    export const WithOutBasicInputLayout = styled.div<{ $maxWidth?: number }>`
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        ${(props) => props.$maxWidth && `max-width: ${props.$maxWidth}px;`}
    `;

    export const InputRelativeWrapper = styled.div<InputContainerStyleProps>`
        position: relative;
        width: ${(props) => props.$width || '100%'};
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    `;

    export const BasicInput = styled(Body3_Medium_Input)<InputContainerStyleProps>`
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: ${(props) => (props.$warning ? `1px solid ${WarningColor.color_warning_normal}` : `1px solid ${BorderColor.color_border_normal}`)};
        padding: 18px 20px;
        height: ${(props) => props.$height || '60px'};
        width: ${(props) => props.$width || '100%'};
        letter-spacing: ${(props) => props.id === 'identityNumber' && '3px'};
        caret-color: ${(props) => props.$warning && 'red'};
        cursor: ${(props) => props.$cursor};
        background-color: ${BackgroundColor.color_background_white};
        &[type='number']::-webkit-inner-spin-button,
        &[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &:hover {
            border: ${(props) => (props.$warning ? `1px solid ${WarningColor.color_warning_normal_hover}` : `1px solid ${BorderColor.color_border_normal_hover}`)};
        }
        &:focus {
            border: ${(props) => (props.$warning ? `1px solid ${WarningColor.color_warning_normal}` : `1px solid ${BorderColor.color_border_normal_press}`)};
        }
        &:disabled {
            border: 1px solid ${BorderColor.color_border_normal};
            background-color: ${BackgroundColor.color_background_disabled};
            color: ${TextColor.color_text_normal};
        }
        &::placeholder {
            color: ${Neutral.color_neutral_300};
            font-size: 20px;
            font-family: Pretendard;
            line-height: 38px;
            font-weight: 600;
        }
    `;

    export const InputLabel = styled(Body2_Medium)<{ $marginBottom?: string | undefined }>`
        margin-bottom: ${(props) => props.$marginBottom || '8px'};
    `;

    export const ErrorMsg = styled(Caption1_Regular)<ErrMsgProps>`
        margin-top: ${(props) => props.$marginTop || '8px'};
        color: ${WarningColor.color_warning_normal};
    `;

    const SelectButtonLayout = styled.div`
        display: flex;
        flex-direction: row;
        gap: 13px;
        align-items: space-between;
    `;

    const SelectButton = styled.button<{ $width: string; $height: string; $gap?: number; $selected?: boolean; $disabled?: boolean }>`
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${(props) => props.$width ?? '100%'};
        height: ${(props) => props.$height || '60px'};
        border-radius: 4px;
        font-size: ${font.size[16]};
        font-weight: ${font.weight.medium};
        gap: ${(props) => props.$gap || '0'};
        border: 1px solid ${BorderColor.color_border_strong};
        color: ${TextColor.color_text_light};
        background-color: ${(props) => (props.$selected ? BackgroundColor.color_background_primary_normal : BackgroundColor.color_background_white)};

        ${(props) =>
            !props.$disabled &&
            `
        &:hover && {
            border: 1px solid ${BorderColor.color_border_normal_hover};
            background-color: ${BackgroundColor.color_background_primary_normal};
            color: ${TextColor.color_text_light};
        }
        &:active {
            border: 1px solid ${BorderColor.color_border_normal_press};
            background-color: ${BackgroundColor.color_background_primary_strong};
            color: ${TextColor.color_text_normal};
        }
        &:focus {
            border: 1px solid ${BorderColor.color_border_normal_press};
            background-color: ${BackgroundColor.color_background_primary_strong};
            color: ${TextColor.color_text_normal};
        }`}
        ${(props) =>
            props.$disabled &&
            `
            border: 1px solid ${BorderColor.color_border_normal};
            background-color: ${BackgroundColor.color_background_disabled};
            color: ${TextColor.color_text_weak};
            cursor: not-allowed;`}
    `;

    const AsssessmentInputContainer = styled.div<{ $width?: number; $disabled?: boolean }>`
        border: 0.5px solid #6f6f6f;
        border-radius: 8px;
        padding: 6px 18px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        min-height: 40px;
        ${(props) => props.$disabled && `background-color: ${BackgroundColor.color_background_disabled};`}
        ${({ $width }) => !$width && `min-width: 164px;`}
        ${({ $width }) => ($width ? `width: ${$width}px;` : `width: 264px;`)}
    `;

    const AssessmentDropdownLayout = styled.div`
        display: flex;
        flex-direction: column;
        gap: 4px;
    `;

    const AssessmentMediumTitle = styled(Body2_Medium)`
        color: ${TextColor.color_text_placeholder};
    `;

    const AsssessmentDropdownContainer = styled.div<{ $width?: number; $disabled?: boolean }>`
        border: 0.5px solid #6f6f6f;
        border-radius: 8px;
        padding: 5px 18px;
        align-items: center;
        justify-content: space-between;
        position: relative;
        display: flex;
        flex-direction: row;
        height: 40px;
        ${(props) => props.$disabled && `background-color: ${BackgroundColor.color_background_disabled};`}
        ${({ $width }) => !$width && `min-width: 264px;`}
    ${({ $width }) => ($width ? `width: ${$width}px;` : `width: 264px;`)}
    `;

    const UnitText = styled(Body2_Medium)`
        color: ${TextColor.color_text_placeholder};
    `;

    export const AbsoluteUnitText = styled(UnitText)`
        position: absolute;
        right: 18px;
        top: 6px;
    `;

    const InputText = styled.input`
        font-size: 18px;
        font-height: 24px;
        display: flex;
        height: 24px;
        padding: 0;
        border: none;
        outline: none;
        width: 100%;
        background-color: transparent;
        text-align: left;
        margin: 0;
    `;
}
