import BasicButton from 'components/Buttons/BasicButton';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MTEHeaderNumberBadge } from 'styles/Global/badgeStyles';
import { Common } from 'styles/Global/colorPrimitive';
import { Body2_Medium, Display1_Bold, Title1_SemiBold } from 'styles/Global/typography';

const MTEHeader = (props: { onReset?: () => void }) => {
    const { pathname } = useLocation();
    return (
        <Layout>
            <Title>MTE 운동 검사 보고서</Title>
            <StepContainer>
                <StepWrapper>
                    <MTEHeaderNumberBadge $hasCurrent={pathname.includes('user-information')}>1</MTEHeaderNumberBadge>
                    <StepTitle>회원정보 입력</StepTitle>
                </StepWrapper>
                <StepWrapper>
                    <MTEHeaderNumberBadge $hasCurrent={pathname.includes('report-input')}>2</MTEHeaderNumberBadge>
                    <StepTitle>운동검사 결과 입력</StepTitle>
                </StepWrapper>
                <ButtonWrapper>
                    <BasicButton $width='200px' $height='56px' $type='fill' onClick={props.onReset}>
                        <ButtonText>초기화</ButtonText>
                    </BasicButton>
                </ButtonWrapper>
            </StepContainer>
        </Layout>
    );
};

export default MTEHeader;

const Layout = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const Title = styled(Display1_Bold)``;
const ButtonText = styled(Body2_Medium)`
    color: ${Common.color_white};
`;

const StepContainer = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    gap: 70px;
`;

const StepWrapper = styled.div`
    display: flex;
    gap: 16px;
    height: 100%;
    align-items: center;
`;

const ButtonWrapper = styled.div`
    margin-left: 20px;
`;

const StepTitle = styled(Title1_SemiBold)``;
