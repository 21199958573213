import styled from 'styled-components';
import { palette } from 'styles/Global/globalStyles';

const HeaderLine = () => {
    return (
        <HeaderLineLayout>
            <HeaderSegment color={palette.PrimaryBlueStrong} />
            <HeaderSegment color={palette.PrimaryBlue} />
            <HeaderSegment color={palette.PrimaryGray} />
        </HeaderLineLayout>
    );
};

export default HeaderLine;

const HeaderLineLayout = styled.div`
    display: flex;
    width: 100%;
    min-height: 14px;
    background-color: black;
`;

const HeaderSegment = styled.div<{ color: string }>`
    flex: 1;
    background-color: ${(props) => props.color};
`;
