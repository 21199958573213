import { Assessment } from 'components/Common/Assessment';
import { MuscularEnduranceAssessmentInputProps } from 'models/MTE/ReportInput/assessmentInputTypes';
import React from 'react';
import {
    AssessmentName,
    AsssessmentInputWrapper,
    ContainerBodyWrapper,
    ContainerHeaderWrapper,
    ContainerLayout,
    InnerDivider,
    SubTitle,
    Title,
    WidthSpacer,
} from 'styles/ReportStyles/MTEReportInputStyles';
import { NUMBER_4_DIGIT_1_REGEX } from 'utils/regex';

export const MuscularEnduranceAssessment = React.memo(({ onChangedSitStand, sitStand }: MuscularEnduranceAssessmentInputProps) => {
    return (
        <ContainerLayout>
            <ContainerHeaderWrapper>
                <Title>4. 근지구력 평가</Title>
                <WidthSpacer $width={18} />
                <SubTitle>60초 앉아 일어서기</SubTitle>
            </ContainerHeaderWrapper>
            <InnerDivider />
            <ContainerBodyWrapper>
                <AsssessmentInputWrapper>
                    <AssessmentName>앉아 일어선 횟수</AssessmentName>
                    <Assessment.Input
                        value={sitStand}
                        unit='회'
                        onChange={(e) => {
                            NUMBER_4_DIGIT_1_REGEX.test(e.currentTarget.value) && onChangedSitStand(e.currentTarget.value);
                        }}
                    />
                </AsssessmentInputWrapper>
            </ContainerBodyWrapper>
        </ContainerLayout>
    );
});
