import { Assessment } from 'components/Common/Assessment';
import { TUGAssessmentInputProps } from 'models/MTE/ReportInput/assessmentInputTypes';
import React from 'react';
import {
    AssessmentName,
    AsssessmentInputWrapper,
    ContainerBodyWrapper,
    ContainerHeaderWrapper,
    ContainerLayout,
    InnerDivider,
    SubTitle,
    Title,
    WidthSpacer,
} from 'styles/ReportStyles/MTEReportInputStyles';
import { NUMBER_4_DIGIT_1_REGEX } from 'utils/regex';

export const TUGAssessment = React.memo(({ onChangeTugTime, tugTime }: TUGAssessmentInputProps) => {
    return (
        <ContainerLayout>
            <ContainerHeaderWrapper>
                <Title>2. TUG 검사</Title>
                <WidthSpacer $width={18} />
                <SubTitle>일어나 걷기 검사로 일상생활에서의 기능적 능력을 측정하는 간단한 검사</SubTitle>
            </ContainerHeaderWrapper>
            <InnerDivider />
            <ContainerBodyWrapper>
                <AsssessmentInputWrapper>
                    <AssessmentName>TUG 시간</AssessmentName>
                    <Assessment.Input unit='초' onChange={(e) => NUMBER_4_DIGIT_1_REGEX.test(e.currentTarget.value) && onChangeTugTime(e.currentTarget.value)} value={tugTime} />
                </AsssessmentInputWrapper>
            </ContainerBodyWrapper>
        </ContainerLayout>
    );
});
