// @ts-ignore
import Lottie from 'react-lottie';
import LoadingJson from 'assets/Icons/Loading/loading.json';
import styled from 'styled-components';
import { zIndex } from 'styles/Global/globalStyles';

const Loading = ({ loading, width = 100, height = 100 }: { loading: boolean; width?: number; height?: number }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: LoadingJson,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <LoadingLayout style={{ display: loading ? 'block' : 'none' }}>
            <LoadingIndicator>
                <Lottie options={defaultOptions} height={height} width={width} speed={2} />
            </LoadingIndicator>
        </LoadingLayout>
    );
};

export default Loading;

const LoadingLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: ${zIndex.loading};
    overflow: auto;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
`;

const LoadingIndicator = styled.div`
    top: 45%;
    left: calc(50% - 75px);
    position: fixed;
`;