export const pagePaths = {
    userInformation: '/MTE/user-information',
    reportInput: '/MTE/report-input',
    report: '/MTE/report',
    reportList: '/MTE/reportList',
};

const reportDepth = 'exReport';

export const apiPath = {
    createReport: `${process.env.REACT_APP_API_NODE}/${reportDepth}/createReport`,
    getReport: `${process.env.REACT_APP_API_NODE}/${reportDepth}/getReport`,
    deleteReport: `${process.env.REACT_APP_API_NODE}/${reportDepth}/deleteReport`,
};
