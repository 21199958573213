import { Assessment } from 'components/Common/Assessment';
import Divider from 'components/Common/Divider';
import { SPPBAssessmentInputProps } from 'models/MTE/ReportInput/assessmentInputTypes';
import React from 'react';
import styled from 'styled-components';
import { Common } from 'styles/Global/colorPrimitive';
import { BackgroundColor, TextColor } from 'styles/Global/colorSemantic';
import { Title2_SemiBold } from 'styles/Global/typography';
import { NUMBER_4_DIGIT_1_REGEX } from 'utils/regex';

export const SPPBAssessment = React.memo(
    ({
        isGeneralPosture,
        isLinearPosture,
        isNonLinearPosture,
        isStand,
        isWalk,
        standTime,
        walkTime,
        onEvent,
        isLinearPostureDisabled,
        isStandDisabled,
        isWalkDisabled,
        isNoneLinearPostureDisabled,
    }: SPPBAssessmentInputProps) => {
        return (
            <ContainerLayout>
                <ContainerHeaderWrapper>
                    <Title>1. SPPB검사</Title>
                    <WidthSpacer $width={18} />
                    <SubTitle>간편신체기능검사로 신체기능, 노쇠정도, 근감소증 여부 및 낙상 위험도를 평가</SubTitle>
                </ContainerHeaderWrapper>
                <InnerDivider />
                <ContainerBodyWrapper>
                    <ItemContainer $minWidth={387}>
                        <AssessmentName>1) 정적균형</AssessmentName>
                        <ItemWrapper>
                            <SPPBAssessmentInputWrapper>
                                <Title> • 일반자세</Title>
                                <Assessment.Selects onTap={(e) => onEvent('isGeneralPosture', e)} selectType='SF' selctedKey={isGeneralPosture} />
                            </SPPBAssessmentInputWrapper>
                            <SPPBAssessmentInputWrapper>
                                <Title> • 반일렬 자세</Title>
                                <Assessment.Selects onTap={(e) => onEvent('isNonLinearPosture', e)} selectType='SF' selctedKey={isNonLinearPosture} disabled={isNoneLinearPostureDisabled} />
                            </SPPBAssessmentInputWrapper>
                            <SPPBAssessmentInputWrapper>
                                <Title> • 일렬자세</Title>
                                <Assessment.Input
                                    onChange={(e) => NUMBER_4_DIGIT_1_REGEX.test(e.currentTarget.value) && onEvent('isLinearPosture', e.currentTarget.value)}
                                    value={isLinearPosture}
                                    unit='초'
                                    width={183}
                                    disabled={isLinearPostureDisabled}
                                />
                            </SPPBAssessmentInputWrapper>
                        </ItemWrapper>
                    </ItemContainer>

                    <ItemContainer>
                        <AssessmentName>2) 보행속도</AssessmentName>
                        <ItemWrapper>
                            <SPPBAssessmentInputWrapper>
                                <ItemFlexWrapper>
                                    <TitleWrapper $width={334}>
                                        <Title>검사자 스스로 보행이 가능합니까?</Title>
                                    </TitleWrapper>
                                    <Assessment.Selects onTap={(e) => onEvent('isWalk', e)} selectType='YN' selctedKey={isWalk} />
                                </ItemFlexWrapper>
                            </SPPBAssessmentInputWrapper>
                            <SPPBAssessmentInputWrapper>
                                <Title>수행 시간</Title>
                                <Assessment.Input
                                    onChange={(e) => NUMBER_4_DIGIT_1_REGEX.test(e.currentTarget.value) && onEvent('walkTime', e.currentTarget.value)}
                                    value={walkTime}
                                    unit='초'
                                    width={164}
                                    disabled={isWalkDisabled}
                                />
                            </SPPBAssessmentInputWrapper>
                        </ItemWrapper>
                    </ItemContainer>
                    <ItemContainer $isLast={true}>
                        <AssessmentName>3) 일어서기</AssessmentName>
                        <ItemWrapper>
                            <SPPBAssessmentInputWrapper>
                                <ItemFlexWrapper>
                                    <TitleWrapper $width={426}>
                                        <Title>검사자가 팔을 사용하지 않고 앉아 일어서기 가능합니까?</Title>
                                    </TitleWrapper>
                                    <Assessment.Selects onTap={(e) => onEvent('isStand', e)} selectType='YN' selctedKey={isStand} />
                                </ItemFlexWrapper>
                            </SPPBAssessmentInputWrapper>
                            <SPPBAssessmentInputWrapper>
                                <Title>수행 시간</Title>
                                <Assessment.Input
                                    onChange={(e) => NUMBER_4_DIGIT_1_REGEX.test(e.currentTarget.value) && onEvent('standTime', e.currentTarget.value)}
                                    value={standTime}
                                    unit='초'
                                    width={183}
                                    disabled={isStandDisabled}
                                />
                            </SPPBAssessmentInputWrapper>
                        </ItemWrapper>
                    </ItemContainer>
                </ContainerBodyWrapper>
            </ContainerLayout>
        );
    }
);

export const TitleWrapper = styled.div<{ $width?: number }>`
    ${({ $width }) => ($width ? `width: ${$width}px;` : 'width: 100%;')}
`;
export const Title = styled(Title2_SemiBold)``;

export const SubTitle = styled(Title2_SemiBold)`
    color: ${TextColor.color_text_placeholder};
`;

export const InnerDivider = styled(Divider)`
    color: #6f6f6f;
`;

export const WidthSpacer = styled.div<{ $width?: number }>`
    width: ${({ $width }) => $width || 20}px;
    height: 100%;
`;

export const ContainerLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ContainerHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
    width: 100%;
`;

export const ContainerBodyWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 40px 20px;
    height: 550px;
`;

export const AssessmentName = styled(Title2_SemiBold)`
    color: ${BackgroundColor.color_background_primary};
`;

const ItemContainer = styled.div<{ $isLast?: boolean; $flex?: number; $padding?: string; $minWidth?: number }>`
    display: flex;
    gap: 32px;
    flex-direction: column;
    ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth}px;`}
    ${({ $padding }) => ($padding ? `padding: ${$padding};` : 'padding: 0 20px;')}
    ${({ $flex }) => ($flex ? `flex: ${$flex};` : `flex: 1;`)}
    ${({ $isLast }) => $isLast !== true && `border-right: 1px solid ${Common.color_black};`}
`;

const ItemWrapper = styled.div`
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const ItemFlexWrapper = styled.div`
    flex-direction: column;
    display: flex;
    gap: 18px;
`;

const SPPBAssessmentInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;
