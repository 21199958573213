import { create } from 'zustand';

export interface useLodaingStoreType {
    loading: boolean;
    setLoading: (loading: boolean) => void;
}

export const useLoadingStore = create<useLodaingStoreType>((set) => ({
    loading: false,
    setLoading: (loading: boolean) => {
        set(() => ({
            loading: loading,
        }));
    },
}));