import { palette } from 'styles/Global/globalStyles';
import { SPPBScoresType } from './reportAllPageTypes';
import { getReportResponseType } from './reportApiTypes';
import { UserInfo } from './totalAssessmentPageTypes';

export interface SPPBBodyPropsTypes {
    $title: string;
    $score: number;
}

export interface SPPBBodyListPropsTypes {
    $item: {
        title: string;
        score: number;
        second: string;
        list: string[];
    };
    $index: number;
}

export type SPPBListType = {
    key: string;
    title: string;
    score: number;
    second: string | number;
    list: string[];
};

/// gender 0: 남자, 1: 여자
export namespace AssessmentResult {

    export const fallRiskBenchMarkData = [
        { name: '위험', value: 100 / 3, color: palette.Graph.danger },
        { name: '주의', value: 100 / 3, color: palette.Graph.caution },
        { name: '양호', value: 100 / 3, color: palette.Graph.good },
    ];

    export const sarcopeniaBenchMarkData = [
        { name: '위험', value: 100 / 2.6, color: palette.Graph.danger },
        { name: '', value: 100 / 2.4, color: palette.Graph.danger },
        { name: '주의', value: 100 / 2.5, color: palette.Graph.caution },
        { name: '양호', value: 100 / 2.5, color: palette.Graph.good },
    ];

    // 양호, 주의, 위험 순서
    export type fallRiskCondition = '양호' | '주의' | '위험';
    type fallRiskSubTitle = '꾸준한 관리로 예방하기' | '신체 기능의 유지 및 개선 필요' | '방문요양, 간호 등의 서비스 필요';

    // Todo: fallRiskCondition에 따라 서브 타이틀 반환
    export const getFallRiskSubTitle = ({ $fallRiskCondition }: { $fallRiskCondition: fallRiskCondition }): fallRiskSubTitle => {
        switch ($fallRiskCondition) {
            case '양호':
                return '꾸준한 관리로 예방하기';
            case '주의':
                return '신체 기능의 유지 및 개선 필요';
            case '위험':
                return '방문요양, 간호 등의 서비스 필요';
        }
    };

    export enum Gender {
        Male = 0,
        Female = 1,
    }

    export interface WalkingAssessmentResultProps {
        data: getReportResponseType;
    }

    export interface FallRiskAssessmentResultProps {
        $walkingMeterPerSecond: number;
        $gender: Gender;
    }
    export interface SarcopeniaAssessmentResultProps {
        $score: number;
    }
}

export type Tug = { tugTime: number; explainTugTime: string; tugState: string; divideTime: number };

type sarcopeniaResultScore = {
    allSPPBScore: number;
    allSPPBScoreGrade: string;
    explainSarcopeniaResult: string;
};

export interface SarcopeniaAssessmentProps {
    SPPBAssessmentList: SPPBListType[];
    walkingSpeedData: walkingSpeedDataType;
    tug: Tug;
    sarcopeniaResultScore: sarcopeniaResultScore;
    userInfo: UserInfo;
    SPPBScores: SPPBScoresType;
    userAge: string;
    formattedDate: string;
    formattedTime: string;
}

export type walkingSpeedDataType = {
    walkingMeterPerSecond: number;
    walkingAge: string;
    walkingGrade: string;
};

interface GradeRange {
    min: number;
    max: number;
}

interface AgeGroup {
    [grade: string]: GradeRange;
}
interface GenderGradeTable {
    [age: string]: AgeGroup;
}

interface AvarageGenderGradeTable {
    [age: string]: GradeRange;
}

interface WalkingSpeedGradeTableType {
    male: GenderGradeTable;
    female: GenderGradeTable;
}

interface averageWalkingSpeedTableType {
    male: AvarageGenderGradeTable;
    female: AvarageGenderGradeTable;
}

export const walkingSpeedGradeTable: WalkingSpeedGradeTableType = {
    male: {
        '2': {
            훌륭함: {
                min: 2.16,
                max: 100,
            },
            좋음: {
                min: 1.8,
                max: 2.15,
            },
            보통: {
                min: 1.6,
                max: 1.79,
            },
            약함: {
                min: 1.4,
                max: 1.59,
            },
            매우약함: {
                min: 0,
                max: 1.39,
            },
        },
        '3': {
            훌륭함: {
                min: 2.11,
                max: 100,
            },
            좋음: {
                min: 1.75,
                max: 2.1,
            },
            보통: {
                min: 1.55,
                max: 1.74,
            },
            약함: {
                min: 1.35,
                max: 1.54,
            },
            매우약함: {
                min: 0,
                max: 1.34,
            },
        },
        '4': {
            훌륭함: {
                min: 2.06,
                max: 100,
            },
            좋음: {
                min: 1.7,
                max: 2.05,
            },
            보통: {
                min: 1.5,
                max: 1.69,
            },
            약함: {
                min: 1.3,
                max: 1.49,
            },
            매우약함: {
                min: 0,
                max: 1.29,
            },
        },
        '5': {
            훌륭함: {
                min: 2.01,
                max: 100,
            },
            좋음: {
                min: 1.65,
                max: 2.0,
            },
            보통: {
                min: 1.45,
                max: 1.64,
            },
            약함: {
                min: 1.25,
                max: 1.44,
            },
            매우약함: {
                min: 0,
                max: 1.24,
            },
        },
        '6': {
            훌륭함: {
                min: 1.86,
                max: 100,
            },
            좋음: {
                min: 1.55,
                max: 1.85,
            },
            보통: {
                min: 1.35,
                max: 1.54,
            },
            약함: {
                min: 1.34,
                max: 1.15,
            },
            매우약함: {
                min: 0,
                max: 1.14,
            },
        },
        '7': {
            훌륭함: {
                min: 1.59,
                max: 100,
            },
            좋음: {
                min: 1.3,
                max: 1.6,
            },
            보통: {
                min: 1.1,
                max: 1.29,
            },
            약함: {
                min: 1.09,
                max: 0.9,
            },
            매우약함: {
                min: 0,
                max: 0.89,
            },
        },
        '8': {
            훌륭함: {
                min: 1.26,
                max: 100,
            },
            좋음: {
                min: 1,
                max: 1.25,
            },
            보통: {
                min: 0.75,
                max: 0.99,
            },
            약함: {
                min: 0,
                max: 0.74,
            },
            매우약함: {
                min: 0,
                max: 0,
            },
        },
    },
    female: {
        '2': {
            훌륭함: {
                min: 2.01,
                max: 100,
            },
            좋음: {
                min: 1.7,
                max: 2,
            },
            보통: {
                min: 1.5,
                max: 1.69,
            },
            약함: {
                min: 1.3,
                max: 1.49,
            },
            매우약함: {
                min: 0,
                max: 1.29,
            },
        },
        '3': {
            훌륭함: {
                min: 1.96,
                max: 100,
            },
            좋음: {
                min: 1.65,
                max: 1.95,
            },
            보통: {
                min: 1.45,
                max: 1.64,
            },
            약함: {
                min: 1.25,
                max: 1.44,
            },
            매우약함: {
                min: 0,
                max: 1.24,
            },
        },
        '4': {
            훌륭함: {
                min: 1.91,
                max: 100,
            },
            좋음: {
                min: 1.6,
                max: 1.9,
            },
            보통: {
                min: 1.4,
                max: 1.59,
            },
            약함: {
                min: 1.2,
                max: 1.39,
            },
            매우약함: {
                min: 0,
                max: 1.19,
            },
        },
        '5': {
            훌륭함: {
                min: 1.86,
                max: 100,
            },
            좋음: {
                min: 1.55,
                max: 1.85,
            },
            보통: {
                min: 1.35,
                max: 1.54,
            },
            약함: {
                min: 1.15,
                max: 1.35,
            },
            매우약함: {
                min: 0,
                max: 1.14,
            },
        },
        '6': {
            훌륭함: {
                min: 1.71,
                max: 100,
            },
            좋음: {
                min: 1.4,
                max: 1.7,
            },
            보통: {
                min: 1.2,
                max: 1.39,
            },
            약함: {
                min: 1,
                max: 1.19,
            },
            매우약함: {
                min: 0,
                max: 0.99,
            },
        },
        '7': {
            훌륭함: {
                min: 1.46,
                max: 100,
            },
            좋음: {
                min: 1.2,
                max: 1.45,
            },
            보통: {
                min: 1,
                max: 1.19,
            },
            약함: {
                min: 0.8,
                max: 0.99,
            },
            매우약함: {
                min: 0,
                max: 0.79,
            },
        },
        '8': {
            훌륭함: {
                min: 1.11,
                max: 100,
            },
            좋음: {
                min: 0.9,
                max: 1.1,
            },
            보통: {
                min: 0.7,
                max: 0.89,
            },
            약함: {
                min: 0,
                max: 0.69,
            },
            매우약함: {
                min: 0,
                max: 0,
            },
        },
    },
};

export const averageWalkingSpeedTable: averageWalkingSpeedTableType = {
    male: {
        '2': {
            min: 1.6,
            max: 1.79,
        },
        '3': {
            min: 1.55,
            max: 1.74,
        },
        '4': {
            min: 1.5,
            max: 1.69,
        },
        '5': {
            min: 1.45,
            max: 1.64,
        },
        '6': {
            min: 1.35,
            max: 1.54,
        },
        '7': {
            min: 1.1,
            max: 1.29,
        },
        '8': {
            min: 0.75,
            max: 0.99,
        },
    },
    female: {
        '2': {
            min: 1.5,
            max: 1.69,
        },
        '3': {
            min: 1.45,
            max: 1.64,
        },
        '4': {
            min: 1.4,
            max: 1.59,
        },
        '5': {
            min: 1.35,
            max: 1.54,
        },
        '6': {
            min: 1.2,
            max: 1.39,
        },
        '7': {
            min: 1,
            max: 1.19,
        },
        '8': {
            min: 0.7,
            max: 0.89,
        },
    },
};
