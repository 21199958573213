import { LoginPageError } from 'constants/loginPageConstants';
import { InputValidateObject } from 'models/Common/validationTypes';
import { EMAIL_REGEX, HEIGHT_REGEX, NUMBER_REGEX, PASSWORD_REGEX, SIGN_UP_NAME_REGEX, SPACE_REGEX } from 'utils/regex';

// 회원가입 유효성 검사
export const validateCheck: InputValidateObject = {
    email: (email) => {
        if (EMAIL_REGEX.test(email)) {
            return '';
        } else if (SPACE_REGEX.test(email)) {
            return '공백은 사용할 수 없습니다.';
        } else if (email && !EMAIL_REGEX.test(email)) {
            return LoginPageError.changeEmailForm;
        } else {
            return '필수 정보입니다.';
        }
    },
    password: (password, value, setErrMsg) => {
        if (value && value.confirmPassword && password !== value.confirmPassword) {
            setErrMsg && setErrMsg((prev) => ({ ...prev, confirmPassword: '비밀번호가 일치하지 않습니다.' }));
        }

        if (SPACE_REGEX.test(password)) {
            return '공백은 사용할 수 없습니다.';
        } else if (value && value.confirmPassword === password && PASSWORD_REGEX.test(password)) {
            setErrMsg && setErrMsg((prev) => ({ ...prev, confirmPassword: '' }));
            return '';
        } else if (PASSWORD_REGEX.test(password)) {
            return '';
        } else if (password && !PASSWORD_REGEX.test(password)) {
            return '영문, 숫자 포함 8자 이상 입력해주세요.';
        } else {
            return '필수 정보입니다.';
        }
    },
    confirmPassword: (confirmPassword, value) => {
        if (value && confirmPassword && value.password === confirmPassword) {
            return '';
        } else if (value && confirmPassword && value.password !== confirmPassword) {
            return '비밀번호가 일치하지 않습니다.';
        } else {
            return '확인을 위해 비밀번호를 한 번 더 입력해주세요.';
        }
    },
    name: (name) => {
        if (SPACE_REGEX.test(name)) {
            return '공백은 사용할 수 없습니다.';
        } else if (!name) {
            return '필수 정보입니다.';
        } else if (SIGN_UP_NAME_REGEX.test(name)) {
            return '';
        } else {
            return '필수 정보입니다.';
        }
    },
    phoneNumber: (phoneNumber) => {
        if (phoneNumber.length === 0) {
            return '필수 정보입니다.';
        } else if (!NUMBER_REGEX.test(phoneNumber) || phoneNumber.length < 9) {
            return '연락처를 정확히 입력해주세요.';
        } else {
            return '';
        }
    },
    birthYear: (birthYear) => {
        const currentYear = new Date().getFullYear();
        if (SPACE_REGEX.test(birthYear)) {
            return '공백은 사용할 수 없습니다.';
        } else if (!birthYear) {
            return '필수 정보입니다.';
        } else if (!NUMBER_REGEX.test(birthYear)) {
            return '숫자만 입력해주세요.';
        } else {
            return '';
        }
        // else if (parseInt(birthYear) < 1900 || parseInt(birthYear) > currentYear) {
        //     return `유효한 연도를 입력해주세요. (1900-${currentYear})`;
        // }
    },
    birthMonth: (birthMonth) => {
        if (SPACE_REGEX.test(birthMonth)) {
            return '공백은 사용할 수 없습니다.';
        } else if (!birthMonth) {
            return '필수 정보입니다.';
        } else if (!NUMBER_REGEX.test(birthMonth)) {
            return '숫자만 입력해주세요.';
        } else if (parseInt(birthMonth) < 1 || parseInt(birthMonth) > 12) {
            return '올바른 정보를 입력해주세요.';
            // return '1~12 사이의 숫자만 입력해주세요.';
        } else {
            return '';
        }
    },
    birthDay: (birthDay) => {
        if (SPACE_REGEX.test(birthDay)) {
            return '공백은 사용할 수 없습니다.';
        } else if (!birthDay) {
            return '필수 정보입니다.';
        } else if (!NUMBER_REGEX.test(birthDay)) {
            return '숫자만 입력해주세요.';
        } else {
            return '';
        }
    },
    height: (height) => {
        if (SPACE_REGEX.test(height)) {
            return '공백은 사용할 수 없습니다.';
        } else if (!height) {
            return '필수 정보입니다.';
        } else if (!HEIGHT_REGEX.test(height)) {
            return '숫자만 입력해주세요.';
        } else {
            return '';
        }
    },
    weight: (weight) => {
        if (SPACE_REGEX.test(weight)) {
            return '공백은 사용할 수 없습니다.';
        } else if (!weight) {
            return '필수 정보입니다.';
        } else if (!NUMBER_REGEX.test(weight)) {
            return '숫자만 입력해주세요.';
        } else {
            return '';
        }
    },
};

// 로그인 유효성 검사
export const loginValidate: InputValidateObject = {
    email: (email) => {
        if (SPACE_REGEX.test(email)) {
            return '공백은 사용할 수 없습니다.';
        } else if (EMAIL_REGEX.test(email)) {
            return '';
        } else if (email && !EMAIL_REGEX.test(email)) {
            return LoginPageError.changeEmailForm;
        } else {
            return '이메일 아이디를 입력해주세요.';
        }
    },
    password: (password, value) => {
        if (!password) {
            return '비밀번호를 입력해주세요.';
        } else if (SPACE_REGEX.test(password) || (value && value.confirmPassword && password !== value.confirmPassword)) {
            return '잘못된 비밀번호입니다. 다시 입력해주세요.';
        } else {
            return '';
        }
    },
    // password: (password, value) => {
    //     if (value && value.confirmPassword && password !== value.confirmPassword) {
    //         return '비밀번호가 일치하지 않습니다.';
    //     }
    //     if (PASSWORD_REGEX.test(password)) {
    //         return '';
    //     } else if (password && !PASSWORD_REGEX.test(password)) {
    //         return '영문, 숫자 포함 8자 이상 입력해주세요.';
    //     } else {
    //         return '비밀번호를 입력해주세요.';
    //     }
    // },
};

/// 운동 검사 결과 유효성 검사
export const reportInputValidate: InputValidateObject = {
    hrBefore: (inputValue) => {
        if (!NUMBER_REGEX.test(inputValue)) {
            return '숫자만 입력해주세요.';
        }
        const valueToNumber = parseInt(inputValue);
        if (valueToNumber < 1 || valueToNumber > 250) {
            return '1~250 사이의 숫자만 입력해주세요.';
        }
        return '';
    },

    hrAfter: (inputValue) => {
        if (!NUMBER_REGEX.test(inputValue)) {
            return '숫자만 입력해주세요.';
        }
        const valueToNumber = parseInt(inputValue);
        if (valueToNumber < 1 || valueToNumber > 250) {
            return '1~250 사이의 숫자만 입력해주세요.';
        }
        return '';
    },

    spo2Before: (inputValue) => {
        if (!NUMBER_REGEX.test(inputValue)) {
            return '숫자만 입력해주세요.';
        }
        const valueToNumber = parseInt(inputValue);
        if (valueToNumber < 1 || valueToNumber > 100) {
            return '1~100 사이의 숫자만 입력해주세요.';
        }
        return '';
    },

    spo2After: (inputValue) => {
        if (!NUMBER_REGEX.test(inputValue)) {
            return '숫자만 입력해주세요.';
        }
        const valueToNumber = parseInt(inputValue);
        if (valueToNumber < 1 || valueToNumber > 100) {
            return '1~100 사이의 숫자만 입력해주세요.';
        }
        return '';
    },

    walkDistance: (inputValue, value) => {
        if (!value || !value.gender || (value.gender !== 'male' && value.gender !== 'female')) return '데이터 초기화 오류';
        const minData = {
            male: 180,
            female: 280,
        };
        const maxData = {
            male: 750,
            female: 850,
        };

        const genderToKR = {
            male: '남자',
            female: '여자',
        };

        const gender = value.gender as 'male' | 'female';
        const valueToNumber = parseInt(inputValue);

        if (valueToNumber < minData[gender]) {
            return '걷기 최소 기준에 미달하여 결과를 확인할 수 없습니다';
        }

        if (valueToNumber > maxData[gender]) {
            return `최대 입력 거리값은 ${genderToKR[gender]} ${maxData[gender]}m 까지 입니다. 그 이상의 값을 넣으면, 걷기 보다는 달리기로 검사를 추천드리며 걷기 검사 결과가 제공되지 않습니다`;
        }
        return '';
    },
};
// 생년월일 유효성 검사(YYMMDD 형식 기준)
function isValidDate(yy: string, mm: string, dd: string) {
    const year = 2000 + parseInt(yy, 10);
    const month = parseInt(mm, 10) - 1; // JavaScript의 month는 0부터 시작
    const day = parseInt(dd, 10);

    const birthDate = new Date(year, month, day);

    // getFullYear, getMonth, getDate 메소드를 사용하여 입력한 날짜와 실제 생성된 날짜가 같은지 확인
    return birthDate.getFullYear() === year && birthDate.getMonth() === month && birthDate.getDate() === day;
}

export function isValidBirthdate(birthdate: string) {
    const regex = /^(\d{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/;
    const match = birthdate.match(regex);

    if (match) {
        return isValidDate(match[1], match[2], match[3]);
    }

    return false;
}

export const validateNumber = (value: string, maxLength: number = 4): boolean => {
    if (value === '') return false;
    return new RegExp(`^\\d{0,${maxLength}}$`).test(value);
};
