type TableType = {
    [key: string]: string;
};
export const vo2maxInterpretationDescEnum: TableType = {
    '매우 훌륭함':
        '현재 심폐체력 수준을 유지하는 것을 권장합니다. 1시간 이상 달리기를 목표로 리포트에서 제시되는 회복력 향상 속도와 심장강화, 체력증진 속도로 꾸준히 달려보세요. 1회 400kcal를 소비하는 시간을 줄이는 걸 목표로 운동하세요.',
    훌륭함: '현재 심폐체력 수준을 유지하는 것을 권장합니다. 주3회 이상 운동처방 리포트에서 제시하는 회복력향상 달리기 속도로 1시간 이상 달려보세요. 주2회 이상 운동처방 리포트에서 제시하는 심장강화, 체력증진 속도로 400kcal를 소비할 수 있는 체력을 목표하세요.',
    강함: '주5회 이상 운동처방 리포트에서 제시하는 회복력향상 달리기 속도로 1시간 이상 달려보세요. 주2회 이상 운동처방 리포트에서 제시하는 심장강화, 체력증진 속도로 400kcal를 소비할 수 있는 체력을 목표하세요.',
    보통: '주 7회 이상 운동처방 리포트에서 제시하는 회복력향상 달리기 속도로 달려보세요. 1주차는 150kcal 소비를 목표로, 2주차는 200kcal, 3주차는 250kcal, 4주차는 300kcal, 5주차는 350kcal, 6주차는 400kcal를 목표하세요. 400kcal 소비까지 소비할 수 있는 체력을 갖췄다면, 심폐체력검사를 다시 받아보세요.',
    약함: '심폐체력을 우선순위 운동으로 기준하세요. 운동처방 리포트에서 제시하는 회복력향상 달리기 속도, 제시되는 150kcal 소비 시간을 목표로 매일 달려보세요. 달리기 형태로 1회 운동시 150kcal 이상을 소비할 수 있는 체력을 만드는 것이 1차 목표입니다. 4주간 운동 후 심폐체력검사를 다시 받아보세요.',
    '매우 약함':
        '심폐체력을 가장 우선순위 운동으로 기준하세요. 달리기 형태만 반드시 유지하고 속도는 걷기보다 느려도 괜찮습니다. 매일 10~15분 내외의 느린 달리기를 한달간 지속한 뒤에 심폐체력검사를 다시 받아보세요.',
};

export const muscleDescEnum: TableType = {
    '매우 훌륭함':
        '현재 근지구력 수준을 유지하는 것을 권장합니다. 근지구력 유지를 위해 매일 1분간 최대반복의 앉아일어서기를 수행하세요. 근력운동은 체중의 40 ~ 50% 수준의 무게를 들고 스쿼트(앉아일어서기)를 시작해보세요.',
    훌륭함: '현재 근지구력 수준을 유지하는 것을 권장합니다.근지구력 유지를 위해 매일 1분간 최대반복의 앉아일어서기를 수행하세요. 근력운동은 체중의 30~40%수준의 무게를 들고 스쿼트(앉아일어서기)를 시작해보세요.',
    강함: '근지구력 강화를 위해 매일 1분이상 앉아 일어서기를 최대반복 해보세요. 체중의 20~30%수준의 무게를 들고 스쿼트(앉아일어서기)를 시작해보세요.',
    보통: '제자리에서 앉아 일어서기를 쉬지 않고 1분 이상 수행하세요. 매일 하루 30회이상을 1분 내에 수행해보세요.',
    약함: '쇼파에서 앉아 일어서기를 매일 하루 총 50회 이상 수행하세요. 힘들땐 손잡이를 잡고 일어서도 괜찮습니다. 힘들면 충분히 쉬고 10회씩 나눠서 해도 괜찮습니다.',
    '매우 약함':
        '1일 8800보 이상의 신체활동을 유지하세요. 쇼파에 앉아 일어서기를 매일 하루 총30회 이상 수행하세요. 힘들면 충분히 쉬고 다시 시작해도 괜찮습니다. 손잡이를 잡고 함께 밀면서 일어나 보세요.',
};

export const tugDescEnum: TableType = {
    양호: '낙상위험도는 낮은 수준으로 양호합니다. 현재의 근력과 인지 기능, 시각과 청력 및 균형감각을 유지하는 것을 권장합니다.',
    주의: '동일 연령 기준 낙상위험도가 주의기준입니다.TUG검사 기록의 시간을 단축하기 위한 하지근력운동과 인지기능 향상을 위한 운동을 시작하세요.',
    위험: '낙상위험도가 높은 수준으로 전문가와 상담이 필요합니다. 보행이상 및 시각과 청각, 근력 상태, 균형감각 등의 낙상 원인을 파악하여 빠르게 개선 할 수 있도록 노력하세요.',
};

export const SPPBDescEnum: TableType = {
    양호: '예방적 건강관리를 위해 운동과 영양섭취를 꾸준히 관리하세요. 10점의 경우는 노쇠 스펙트럼이 나타날 수 있어 적극적인 개선관리가 필요합니다.',
    주의: '현재 신체기능 개선을 우선순위로 기준하세요. 인지기능 감소, 기억력 저하, 사회적 관계 및 활동 감소 등을 보일 수 있어 세심한 관리와 개선이 필요합니다.',
    위험: '전문 기관의 도움이 필요할 수 있습니다. 방문 건강관리 및 간호 등의 서비스가 필요하며, 기관 입소를 늦추기 위한 적극적인 관리와 개선이 매우 필요합니다.',
};

export const mobilityAssessmentDescEnum: TableType = {
    정상: '경추, 어깨, 고관절 가동성, 안정화에 추천되는 MTE교정운동을 수행하세요. 주의단계에 있는 부위가 있다면 꾸준한 교정운동을 통해 개선해보세요.',
    개선필요: '경추, 어깨, 고관절 가동성, 안정화에 추천되는 MTE교정운동을 수행하세요. 교정필요 단계에 있는 부위가 있다면 꾸준한 교정운동과 전문가의 도움을 받아보세요.',
    교정필요: '경추, 어깨, 고관절 가동성, 안정화에 추천되는 MTE교정운동을 수행하세요. 전문가의 도움이 필요하며, 통증부위가 있다면 가장 먼저 개선해야 할 우선순위입니다.',
};

export const functionalMotionDescEnum: TableType = {
    정상: '현재의 기능성 움직임 수준을 유지하는 것을 목표로 합니다. 상하지의 협응 및 좌우 밸런스 기능 유지를 위해 추천되는 MTE리포트의 운동을 수행해보세요. 꾸준한 관리와 평가로 기능성 움직임을 향상시켜보세요.',
    개선필요:
        '흉추와 어깨 리듬, 고관절의 접힘, 발목의 가동성 등 다양한 이유로 현재의 기능성 움직임이 제한되어 있습니다. 추천되는 MTE리포트 운동을 수행하고, 정기적인 검사를 통해 기능성 움직임 개선이 필요합니다.',
    교정필요:
        '현재의 기능성 움직임 수준은 부상의 위험도가 높습니다. 좌우의 밸런스 및 상하지 협응, 운동 기능성에 문제를 발생시키는 경추, 흉추, 견관절 복합체의 리듬, 고관절의 접힘, 발목의 가동성 등 원인이 되는 부분을 확인하고 빠르게 개선이 필요합니다. 추천되는 MTE리포트 운동을 수행하고, 정기적인 검사를 통해 위험상태를 개선해보세요.',
};
