import { MobilityAssessmentInputProps, scoreNumberValues } from 'models/MTE/ReportInput/assessmentInputTypes';
import React from 'react';
import {
    AssessmentName,
    AssessmentObjectiveTestScore,
    AssessmentObjectiveTestScoreText,
    AssessmentObjectiveTestScoreWrapper,
    AssessmentObjectiveTestTitle,
    AssessmentObjectiveTestWrapper,
    AssessmentRadiusTitle,
    AssessmentTableContainer,
    AssessmentTableContentLayout,
    AssessmentTableHeader,
    AssessmentTableLayout,
    AssesssmentTargetText,
    ContainerHeaderWrapper,
    ContainerLayout,
    HeightSpacer,
    InnerDivider,
    Spacer,
    SubTitle,
    Title,
    WidthSpacer,
} from 'styles/ReportStyles/MTEReportInputStyles';

export const MobilityAssessment = React.memo(({ assessmentInputList }: MobilityAssessmentInputProps) => {
    return (
        <ContainerLayout>
            <ContainerHeaderWrapper>
                <Title>3. 움직임 능력 검사</Title>
                <WidthSpacer $width={18} />
                <Spacer />
                <AssessmentRadiusTitle>
                    <AssesssmentTargetText>4종 검사(노약자 대상)</AssesssmentTargetText>
                </AssessmentRadiusTitle>
            </ContainerHeaderWrapper>
            <InnerDivider />
            <HeightSpacer $height={31} />
            <AssessmentTableLayout>
                {assessmentInputList.map((assessment, index) => {
                    return (
                        <AssessmentTableContainer key={assessment.subjectName}>
                            <AssessmentTableHeader>
                                <AssessmentName>
                                    {index + 1}. {assessment.subjectName}
                                </AssessmentName>
                                <SubTitle>{assessment.subjectDescription}</SubTitle>
                            </AssessmentTableHeader>
                            <HeightSpacer $height={22} />
                            <AssessmentTableContentLayout>
                                {assessment.assessmentInputList.map((assessmentInput, innerIndex) => {
                                    return (
                                        <AssessmentObjectiveTestWrapper key={assessmentInput.name}>
                                            <AssessmentObjectiveTestTitle>
                                                {index + 1}-{innerIndex + 1}. {assessmentInput.name}
                                            </AssessmentObjectiveTestTitle>
                                            <AssessmentObjectiveTestScoreWrapper>
                                                {scoreNumberValues.map((item, index) => {
                                                    return (
                                                        <AssessmentObjectiveTestScore
                                                            key={index}
                                                            onClick={() => {
                                                                if (typeof assessment.onChange[innerIndex](item) === 'function') {
                                                                    assessment.onChange[innerIndex](item);
                                                                }
                                                            }}
                                                            $isSelected={assessmentInput.score === item}
                                                        >
                                                            <AssessmentObjectiveTestScoreText>{item}</AssessmentObjectiveTestScoreText>
                                                        </AssessmentObjectiveTestScore>
                                                    );
                                                })}
                                            </AssessmentObjectiveTestScoreWrapper>
                                        </AssessmentObjectiveTestWrapper>
                                    );
                                })}
                            </AssessmentTableContentLayout>
                        </AssessmentTableContainer>
                    );
                })}
            </AssessmentTableLayout>
        </ContainerLayout>
    );
});
