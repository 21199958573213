import axios from 'axios';
import { apiPath } from 'constants/path';
import { createReportRequestType, getReportResponseType } from 'models/MTE/reportApiTypes';

export const header = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_ADMIN_KEY,
};

class BaseApi {
    constructor() {
        axios.defaults.headers.common['Authorization'] = process.env.REACT_APP_ADMIN_KEY;
        axios.interceptors.response.use((res) => res.data);
    }

    async post<T>(url: string, data: any) {
        const res = await axios.post<T>(url, data);
        return res.data;
    }
    async get<T>(url: string) {
        const res = await axios.get<T>(url);
        return res.data;
    }
    async delete<T>(url: string) {
        const res = await axios.delete<T>(url);
        return res.data;
    }
}
export const api = new BaseApi();

class ReportApi {
    createReport(report: createReportRequestType): Promise<getReportResponseType> {
        try {
            const response = api.post<getReportResponseType>(apiPath.createReport, report);

            return response;
        } catch (error) {
            throw error;
        }
    }

    getReport(userId: string): Promise<getReportResponseType> {
        try {
            const response = api.get<getReportResponseType>(`${apiPath.getReport}/${userId}`);
            console.log(response, 'respons');
            return response;
        } catch (e) {
            throw e;
        }
    }

    getAllReport(): Promise<getReportResponseType[]> {
        try {
            const response = api.get<getReportResponseType[]>(`${apiPath.getReport}`);
            return response;
        } catch (e) {
            throw e;
        }
    }

    deleteReport(reportId: string): Promise<getReportResponseType> {
        try {
            const response = api.delete<getReportResponseType>(`${apiPath.deleteReport}/${reportId}`);
            return response;
        } catch (e) {
            throw e;
        }
    }
}
export const reportApi = new ReportApi();
