import styled from 'styled-components';
import { BackgroundColor, InverseColor, PrimaryColor } from './colorSemantic';
import { font } from './globalStyles';
import { Radius } from './objectStyles';

export const BasicNumberBadge = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: ${Radius.radius_circle};
    background-color: ${BackgroundColor.color_background_white};
    color: ${PrimaryColor.color_primary_strong};
    font-size: ${font.size[14]};
    font-weight: ${font.weight.bold};
`;

export const MTEHeaderNumberBadge = styled.div<{ $hasCurrent: boolean }>`
    display: flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    border-radius: ${Radius.radius_circle};
    background-color: ${({ $hasCurrent }) => ($hasCurrent ? PrimaryColor.color_primary_strong : BackgroundColor.color_background_dim)};
    color: ${InverseColor.color_inverse_white};
    font-weight: ${font.weight.bold};
`;
