import React from 'react';
import styled from 'styled-components';
import { BorderColor } from 'styles/Global/colorSemantic';
import { palette } from 'styles/Global/globalStyles';
import { Caption2_Medium, Caption2_SemiBold } from 'styles/Global/typography';
import { ReportCircle } from 'styles/ReportStyles/reportStyles';
import { DescAndListTitle } from 'styles/ReportStyles/sarcopeniaAssessmentStyles';

const listTitle = ['수준', '적당 횟수', '월', '화', '수', '목', '금', '토', '일', '주당 총 kcal', '1회당 운동시간'];

const listBody = [
    ['초급', '7', 'Recovery', 'Recovery', 'Recovery', 'Recovery', 'Recovery', 'Recovery', 'Recovery', '1,000~2,000 (200~600)', '20~30분'],
    ['중급', '7', 'Recovery', 'For cardio', 'Recovery', 'Recovery', 'Recovery', 'For cardio', 'Recovery', '1,500~2,000', '30~90분'],
    ['고급', '5', 'For cardio', 'Recovery', 'Recovery', <ReportCircle style={{ backgroundColor: 'black' }} />, 'For cardio', 'Recovery', 'Recovery', '> 2,000', '20~60분'],
    // ['고급', '5', 'For cardio', 'Recovery', 'Recovery', <ReportCircle style={{ backgroundColor: 'black' }} />, 'For cardio', 'Recovery', 'Recovery', '> 2,000', '20 ~ 60'],
];

const WeeklyExerciseProgram = () => {
    return (
        <Layout>
            <DescAndListTitle>MTE 주간 유산소 운동 프로그램</DescAndListTitle>
            <TableContainer>
                <TableHeader>
                    {listTitle.map((title, idx) => {
                        return <SubHeader key={idx}>{title}</SubHeader>;
                    })}
                </TableHeader>
                {listBody.map((body, rowIdx) => (
                    <TableRow key={rowIdx}>
                        {body.map((text, colIdx) => (
                            <TableCell key={colIdx} $idx={colIdx}>
                                {text}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableContainer>
        </Layout>
    );
};

export default WeeklyExerciseProgram;

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-bottom: 16px;
`;

const TableContainer = styled.div`
    width: 100%;
    border: 1px solid ${palette.PrimaryBlueStrong};
    border-radius: 6px;
    overflow: hidden;
`;

const TableHeader = styled(Caption2_Medium)`
    display: grid;
    grid-template-columns: 1.1fr 1.1fr repeat(7, 1.7fr) 2.5fr 2.6fr;
    background-color: ${palette.PrimaryBlueStrong};
    color: white;

    & > * {
        display: flex;
        align-items: center;
        border-right: 1px solid #888;
    }

    & > *:last-child {
        border-right: none;
    }

    & > DiagonalHeader {
        display: block;
    }
`;

const TableRow = styled.div`
    display: grid;
    grid-template-columns: 1.1fr 1.1fr repeat(7, 1.7fr) 2.5fr 2.6fr;
    border-bottom: 1px solid ${palette.basic.gray[50]};
    text-align: center;

    &:last-child {
        border-bottom: none;
    }
`;

const TableCell = styled(Caption2_Medium)<{ $idx: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${BorderColor.color_border_neutral};
    height: 48px;

    &:last-child {
        border-right: none;
    }
`;

const SubHeader = styled(Caption2_SemiBold)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    color: white;
`;
