import { Bar, BarChart, Cell, LabelList, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { palette } from 'styles/Global/globalStyles';

//TUG 표준 데이터 예시
const TUGStandardData = [
    {
        name: 'TUG',
        inputData: 6.1,
        fine: 6.8,
        caution: 3.2,
        danger: 5,
    },
];

const chartStackKey = 'information';

interface TUGBarChartProps {
    inputData: number;
    divideTime: number;
    userType?: string | boolean;
}

export const TUGBarChart = ({ inputData, divideTime, userType }: TUGBarChartProps) => {
    const TUGStandardData = [
        {
            name: 'TUG',
            inputData: Math.min(inputData, divideTime + (10 - divideTime) + 5),
            fine: divideTime,
            caution: 10 - divideTime,
            danger: 5,
        },
    ];

    const normalTicks = [divideTime, 10];
    const oldFemaleTicks = [10.3];

    let ticks = userType !== 'oldFemale' ? normalTicks : oldFemaleTicks;

    return (
        <ResponsiveContainer width='100%' height='80%'>
            <BarChart layout='vertical' barSize={20} barGap={10} margin={{ top: 0, right: 0, left: 0, bottom: 0 }} data={TUGStandardData}>
                {ticks.map((tick, idx) => (
                    <ReferenceLine key={idx} x={tick} stroke='#ccc'></ReferenceLine>
                ))}
                <XAxis type='number' height={15} tickFormatter={(value) => `${value}초`} domain={[0, 15]} ticks={ticks} style={{ fontSize: '10px', fontWeight: 'bold' }} tickLine={false} />
                <YAxis type='category' dataKey='name' width={1} tickLine={false} tickMargin={1} tick={false} />
                <Bar dataKey='inputData' fill='gray'>
                    <LabelList dataKey='inputData' position='inside' fill='#fff' formatter={() => `${inputData}초`} />
                </Bar>
                <Bar dataKey='fine' fill={palette.Graph.good} stackId={chartStackKey}>
                    <LabelList dataKey='fine' position='inside' fill='#fff' formatter={(value: any) => '양호'} />
                </Bar>
                {userType !== 'oldFemale' && (
                    <Bar dataKey='caution' fill={palette.Graph.caution} stackId={chartStackKey}>
                        <LabelList dataKey='caution' position='inside' fill='#fff' formatter={(value: any) => '주의'} />
                    </Bar>
                )}

                <Bar dataKey='danger' fill={palette.Graph.danger} stackId={chartStackKey}>
                    <LabelList dataKey='caution' position='inside' fill='#fff' formatter={(value: any) => '위험'} />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

const LineData = [10, 20, 30, 40, 50, 60];

const maxContant = 60;

const colors = [palette.PrimaryBlue, palette.basic.gray[80]];

export const LegStrengthBarChart = ({ inputData, averageData }: { inputData: number; averageData: number }) => {
    const LegStrengthData = [
        {
            name: '내 능력',
            inputData: Math.min(inputData, maxContant),
        },
        {
            name: '평균',
            inputData: averageData,
            // inputData: 27,
        },
    ];

    ///텍스트 왼쪽 정렬을 위한 커스텀 틱
    const customTick = (props: any) => {
        const { x, y, payload, width } = props;
        return (
            <text x={x - width * 0.8} y={y} dy={3} textAnchor='start' fill='#666' fontSize={12} fontFamily='Pretendard' fontWeight={700}>
                {payload.value}
            </text>
        );
    };

    return (
        <ResponsiveContainer width='100%' height='100%'>
            <BarChart layout='vertical' barSize={20} margin={{ top: 0, right: 0, left: 0, bottom: 0 }} data={LegStrengthData}>
                {LineData.map((data, _) => {
                    return <ReferenceLine x={data} stroke='#ccc' />;
                })}
                <XAxis type='number' height={15} ticks={LineData} style={{ fontSize: '10px' }} tickLine={false} />
                <YAxis type='category' dataKey='name' tickLine={false} width={45} tickMargin={1} minTickGap={0} orientation='left' tick={customTick} />
                <Bar dataKey='inputData'>
                    {LegStrengthData.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} fontWeight={700} fontSize={12} />
                    ))}
                    <LabelList dataKey='inputData' position='inside' fill='#FFFFFF' />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

interface VO2MaxBarChartProps {
    inputData: number;
    averageStandard?: number;
    colorProps?: string[];
}

export const VO2MaxBarChart = ({ averageStandard = 20, colorProps = colors, ...props }: VO2MaxBarChartProps) => {
    const avgValue = 100;
    const LineData = Array.from({ length: averageStandard }, (_, i) => i * 5);

    /// 선 컬러 색
    const axisColor = `${palette.basic.gray[50]}`;

    const VO2MaxData = [
        {
            name: '내 능력',
            inputData: props.inputData,
        },
        {
            name: '평균',
            inputData: 50,
        },
    ];

    const avgMapData = [
        {
            data: avgValue * 0.15,
            label: '평균이하',
        },
        {
            data: 50,
            label: '평균',
        },
        {
            data: avgValue * 0.85,
            label: '평균이상',
        },
    ];

    const categoryTick = (props: any) => {
        const { x, y, payload } = props;
        return (
            <text x={x} y={y} dy={3} textAnchor='end' fill='#666' fontSize={12} fontFamily='Pretendard' fontWeight={700}>
                {payload.value}
            </text>
        );
    };

    const avgTick = (props: any) => {
        const { x, y, payload } = props;
        const result = avgMapData.find((data) => data.data === payload.value);
        return (
            <text x={x} y={y} dy={3} textAnchor='middle' fill='#666' fontSize={12} fontFamily='Pretendard' fontWeight={700}>
                {result?.label}
            </text>
        );
    };

    return (
        <ResponsiveContainer width='100%' height='100%'>
            <BarChart layout='vertical' margin={{ top: 0, right: 0, left: 0, bottom: 0 }} data={VO2MaxData} barSize={15}>
                <text x={52} y={18} dy={0} textAnchor='end' fill={`${palette.PrimaryBlue}`} fontSize={12} fontFamily='Pretendard' fontWeight={700}>
                    VO2 Max
                </text>
                {LineData.map((data, _) => {
                    return <ReferenceLine x={data} stroke={axisColor} />;
                })}
                {avgMapData.map((data, _) => {
                    return <ReferenceLine x={data.data} stroke='transparent' key={data.data} />;
                })}
                <XAxis
                    xAxisId={0}
                    type='number'
                    height={30}
                    domain={[0, 100]}
                    tickLine={false}
                    tickMargin={8}
                    ticks={avgMapData.map((data) => data.data)}
                    tick={avgTick}
                    style={{ fontSize: '10px' }}
                    axisLine={{ stroke: axisColor }}
                    orientation='top'
                    allowDataOverflow
                />
                <XAxis
                    xAxisId={1}
                    type='number'
                    height={1}
                    domain={[0, 100]}
                    tick={false}
                    style={{ fontSize: '10px' }}
                    axisLine={{ stroke: axisColor }}
                    tickLine={false}
                    orientation='bottom'
                    allowDataOverflow
                />
                <YAxis
                    type='category'
                    dataKey='name'
                    tickLine={false}
                    width={50}
                    tickMargin={5}
                    minTickGap={0}
                    orientation='left'
                    tick={categoryTick}
                    padding={{ top: 5, bottom: 5 }}
                    axisLine={{ stroke: axisColor }}
                />
                <Bar dataKey='inputData'>
                    {VO2MaxData.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};
