import { sitUp } from 'assets/Imgs/Reports';
import { DoughnutChart } from 'components/Common/DoughnutChart';
import { LegStrengthBarChart } from 'components/Common/RowBarChart';
import { mobilityAssessmentType } from 'models/MTE/mobilityAssessmentTypes';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BorderColor } from 'styles/Global/colorSemantic';
import { palette } from 'styles/Global/globalStyles';
import { Body1_Bold, Body2_Bold, Body4_Regular, Caption2_Medium } from 'styles/Global/typography';

const MobilityAssessmentSitUp = ({ mobilityData }: { mobilityData: mobilityAssessmentType }) => {
    const [grade, setGrade] = useState<string>('');

    useEffect(() => {
        switch (mobilityData.muscle.sitStandGrade) {
            case '훌륭함':
                setGrade('1등급');
                break;
            case '매우 강함':
                setGrade('2등급');
                break;
            case '강함':
                setGrade('3등급');
                break;
            case '보통':
                setGrade('4등급');
                break;
            case '약함':
                setGrade('5등급');
                break;
            case '매우 약함':
                setGrade('6등급');
                break;
        }
    }, [mobilityData]);
    return (
        <Layout>
            <Title>60초 앉아 일어서기(일반)</Title>
            <Desc>*남 19회, 여 17회 이상은 60초 검사로 진행</Desc>
            <ResultsLayout>
                <ContainerWithImg>
                    <WrapperWithImg>
                        <ImgTag src={sitUp} />
                    </WrapperWithImg>
                    <SitUpGradeWrapper>
                        <GradeNameWrapper>
                            <GradeName>{mobilityData.muscle.sitStandGrade}</GradeName>
                        </GradeNameWrapper>
                        {/* <Grade>{grade}</Grade> */}
                        <Grade>{grade}</Grade>
                    </SitUpGradeWrapper>
                </ContainerWithImg>
                <ColumnContainer>
                    <SitUpAvarageWrapper>
                        <AvarageTitle>평균비교</AvarageTitle>
                        <LegStrengthBarChart inputData={mobilityData.muscle.sitStandScore} averageData={mobilityData.muscle.averageScore} />
                    </SitUpAvarageWrapper>
                </ColumnContainer>
                <PercentileWrapper>
                    <PercentileTitle>백분위</PercentileTitle>
                    <DoughnutChart inputData={mobilityData.muscle.sitStandRanking} />
                </PercentileWrapper>
            </ResultsLayout>
        </Layout>
    );
};

export default MobilityAssessmentSitUp;

const GradeNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Title = styled(Body2_Bold)`
    width: 100%;
`;

const ResultsLayout = styled.div`
    display: grid;
    grid-template-columns: 1.1fr 1.5fr 0.6fr;
    gap: 32px;
    height: 100%;
`;

const ContainerWithImg = styled.div`
    display: flex;
    width: 100%;
    padding: 1px 12px;
    background-color: ${palette.PrimaryBlueWeak};
    border-radius: 6px;
    border: 1px solid ${BorderColor.color_border_neutral};
`;

const WrapperWithImg = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 55%;
`;

const ImgTag = styled.img`
    height: 100px;
`;

const Desc = styled(Caption2_Medium)`
    padding-bottom: 12px;
`;

const SitUpGradeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 45%;
    justify-content: center;
`;

const GradeName = styled(Body1_Bold)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110%;
    color: ${palette.PrimaryBlueStrong};
`;

const Grade = styled(Body4_Regular)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: ${palette.PrimaryBlueStrong};
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 10px;
`;
const SitUpAvarageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const AvarageTitle = styled(Body2_Bold)`
    display: flex;
    color: ${palette.PrimaryBlueStrong};
    width: 100%;
    margin-bottom: 12px;
`;

const PercentileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 140px;
    gap: 10px;
`;

const PercentileTitle = styled(Body1_Bold)`
    display: flex;
    color: ${palette.PrimaryBlueStrong};
    justify-content: center;
    width: 100%;
`;
