import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { GlobalStyles } from './styles/Global/globalStyles';
import Loading from 'components/Common/Loading';
import { Suspense } from 'react';
import { useLoadingStore } from 'store/useLoadingStore';

function App() {
    const { loading } = useLoadingStore();

    return (
        <>
            {/* <Suspense fallback={<Loading loading={true} />}> */}
            <GlobalStyles />
            <AppLayout>
                <Loading loading={loading} />
                <Outlet />
            </AppLayout>
            {/* </Suspense> */}
        </>
    );
}

export default App;

const AppLayout = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
`;
