import { ColumnBarChart } from 'components/Common/ColumnBarChart';
import { WalkingAssessmentDoughnutChart } from 'components/Common/DoughnutChart';
import { WalkingSpeedAverageType } from 'models/MTE/walkingAssessmentTypes';
import styled from 'styled-components';
import { Body4_SemiBold } from 'styles/Global/typography';
import { ContainerLayout, SubTitle, Title, TitleWrapper } from 'styles/ReportStyles/walkingAssessmentStyles';

export const WalkingSpeedAverage = (props: WalkingSpeedAverageType) => {
    return (
        <ContainerLayout>
            <TitleWrapper>
                <Title>평균치 비교</Title>
                <SubTitle>(동일연령 및 성별) Speed / Distance / %</SubTitle>
            </TitleWrapper>
            <GraphContainer>
                <ColumnChartLayout>
                    <ColumnChartWrapper>
                        <ChartTitle>속도 (km/h)</ChartTitle>
                        <ColumnBarChart
                            overallData={props.speedChartProps.overallData}
                            averageData={props.speedChartProps.averageData}
                            percentile={props.speedChartProps.percentile}
                            inputData={props.speedChartProps.inputData}
                        />
                    </ColumnChartWrapper>
                    <ColumnChartWrapper>
                        <ChartTitle>거리 (m)</ChartTitle>
                        <ColumnBarChart
                            overallData={props.distanceChartProps.overallData}
                            averageData={props.distanceChartProps.averageData}
                            percentile={props.distanceChartProps.percentile}
                            inputData={props.distanceChartProps.inputData}
                        />
                    </ColumnChartWrapper>
                </ColumnChartLayout>
                <DoughnutChartLayout>
                    <ChartTitle>
                        백분위
                        <br />
                        Percentile
                    </ChartTitle>
                    <WalkingAssessmentDoughnutChart inputData={props.percentile} />
                </DoughnutChartLayout>
            </GraphContainer>
        </ContainerLayout>
    );
};

const GraphContainer = styled.div`
    display: grid;
    height: 165px;
    gap: 30px;
    grid-template-columns: 1.3fr 1fr;
`;

const ChartTitle = styled(Body4_SemiBold)``;

const ColumnChartWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const DoughnutChartLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 4fr;
`;

const ColumnChartLayout = styled.div`
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr 1fr;
`;
