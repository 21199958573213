import { mobilityAssessmentType } from 'models/MTE/mobilityAssessmentTypes';
import styled from 'styled-components';
import { BorderColor } from 'styles/Global/colorSemantic';
import { palette } from 'styles/Global/globalStyles';
import { Caption1_Medium } from 'styles/Global/typography';
import { DescAndListTitle } from 'styles/ReportStyles/sarcopeniaAssessmentStyles';

const MobilityAssessmentLists = ({ mobilityData }: { mobilityData: mobilityAssessmentType }) => {
    return (
        <AssessmentListContainer>
            <AssessmentListFlexContainer style={{ flex: 3 }}>
                <DescAndListTitle>가동성 검사</DescAndListTitle>
                <AssessmentListWrapper>
                    <AssessmentListMobilityTestTitleGridWrapper>
                        <AssessmentListTitle>어깨</AssessmentListTitle>
                        <AssessmentListTitle>경추</AssessmentListTitle>
                        <AssessmentListTitle>고관절</AssessmentListTitle>
                    </AssessmentListMobilityTestTitleGridWrapper>
                    <AssessmentListMobilityTestBodyGridWrapper>
                        <AssessmentListBody>{mobilityData.shoulder.shoulderGrade}</AssessmentListBody>
                        <AssessmentListBody>{mobilityData.neck.neckGrade}</AssessmentListBody>
                        <AssessmentListBody>{mobilityData.hip.hipGrade}</AssessmentListBody>
                    </AssessmentListMobilityTestBodyGridWrapper>
                </AssessmentListWrapper>
            </AssessmentListFlexContainer>
            <AssessmentListFlexContainer style={{ flex: 2 }}>
                <DescAndListTitle>기능성 검사</DescAndListTitle>
                <AssessmentListWrapper>
                    <AssessmentListTitle className='no-border'>손들고 앉아 일어서기</AssessmentListTitle>
                    <AssessmentListBody>
                        {mobilityData.functionalMotion.functionalMotionScore} {mobilityData.functionalMotion.functionalMotionGrade}
                    </AssessmentListBody>
                </AssessmentListWrapper>
            </AssessmentListFlexContainer>
            <AssessmentListFlexContainer style={{ flex: 5 }}>
                <DescAndListTitle>근지구력 검사 (60초 앉아 일어서기)</DescAndListTitle>
                <AssessmentListWrapper>
                    <AssessmentListMuscularEnduranceTestTitleGridWrapper>
                        <AssessmentListTitle>횟수</AssessmentListTitle>
                        <AssessmentListTitle>상위%</AssessmentListTitle>
                        <AssessmentListTitle>하지근력 나이</AssessmentListTitle>
                        <AssessmentListTitle>평가</AssessmentListTitle>
                    </AssessmentListMuscularEnduranceTestTitleGridWrapper>
                    <AssessmentListMuscularEnduranceTestBodyGridWrapper>
                        <AssessmentListBody>{mobilityData.muscle.sitStandScore} 회</AssessmentListBody>
                        <AssessmentListBody>{100 - mobilityData.muscle.sitStandRanking} %</AssessmentListBody>
                        <AssessmentListBody>{mobilityData.muscle.muscleAge} 세</AssessmentListBody>
                        <AssessmentListBody>{mobilityData.muscle.sitStandGrade}</AssessmentListBody>
                    </AssessmentListMuscularEnduranceTestBodyGridWrapper>
                </AssessmentListWrapper>
            </AssessmentListFlexContainer>
        </AssessmentListContainer>
    );
};

export default MobilityAssessmentLists;

const AssessmentListContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    padding-top: 10px;
`;

const AssessmentListFlexContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const AssessmentListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${palette.PrimaryBlueStrong};
    border-radius: 6px;
    overflow: hidden;
    height: 88px;
    margin-top: 6px;
`;

const AssessmentListMobilityTestTitleGridWrapper = styled.div`
    display: grid;
    grid-template-columns: 2.5fr 3fr 3fr;
    width: 100%;
    flex-grow: 1.4;
`;
const AssessmentListMuscularEnduranceTestTitleGridWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1.2fr 2fr 1.5fr;
    width: 100%;
    flex-grow: 1;
`;
const AssessmentListMobilityTestBodyGridWrapper = styled.div`
    display: grid;
    grid-template-columns: 2.5fr 3fr 3fr;
    width: 100%;
    flex-grow: 2;
`;
const AssessmentListMuscularEnduranceTestBodyGridWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1.2fr 2fr 1.5fr;
    width: 100%;
    flex-grow: 2;
`;

const AssessmentListTitle = styled(Caption1_Medium)`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-grow: 1.4;
    background-color: ${palette.PrimaryBlueStrong};
    color: white;
    border-right: 1px solid #888;

    &:last-of-type {
        border: none;
    }

    &.no-border {
        border: none;
    }
`;

const AssessmentListBody = styled(Caption1_Medium)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 2;
    border-right: 1px solid ${BorderColor.color_border_normal};
    grid-template-columns: 1fr 1.2fr 2fr 1.5fr;

    &:not(:last-child)::before {
        content: '';
        position: absolute;
        height: 100%;
        bottom: 8px;
        right: 0;
        width: 1px;
        background-color: ${BorderColor.color_border_normal};
    }
`;
