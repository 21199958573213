import { RadarWithLabel } from 'graph/Radar';
import styled from 'styled-components';

interface TotalRadarChartProps {
    vo2maxPer: number;
    musclePer: number;
    tugPer: number;
    functionalMotionPer: number;
    mobilityAssessmentPer: number;
    sarcopeniaPer: number;
}

export const TotalRadarChart = ({ vo2maxPer, musclePer, tugPer, functionalMotionPer, mobilityAssessmentPer, sarcopeniaPer }: TotalRadarChartProps) => {
    // 방사형 데이터에 값이 1도 안 된다면 1까지는 노출되도록 하기 위한 부등호
    const totalData = [
        { key: 'A', value: vo2maxPer < 1 ? 1 : vo2maxPer, label: '심폐체력' },
        { key: 'B', value: musclePer < 1 ? 1 : musclePer, label: '근지구력' },
        { key: 'C', value: tugPer < 1 ? 1 : tugPer, label: '낙상위험' },
        { key: 'D', value: functionalMotionPer < 1 ? 1 : functionalMotionPer, label: '기능성 움직임' },
        { key: 'E', value: mobilityAssessmentPer < 1 ? 1 : mobilityAssessmentPer, label: '관절\n가동성' },
        { key: 'F', value: sarcopeniaPer < 1 ? 1 : sarcopeniaPer, label: '근감소증' },
    ];
    return (
        <RadarContainer>
            <RadarWithLabel size={200} data={totalData} />
        </RadarContainer>
    );
};

const RadarContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;
