import { TugResultConstants } from 'constants/sarcopeniaAssessmentConstants';
import { AssessmentResult, averageWalkingSpeedTable, walkingSpeedGradeTable } from 'models/MTE/sarcopeniaAssessmentTypes';
import {
    calculateAllSPPBScoreFunc,
    CalculateSPPBListValueFunc,
    GetAvarageWalkingSpeedAgeFunc,
    GetGradeFunc,
    handleSetTugDataFunc,
    LinearSpeedFunc,
    WalkingAndSitUpSpeedFunc,
} from 'models/MTE/SPPBScoreCalculateTypes';

// 정적균형 계산
export const isLinearPostureTime: LinearSpeedFunc = (generalPosturepossibility, nonLinearPosturePossibility, time, setState) => {
    const setListFunc = (score: number) => {
        return setState((prev) => ({ ...prev, isLinearPostureScore: score }));
    };

    if (!generalPosturepossibility) {
        return setListFunc(0);
    } else if (!nonLinearPosturePossibility) {
        return setListFunc(1);
    } else if (time >= 10) {
        return setListFunc(4);
    } else if (time < 10 && time >= 3) {
        return setListFunc(3);
    } else if (time < 3) {
        return setListFunc(2);
    }
};

// 보행속도 계산
export const walkingSpeed: WalkingAndSitUpSpeedFunc = (possibility, time, setState) => {
    const setListFunc = (score: number) => {
        return setState((prev) => ({ ...prev, walkTimeScore: score }));
    };

    if (!possibility) {
        return setListFunc(0);
    } else if (time < 4.82) {
        return setListFunc(4);
    } else if (time < 6.21) {
        return setListFunc(3);
    } else if (time < 8.71) {
        return setListFunc(2);
    } else {
        return setListFunc(1);
    }
};

// 일어서기 계산
export const SitUpAndStandUpSpeed: WalkingAndSitUpSpeedFunc = (possibility, time, setState) => {
    const setListFunc = (score: number) => {
        setState((prev) => ({ ...prev, standTimeScore: score }));
    };

    if (time > 60 || !possibility) {
        return setListFunc(0);
    } else if (time < 11.19) {
        return setListFunc(4);
    } else if (time < 13.7) {
        return setListFunc(3);
    } else if (time < 16.7) {
        return setListFunc(2);
    } else if (time >= 16.7 && time <= 60) {
        return setListFunc(1);
    }
};

// 보행 나이 계산
export const getAvarageWalkingSpeedAge: GetAvarageWalkingSpeedAgeFunc = (reportData) => {
    const avarageWalkingSpeed = averageWalkingSpeedTable[reportData.gender];
    const value = Number(calculateWalkingMeterPerSecond(reportData.data[0].sppb.walkTime));

    // 예외처리
    if (value > avarageWalkingSpeed['7'].max && value < avarageWalkingSpeed['6'].min) {
        const calculateSmallValue = Math.abs(avarageWalkingSpeed['7'].max - value);
        const calculateBigValue = Math.abs(avarageWalkingSpeed['6'].min - value);

        if (calculateSmallValue > calculateBigValue) {
            return '60~69세';
        } else {
            return '70~79세';
        }
    } else if (value > avarageWalkingSpeed['8'].max && value < avarageWalkingSpeed['7'].min) {
        const calculateSmallValue = Math.abs(avarageWalkingSpeed['7'].max - value);
        const calculateBigValue = Math.abs(avarageWalkingSpeed['6'].min - value);

        if (calculateSmallValue > calculateBigValue) {
            return '70~79세';
        } else {
            return '80~89세';
        }
    } else if (value > avarageWalkingSpeed['2'].max) {
        return '20~29세';
    } else if (value < avarageWalkingSpeed['8'].min) {
        return '80~89세';
    } else {
        // 범위 안 계산
        for (const age in avarageWalkingSpeed) {
            const { min, max } = avarageWalkingSpeed[age];

            if (value >= min && value <= max) {
                return `${age}0~${age}9세`;
            }
        }
    }

    return '범위 밖';
};

// 4m 보행속도 계산
export const calculateWalkingMeterPerSecond = (walkTime: number) => {
    return Math.floor((4 / walkTime) * 100) / 100;
};

// SPPB List value 구하는 함수
export const calculateSPPBListValue: CalculateSPPBListValueFunc = (SPPBAssessmentList, reportData, SPPBScores) => {
    return SPPBAssessmentList.map((item) => {
        if (item.key === 'isLinearPosture') {
            if (!reportData.data[0].sppb.isGeneralPosture) {
                return { ...item, score: SPPBScores.isLinearPostureScore, second: '0false' };
            } else if (!reportData.data[0].sppb.isNonLinearPosture) {
                return { ...item, score: SPPBScores.isLinearPostureScore, second: '1false' };
            } else {
                return { ...item, score: SPPBScores.isLinearPostureScore, second: reportData.data[0].sppb.isLinearPosture };
            }
        } else if (item.key === 'walkTime') {
            if (!reportData.data[0].sppb.isWalk) {
                return { ...item, score: SPPBScores.walkTimeScore, second: '0false' };
            } else {
                return { ...item, score: SPPBScores.walkTimeScore, second: reportData.data[0].sppb.walkTime };
            }
        } else if (item.key === 'standTime') {
            if (!reportData.data[0].sppb.isStand || reportData.data[0].sppb.standTime > 60) {
                return { ...item, score: SPPBScores.standTimeScore, second: '0false' };
            } else {
                return { ...item, score: SPPBScores.standTimeScore, second: reportData.data[0].sppb.standTime };
            }
        }
        return item;
    });
};

// 등급 계산
export const getGrade: GetGradeFunc = (reportData, userAge) => {
    const grades = walkingSpeedGradeTable[reportData.gender][userAge.slice(0, 1)];
    const value = Number(calculateWalkingMeterPerSecond(reportData.data[0].sppb.walkTime));

    for (const grade in grades) {
        const { min, max } = grades[grade];

        if (value >= min && value <= max) {
            return grade;
        }
    }

    return '범위 밖';
};

// TUG 데이터 설정
export const handleSetTugData: handleSetTugDataFunc = (reportData, setTug, userAge) => {
    // 최종 set func
    const setReturnFunc = (explain: string, tugState: string, divideTime: number) => {
        setTug({ tugTime: reportData.data[0].tug.tugTime, explainTugTime: explain, tugState: tugState, divideTime: divideTime });
    };

    // TUG 시간에 따른 결과 분류
    const divideTimeFunc = (time: number) => {
        if (reportData.data[0].tug.tugTime <= time) {
            return setReturnFunc(TugResultConstants.good, '양호', time);
        } else if (reportData.data[0].tug.tugTime > time && reportData.data[0].tug.tugTime < 10) {
            return setReturnFunc(TugResultConstants.caution, '주의', time);
        } else if (reportData.data[0].tug.tugTime > 10) {
            return setReturnFunc(TugResultConstants.dangers, '위험', time);
        }
    };

    // 나이에 따른 TUG 시간 분류
    if (Number(userAge.slice(0, 2)) <= 69) {
        if (reportData.gender === 'male') {
            return divideTimeFunc(6.8);
        } else {
            return divideTimeFunc(7.3);
        }
    } else if (Number(userAge.slice(0, 2)) >= 70 && Number(userAge.slice(0, 2)) <= 74) {
        if (reportData.gender === 'male') {
            divideTimeFunc(7.5);
        } else {
            divideTimeFunc(8.1);
        }
    } else {
        if (reportData.gender === 'male') {
            divideTimeFunc(8.5);
        } else {
            if (reportData.data[0].tug.tugTime <= 10.3) {
                return setReturnFunc(TugResultConstants.good, '양호', 10.3);
            } else {
                return setReturnFunc(TugResultConstants.dangers, '위험', 10.3);
            }
        }
    }
};

// 근감소증 계산
export const calculateAllSPPBScore: calculateAllSPPBScoreFunc = (SPPBAssessmentList, setSarcopeniaResultScore) => {
    let sum = 0;
    let allSPPBScoreGrade = '';
    let explainSarcopeniaResult = '';

    SPPBAssessmentList.forEach((item) => {
        sum += item.score;
    });

    if (sum <= 6) {
        allSPPBScoreGrade = '위험';
        explainSarcopeniaResult = AssessmentResult.getFallRiskSubTitle({ $fallRiskCondition: '위험' });
    } else if (sum >= 7 && sum <= 9) {
        allSPPBScoreGrade = '주의';
        explainSarcopeniaResult = AssessmentResult.getFallRiskSubTitle({ $fallRiskCondition: '주의' });
    } else {
        allSPPBScoreGrade = '양호';
        explainSarcopeniaResult = AssessmentResult.getFallRiskSubTitle({ $fallRiskCondition: '양호' });
    }

    return setSarcopeniaResultScore({ allSPPBScore: sum, allSPPBScoreGrade: allSPPBScoreGrade, explainSarcopeniaResult: explainSarcopeniaResult });
};
