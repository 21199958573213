import { RunningProgramProps } from 'models/MTE/RunningProgramTypes';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { BorderColor } from 'styles/Global/colorSemantic';
import { palette } from 'styles/Global/globalStyles';
import { Body5_Bold, Caption2_Medium, Caption2_Regular, Caption2_SemiBold } from 'styles/Global/typography';
import { DescAndListTitle } from 'styles/ReportStyles/sarcopeniaAssessmentStyles';

const RunningProgram = ({ recoveryProgram, forCardioProgram, recoveryTime, forCardioTime }: RunningProgramProps) => {
    const exerciseData = [
        {
            type: 'Recovery',
            firstKoreanType: '회복력 향상',
            secondKoreanType: '체지방감량',
            firstSpeedText: `${recoveryTime[0]}km/h`,
            secondSpeedText: `${recoveryTime[1]}/km`,
            calories: [
                { kcal: 150, duration: recoveryProgram[0] },
                { kcal: 200, duration: recoveryProgram[1] },
                { kcal: 250, duration: recoveryProgram[2] },
                { kcal: 300, duration: recoveryProgram[3] },
                { kcal: 350, duration: recoveryProgram[4] },
                { kcal: 400, duration: recoveryProgram[5] },
            ],
        },
        {
            type: 'For cardio',
            firstKoreanType: '심장 강화',
            secondKoreanType: '체력 증진',
            firstSpeedText: `${forCardioTime[0]}km/h`,
            secondSpeedText: `${forCardioTime[1]}km/h`,
            calories: [
                { kcal: 150, duration: forCardioProgram[0] },
                { kcal: 200, duration: forCardioProgram[1] },
                { kcal: 250, duration: forCardioProgram[2] },
                { kcal: 300, duration: forCardioProgram[3] },
                { kcal: 350, duration: forCardioProgram[4] },
                { kcal: 400, duration: forCardioProgram[5] },
            ],
        },
    ];
    return (
        <div>
            <DescAndListTitle>심폐체력 & 리커버리 개인화 운동프로그램</DescAndListTitle>
            <InfomationContainer>
                <RunningProgramListColumnContainer>
                    <ListMainTitle>개인 최적화 러닝 프로그램</ListMainTitle>
                    <TableContainer>
                        <TableHeader>
                            <SubHeader>운동 목적</SubHeader>
                            <DiagonalHeaderComponent>
                                <HeaderLabel $position='top' $align='right'>
                                    <span>운동볼륨</span>
                                    <span> kcal</span>
                                </HeaderLabel>
                                <HeaderLabel $position='bottom' $align='left'>
                                    <span>km km</span>
                                    <span> 운동강도</span>
                                </HeaderLabel>
                            </DiagonalHeaderComponent>
                            {[150, 200, 250, 300, 350, 400].map((kcal) => (
                                <SubHeader key={kcal}>{kcal}</SubHeader>
                            ))}
                        </TableHeader>
                        {exerciseData.map((exercise, index) => (
                            <TableRow key={index} $idx={index}>
                                <ExerciseType>
                                    {exercise.type}
                                    <span>{exercise.firstKoreanType}</span>
                                    <span>{exercise.secondKoreanType}</span>
                                </ExerciseType>
                                <TableCell>
                                    <span>{exercise.firstSpeedText}</span>&&<span>{exercise.secondSpeedText}</span>
                                </TableCell>
                                {exercise.calories.map((cal, calIndex) => (
                                    <TableCell key={calIndex} $align='center'>
                                        {cal.duration}분
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableContainer>
                    <Desc>*개인의 성별, 체중, 체력수료 등의 종합 데이터를 분석하여 최적의 유산소 운동 처방 프로그램을 제공합니다.</Desc>
                </RunningProgramListColumnContainer>
                <KcalInfoListColumnContainer>
                    <ListMainTitle>목적별 최소 주당 소비 kcal</ListMainTitle>
                    <KcalTableContainer>
                        {kcalInfoData.map((kcalInfo, index) => (
                            <KcalTableWrapper key={index}>
                                <KcalTableCellTitle>{kcalInfo.title}</KcalTableCellTitle>
                                <KcalTableCellValue>{kcalInfo.kcal}kcal / 주당</KcalTableCellValue>
                            </KcalTableWrapper>
                        ))}
                    </KcalTableContainer>
                </KcalInfoListColumnContainer>
            </InfomationContainer>
        </div>
    );
};

export default RunningProgram;

const InfomationContainer = styled.div`
    display: flex;
    gap: 20px;
    width: 100%;
    padding: 2px 0 14px 0;
`;

const RunningProgramListColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 4px;
`;
const KcalInfoListColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 35%;
    gap: 4px;
`;

const ListMainTitle = styled(Body5_Bold)`
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${palette.basic.gray[80]};
`;

const TableContainer = styled.div`
    width: 100%;
    border: 1px solid ${palette.PrimaryBlueStrong};
    border-radius: 5px;
    overflow: hidden;
`;

const TableHeader = styled(Caption2_Medium)`
    display: grid;
    grid-template-columns: 1.5fr 1.2fr 1.2fr repeat(6, 1fr);
    background-color: ${palette.PrimaryBlueStrong};
    color: white;
    // font-size: 11px;
    line-height: 13px;
    height: 60px;

    & > * {
        display: flex;
        align-items: center;
        border-right: 1px solid #888;
    }

    & > *:last-child {
        border-right: none;
    }

    & > DiagonalHeader {
        display: block;
    }
`;

// const DiagonalHeader = styled.div`
//     position: relative;
//     grid-column: span 2;

//     &::before {
//         content: '';
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         background: linear-gradient(to top right, transparent calc(50% - 1px), #888, transparent calc(50% + 1px));
//     }
// `;

const DiagonalHeader = styled.div`
    position: relative;
    grid-column: span 2;
`;

const DiagonalLine = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const DiagonalHeaderComponent = ({ children }: { children: ReactNode }) => (
    <DiagonalHeader>
        <DiagonalLine viewBox='0 0 100 100' preserveAspectRatio='none'>
            <line x1='0' y1='0' x2='100' y2='100' stroke='#888' strokeWidth='2' />
        </DiagonalLine>
        {children}
    </DiagonalHeader>
);

const HeaderLabel = styled.div<{ $position: string; $align: string }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    ${(props) => props.$position} : 7px;
    ${(props) => props.$align}: 8px;
    font-weight: bold;

    & > nth-child(1) {
        font-size: 30px;
    }

    ${(props) =>
        props.$align === 'right' &&
        `
        & > :last-child {
            position: absolute;
            top: 14px;
            right: 0;
            font-size: 11px;
        }
    `}
    ${(props) =>
        props.$align === 'left' &&
        `
        & > :first-child {
            position: absolute;
            bottom: 14px;
            left: 0;
            font-size: 11px;
        }
    `}
`;

const SubHeader = styled(Caption2_SemiBold)`
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
`;

const TableRow = styled.div<{ $idx: number }>`
    display: grid;
    grid-template-columns: 1.5fr 2.4fr repeat(6, 1fr);
    ${(props) =>
        props.$idx === 0 &&
        `
        border-bottom: 1px solid ${BorderColor.color_border_strong};
    `}
`;

const KcalTableContainer = styled.div`
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    background-color: ${palette.PrimaryBlueWeak};
    border-radius: 6px;
    padding: 6px 14px;
    height: 200px;
    overflow: hidden;
    border: 1px solid ${BorderColor.color_border_neutral};
`;

const KcalTableWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px dotted ${BorderColor.color_border_normal};

    &:last-child {
        border-bottom: none;
    }
`;

const KcalTableCellTitle = styled(Caption2_SemiBold)`
    display: flex;
    align-items: center;
    gap: 5px;
`;
const KcalTableCellValue = styled(Caption2_Regular)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 6px 0;
`;

const TableCell = styled(Caption2_Medium)<{ $align?: string }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${(props) => props.$align || 'center'};
    text-align: ${(props) => props.$align || 'left'};
    font-size: 11px;
    border-right: 1px solid ${BorderColor.color_border_neutral};
    height: 56px;

    &:last-child {
        border-right: none;
    }
`;

const ExerciseType = styled(TableCell)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const KcalListCircle = styled.div`
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: black;
`;

const Desc = styled(Caption2_Regular)`
    padding-top: 3px;
    color: ${palette.basic.gray[80]};
`;

const kcalInfoData = [
    {
        title: (
            <>
                {'만성질환 '} <KcalListCircle /> {' 당뇨 개선'}
            </>
        ),
        kcal: '1000',
    },
    {
        title: '고지혈증 개선',
        kcal: '1500',
    },
    {
        title: '고혈압 개선',
        kcal: '700',
    },
    {
        title: '비만 예방',
        kcal: '1500',
    },
    {
        title: '비만 개선',
        kcal: '2000',
    },
    {
        title: '심혈관계질환예방',
        kcal: '2200',
    },
];
