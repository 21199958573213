import Divider from 'components/Common/Divider';
import HeaderLine from 'components/Common/HeaderLine';
import MTEReportFooter from 'components/Common/MTEReportFooter';
import MTEReportHeader from 'components/Common/MTEReportHeader';
import { FallRiskAssessmentResult, SarcopeniaAssessmentResult, TUGAssessmentResult, WalkingAssessmentResult } from 'components/SarcopeniaAssessment/SarcopeniaAssessmentResult';
import SPPBAssessmentDataUI from 'components/SarcopeniaAssessment/SPPBAssessmentDataUI';
import { SarcopeniaAssessmentProps } from 'models/MTE/sarcopeniaAssessmentTypes';
import styled from 'styled-components';
import { palette } from 'styles/Global/globalStyles';
import { Caption2_Medium, Caption2_Regular } from 'styles/Global/typography';
import { InnerBodyContainer, InnerBodyLayout } from 'styles/ReportStyles/reportStyles';
import { DescAndListTitle } from 'styles/ReportStyles/sarcopeniaAssessmentStyles';

const SarcopeniaAssessment = ({ SPPBAssessmentList, walkingSpeedData, tug, sarcopeniaResultScore, userInfo, userAge, formattedDate, formattedTime }: SarcopeniaAssessmentProps) => {
    return (
        <InnerBodyLayout>
            <HeaderLine />
            <InnerBodyContainer>
                <MTEReportHeader $title='TLC MTE 검사 리포트 | 근감소/낙상 평가' userInfo={userInfo} userAge={userAge} formattedDate={formattedDate} formattedTime={formattedTime} />
                <DescContainer>
                    <DescWrapper>
                        <DescAndListTitle>SPPB검사</DescAndListTitle>
                        <TitleDesc>Short Physical Performance Battery test</TitleDesc>
                    </DescWrapper>
                    <SarcopeniaAssessmentDesc>간편신체기능검사로 신체기능, 노쇠정도, 근감소증 여부 및 낙상 위험도를 평가</SarcopeniaAssessmentDesc>
                </DescContainer>
                <SPPBAssessmentDataUI SPPBAssessmentList={SPPBAssessmentList} />
                <Divider />
                <ResultsWrapper>
                    <WalkingAssessmentResult walkingSpeedData={walkingSpeedData} />
                    <ResultsRowWrapper>
                        <FallRiskAssessmentResult tug={tug} />
                        <SarcopeniaAssessmentResult sarcopeniaResultScore={sarcopeniaResultScore} />
                        <ResultBottomWrapper>
                            <TUGAssessmentResult tug={tug} userType={userInfo.gender === 'female' && Number(userAge.slice(0, 2)) > 75 && 'oldFemale'} />
                        </ResultBottomWrapper>
                    </ResultsRowWrapper>
                </ResultsWrapper>
            </InnerBodyContainer>
            <MTEReportFooter />
        </InnerBodyLayout>
    );
};

export default SarcopeniaAssessment;

const DescContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 4px;
`;

const ResultsWrapper = styled.div`
    margin-top: 24px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: start;
    gap: 20px;
    height: 100%;
`;

const ResultsRowWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    gap: 4px 20px;
`;

const ResultBottomWrapper = styled.div`
    display: flex;
    grid-row: 2 / 3;
    grid-column: 1 / 3;
`;

const DescWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 6px;
    padding-top: 6px;
`;

const TitleDesc = styled(Caption2_Regular)`
    align-self: flex-end;
    padding: 4px 0;
    color: ${palette.basic.gray[70]};
`;

const SarcopeniaAssessmentDesc = styled(Caption2_Medium)`
    color: ${palette.basic.gray[90]};
`;
