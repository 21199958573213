import { reportApi } from 'Api/Report/reportApi';
import { setMobilityAssessmentDataFunc } from 'functions/MTE/MobilityScoreFunctions';
import {
    calculateAllSPPBScore,
    calculateSPPBListValue,
    calculateWalkingMeterPerSecond,
    getAvarageWalkingSpeedAge,
    getGrade,
    handleSetTugData,
    isLinearPostureTime,
    SitUpAndStandUpSpeed,
    walkingSpeed,
} from 'functions/MTE/SPPBScoreCalculateFunctions';
import { OptimizedProgramFunc, RunningIntensityFunc, Vo2MaxDiagnosisTypeFunc, WalkingAssessmentTableFunc, WalkingSpeedAverageFunc } from 'functions/MTE/WalkingAssessmentFunctions';
import { calculateAge } from 'functions/calculateAge';
import { getReportResponseType } from 'models/MTE/reportApiTypes';
import { SPPBListType } from 'models/MTE/sarcopeniaAssessmentTypes';
import { UserInfo } from 'models/MTE/totalAssessmentPageTypes';
import { OptimizedProgramType, RunningIntensityType, Vo2MaxDiagnosisType, WalkingAssessmentTableType, WalkingSpeedAverageType } from 'models/MTE/walkingAssessmentTypes';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useLoadingStore } from 'store/useLoadingStore';
import styled from 'styled-components';
import AerobicExerciseAssessment from './AerobicExerciseAssessment';
import MobilityAssessment from './MobilityAssessment';
import SarcopeniaAssessment from './SarcopeniaAssessment';
import TotalAssesment from './TotalAssessment';
import { WalkingAssessement } from './WalkingAssessment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const ReportAllPage = () => {
    const contentRef = useRef<HTMLDivElement>(null);
    // const reactToPrintFn = useReactToPrint({ contentRef: contentRef });

    const { state } = useLocation() as { state: { response: getReportResponseType } };

    const [userInfo, setUserInfo] = useState<UserInfo>();
    const [SPPBScores, setSPPBScores] = useState({ isLinearPostureScore: 0, walkTimeScore: 0, standTimeScore: 0 });
    const [userAge, setUserAge] = useState('');
    const [formattedDate, setFormattedDate] = useState('');
    const [formattedTime, setFormattedTime] = useState('');
    const [SPPBAssessmentList, setSPPBAssessmentList] = useState<SPPBListType[]>(SPPBList);
    const [walkingSpeedData, setWalkingSpeedData] = useState({ walkingMeterPerSecond: 0, walkingAge: '', walkingGrade: '' });
    const [tug, setTug] = useState<{ tugTime: number; explainTugTime: string; tugState: string; divideTime: number }>({ tugTime: 0, explainTugTime: '', tugState: '', divideTime: 0 });
    const [sarcopeniaResultScore, setSarcopeniaResultScore] = useState({ allSPPBScore: 0, allSPPBScoreGrade: '', explainSarcopeniaResult: '' });
    const [mobilityAssessmentData, setMobilityAssessmentData] = useState({
        neck: {
            neckGrade: '',
            neckScore: 0,
        },
        hip: {
            hipGrade: '',
            hipScore: 0,
        },
        shoulder: { shoulderGrade: '', shoulderScore: 0 },
        functionalMotion: { functionalMotionGrade: '', functionalMotionScore: 0 },
        muscle: { sitStandScore: 0, sitStandRanking: 0, averageScore: 0, muscleAge: '', sitStandGrade: '' },
    });
    const [walkingTableData, setWalkingTableData] = useState<WalkingAssessmentTableType>({
        bidAfter: 0,
        bidBefore: 0,
        distance: 0,
        hrAfter: 0,
        hrBefore: 0,
        mets: 0,
        speed: 0,
        spo2After: 0,
        spo2Before: 0,
        vo2Average: 0,
        vo2Max: 0,
    });
    const [walkingDiagnosisData, setWalkingDiagnosisData] = useState<Vo2MaxDiagnosisType>({
        cardiorespiratoryAge: 0,
        memo: '',
        totalScoreResult: 'Very Poor',
        vo2Max: 0,
        vo2MaxPercentile: 0,
    });
    const [walkingSpeedAvgData, setWalkingSpeedAvgData] = useState<WalkingSpeedAverageType>({
        distanceChartProps: { averageData: 0, percentile: 0, overallData: [], inputData: 0 },
        percentile: 0,
        speedChartProps: { averageData: 0, percentile: 0, overallData: [], inputData: 0 },
    });
    const [reportData, setReportData] = useState<getReportResponseType>(initReportData);
    const { loading, setLoading } = useLoadingStore();

    const [runningIntensity, setRunningIntensity] = useState<RunningIntensityType>({
        circulatorySafezoneGuide: {
            max: 0,
            min: 0,
        },
        metabolicSafezoneGuide: {
            max: 0,
            min: 0,
        },
        outdoorGoalHigh: {
            kilometer: 0,
            minute: 0,
        },
        outdoorGoalLow: {
            kilometer: 0,
            minute: 0,
        },
        outdoorGoalMid: {
            kilometer: 0,
            minute: 0,
        },
        targetAerobicExercise: {
            kcalPerMinute: 0,
            kilometerPerHour: 0,
        },
        targetBurnFatExercise: {
            kcalPerMinute: 0,
            kilometerPerHour: 0,
        },
        targetHighIntensityExercise: {
            kcalPerMinute: 0,
            kilometerPerHour: 0,
        },
    });

    const [optimizedProgram, setOptimizedProgram] = useState<OptimizedProgramType>({
        heartRateZone: {
            zone1: {
                kcalPerMinute: 0,
                max: 0,
                min: 0,
            },
            zone2: {
                kcalPerMinute: 0,
                max: 0,
                min: 0,
            },
        },
        highIntensityRun: {
            burnFat: {
                kcalPerMinute: 0,
                kilometerPerHour: 0,
            },
            highIntensity: {
                kcalPerMinute: 0,
                kilometerPerHour: 0,
            },
            repetition: {
                max: 0,
                min: 0,
            },
        },
        recoveryRun: 0,
    });

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setUserInfo({
            birthday: reportData.birthday,
            email: reportData.email,
            gender: reportData.gender,
            height: reportData.height,
            managerName: reportData.managerName,
            memo: reportData.memo,
            mobile: reportData.mobile,
            name: reportData.name,
            weight: reportData.weight,
            _id: reportData._id,
        });
        const { justAgeString } = calculateAge(reportData.birthday.slice(2));
        const formattedDate = moment(reportData.timestamp).format('YYYY-MM-DD');
        const formattedTime = moment(reportData.timestamp).format('HH:mm:ss');
        setFormattedDate(formattedDate);
        setFormattedTime(formattedTime);
        SPPBScoresEditFunc();
        setUserAge(justAgeString);
        setMobilityAssessmentDataFunc(reportData, setMobilityAssessmentData, justAgeString.slice(0, 2));
        WalkingAssessmentResultFunc();
        setWalkingSpeedData({
            walkingMeterPerSecond: Number(calculateWalkingMeterPerSecond(reportData.data[0].sppb.walkTime)),
            walkingAge: getAvarageWalkingSpeedAge(reportData),
            walkingGrade: getGrade(reportData, justAgeString),
        });
        handleSetTugData(reportData, setTug, justAgeString);
    }, [reportData]);

    useEffect(() => {
        setSPPBAssessmentList(calculateSPPBListValue(SPPBAssessmentList, reportData, SPPBScores));
    }, [SPPBScores]);

    // 모든 state가 업데이트된 후 로딩 상태를 false로 설정
    useEffect(() => {
        if (
            userInfo &&
            formattedDate &&
            formattedTime &&
            userAge &&
            SPPBAssessmentList.length > 0 &&
            walkingSpeedData.walkingMeterPerSecond !== 0 &&
            tug.tugTime !== 0 &&
            sarcopeniaResultScore.allSPPBScore !== 0 &&
            mobilityAssessmentData.neck.neckScore !== 0 &&
            walkingTableData.distance !== 0 &&
            walkingDiagnosisData.vo2Max !== 0 &&
            walkingSpeedAvgData.distanceChartProps.averageData !== 0
        ) {
            setLoading(false);
        }
    }, [
        userInfo,
        formattedDate,
        formattedTime,
        userAge,
        SPPBAssessmentList,
        walkingSpeedData,
        tug,
        sarcopeniaResultScore,
        mobilityAssessmentData,
        walkingTableData,
        walkingDiagnosisData,
        walkingSpeedAvgData,
    ]);

    useEffect(() => {
        calculateAllSPPBScore(SPPBAssessmentList, setSarcopeniaResultScore);
    }, [SPPBAssessmentList]);

    // report data 불러오기
    const fetchData = async () => {
        // setLoading(true);
        const response = await reportApi.getReport(state.response._id);
        return setReportData(response);
    };

    // input data로 SPPB score 수정
    const SPPBScoresEditFunc = () => {
        isLinearPostureTime(reportData.data[0].sppb.isGeneralPosture, reportData.data[0].sppb.isNonLinearPosture ?? false, reportData.data[0].sppb.isLinearPosture, setSPPBScores);
        walkingSpeed(reportData.data[0].sppb.isWalk, reportData.data[0].sppb.walkTime, setSPPBScores);
        SitUpAndStandUpSpeed(reportData.data[0].sppb.isStand, reportData.data[0].sppb.standTime, setSPPBScores);
    };

    // 심폐지구력 페이지 결과값 계산
    const WalkingAssessmentResultFunc = () => {
        WalkingAssessmentTableFunc(reportData, setWalkingTableData);
        Vo2MaxDiagnosisTypeFunc(reportData, setWalkingDiagnosisData);
        WalkingSpeedAverageFunc(reportData, setWalkingSpeedAvgData);
        RunningIntensityFunc(reportData, setRunningIntensity);
        OptimizedProgramFunc(reportData, setOptimizedProgram);
    };

    const handleGeneratePdf = async () => {
        if (contentRef.current) {
            const pdf = new jsPDF('p', 'mm', 'a4');
            const content = contentRef.current;
            const pages = content.querySelectorAll('.pdf-page');

            for (let i = 0; i < pages.length; i++) {
                const page = pages[i];
                const canvas = await html2canvas(page as HTMLElement, {
                    // allowTaint: true,
                    scale: 2,
                    // useCORS: true,
                });
                const imgData = canvas.toDataURL('image/png');
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

                if (i > 0) {
                    pdf.addPage();
                }
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            }
            pdf.save('report.pdf');

            const pdfBlob = pdf.output('blob');

            const formData = new FormData();
            formData.append('file', pdfBlob, 'report.pdf');

            console.log(pdfBlob, formData);
            // try {
            //     const response = await axios.post('YOUR_SERVER_UPLOAD_ENDPOINT', formData, {
            //         headers: {
            //             'Content-Type': 'multipart/form-data',
            //         },
            //     });
            //     const fileUrl = response.data.fileUrl;
            //     console.log('File uploaded successfully:', fileUrl);
            //     window.open(fileUrl, '_blank');
            // } catch (error) {
            //     console.error('Error uploading PDF:', error);
            // }
        }
    };

    return !loading && userInfo ? (
        <InnerLayout ref={contentRef}>
            <LayoutDivider className='pdf-page'>
                <TotalAssesment
                    formattedDate={formattedDate}
                    formattedTime={formattedTime}
                    handlePrint={handleGeneratePdf}
                    userInfo={userInfo}
                    userAge={userAge}
                    vo2maxData={walkingDiagnosisData}
                    mobilityAssessmentData={mobilityAssessmentData}
                    tug={tug}
                    sarcopeniaResultScore={sarcopeniaResultScore}
                    SPPBAssessmentList={SPPBAssessmentList}
                />
            </LayoutDivider>
            <LayoutDivider className='pdf-page'>
                <SarcopeniaAssessment
                    formattedDate={formattedDate}
                    formattedTime={formattedTime}
                    SPPBAssessmentList={SPPBAssessmentList}
                    walkingSpeedData={walkingSpeedData}
                    tug={tug}
                    sarcopeniaResultScore={sarcopeniaResultScore}
                    userInfo={userInfo}
                    SPPBScores={SPPBScores}
                    userAge={userAge}
                />
            </LayoutDivider>
            <LayoutDivider className='pdf-page'>
                <MobilityAssessment data={mobilityAssessmentData} userInfo={userInfo} userAge={userAge} formattedDate={formattedDate} formattedTime={formattedTime} />
            </LayoutDivider>
            <LayoutDivider className='pdf-page'>
                <AerobicExerciseAssessment userInfo={userInfo} userAge={userAge} vo2maxData={walkingDiagnosisData} formattedDate={formattedDate} formattedTime={formattedTime} />
            </LayoutDivider>
            <LayoutDivider className='pdf-page'>
                <WalkingAssessement
                    userAge={userAge}
                    userInfo={userInfo}
                    runningIntensityProps={runningIntensity}
                    optimizedProgramProps={optimizedProgram}
                    tableProps={walkingTableData}
                    diagnosisProps={walkingDiagnosisData}
                    chartProps={walkingSpeedAvgData}
                    formattedDate={formattedDate}
                    formattedTime={formattedTime}
                />
            </LayoutDivider>
        </InnerLayout>
    ) : null;
};

export default ReportAllPage;

const InnerLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 214mm;
    height: 300mm;
    overflow: auto;

    @media print {
        width: 210mm;
        height: auto;
        margin: 0 auto; /* 중앙 정렬 */
    }
`;

const LayoutDivider = styled.div`
    display: flex;
    width: 210mm;
    height: 297mm;
    page-break-after: always;
`;

const SPPBList = [
    {
        key: 'isLinearPosture',
        title: '정적균형',
        score: 0,
        second: '',
        list: ['일반자세, 10초 유지 불가', '반일렬자세, 10초 유지 불가', '일렬자세, 3초 유지 불가', '일렬자세, 3~9.9초 유지 시', '일렬자세, 10초 유지 성공'],
    },
    {
        key: 'walkTime',
        title: '보행속도',
        score: 0,
        second: '',
        list: ['수행 불가', '> 8.70초', '6.21~8.70초', '4.82~6.20초', '< 4.82초'],
    },
    {
        key: 'standTime',
        title: '일어서기',
        score: 0,
        second: '',
        list: ['수행불가 or 60초 초과', '> 16.70초', '13.70~16.69초', '11.20~13.69초', '< 11.19초'],
    },
];

const initReportData: getReportResponseType = {
    _id: '',
    name: '',
    gender: 'male',
    birthday: '',
    height: 0,
    weight: 0,
    mobile: '',
    email: '',
    managerName: '',
    memo: '',
    data: [
        {
            sppb: {
                isGeneralPosture: false,
                isNonLinearPosture: false,
                isLinearPosture: 0,
                isWalk: false,
                walkTime: 0,
                isStand: false,
                standTime: 0,
            },
            tug: {
                tugTime: 0,
            },
            gait: {
                neck: [],
                hip: [],
                shoulder: [],
                functionalMotion: 0,
            },
            muscle: {
                sitStand: 0,
            },
            respiratory: {
                walkDistance: 0,
                bdiBefore: 0,
                bdiAfter: 0,
                hrBefore: 0,
                hrAfter: 0,
                vo2Max: 0,
                spo2Before: 0,
                spo2After: 0,
            },
        },
    ],
    timestamp: 0,
};
