import { TextareaAutosize } from '@mui/material';
import BasicButton from 'components/Buttons/BasicButton';
import Divider from 'components/Common/Divider';
import { pagePaths } from 'constants/path';
import { validateCheck } from 'functions/validationFunctions';
import RadioButton from 'Icons/RadioButton';
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BackgroundColor, BorderColor, IconColor, PrimaryColor, WarningColor } from 'styles/Global/colorSemantic';
import { font } from 'styles/Global/globalStyles';
import { Radius } from 'styles/Global/objectStyles';
import { Caption1_Regular, Title1_SemiBold } from 'styles/Global/typography';

const MTEMembershipInformationBody = forwardRef((props, ref) => {
    // 2글자 및 숫자만 허용 로직
    const navigate = useNavigate();

    const errorInitValue = {
        name: '',
        birthYear: '',
        birthMonth: '',
        birthDay: '',
        height: '',
        weight: '',
        phoneNumber: '',
        email: '',
    };

    const [errMsg, setErrMsg] = useState(errorInitValue);
    const [name, setName] = useState('');
    const [gender, setGender] = useState('male');
    const [birthYear, setBirthYear] = useState('');
    const [birthMonth, setBirthMonth] = useState('');
    const [birthDay, setBirthDay] = useState('');
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [managerName, setManagerName] = useState('');
    const [memo, setMemo] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);

    // 초기화 버튼 로직
    const handReset = () => {
        setErrMsg(errorInitValue);
        setName('');
        setGender('male');
        setBirthYear('');
        setBirthMonth('');
        setBirthDay('');
        setHeight('');
        setWeight('');
        setPhoneNumber('');
        setEmail('');
        setManagerName('');
        setMemo('');
        setButtonDisabled(false);
    };

    // Input 값이 변경될 때마다 버튼 활성화 로직
    useEffect(() => {
        if (
            errMsg.name ||
            errMsg.birthYear ||
            errMsg.birthMonth ||
            errMsg.birthDay ||
            errMsg.height ||
            errMsg.weight ||
            errMsg.phoneNumber ||
            errMsg.email ||
            !name ||
            !gender ||
            !birthYear ||
            !birthMonth ||
            !birthDay ||
            !height ||
            !weight ||
            !phoneNumber ||
            !email
        ) {
            setButtonDisabled(true);
        } else {
            setButtonDisabled(false);
        }
    }, [errMsg, name, gender, birthYear, birthMonth, birthDay, height, weight, phoneNumber, email]);

    // Input change event
    const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, type: string) => {
        const value = e.target.value;
        switch (type) {
            case 'name':
                validateCheckFunc(type, value);
                return setName(value);
            case 'birthYear':
                if (/^\d{0,4}$/.test(value)) {
                    validateCheckFunc(type, value);
                    return setBirthYear(value);
                }
                break;
            case 'birthMonth':
                if (/^\d{0,2}$/.test(value)) {
                    validateCheckFunc(type, value);
                    return setBirthMonth(value);
                }
                break;
            case 'birthDay':
                if (/^\d{0,2}$/.test(value)) {
                    validateCheckFunc(type, value);
                    return setBirthDay(value);
                }
                break;
            case 'height':
                if (/^\d{0,3}(\.\d{0,1})?$/.test(value)) {
                    validateCheckFunc(type, value);
                    return setHeight(value);
                }
                break;
            case 'weight':
                if (/^\d{0,3}$/.test(value)) {
                    validateCheckFunc(type, value);
                    return setWeight(value);
                }
                break;
            case 'phoneNumber':
                if (/^\d{0,11}$/.test(value)) {
                    validateCheckFunc(type, value);
                    return setPhoneNumber(value);
                }
                break;
            case 'email':
                validateCheckFunc(type, value);
                setEmail(value);
                break;
            case 'managerName':
                setManagerName(value);
                break;
            case 'memo':
                setMemo(value);
                break;
        }
    };

    // Err check function
    const validateCheckFunc = (type: string, value: string) => {
        if (birthYear && birthMonth) {
            const isValidDate = (year: number, month: number, day: number): boolean => {
                const date = new Date(year, month - 1, day);
                return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
            };

            if (type === 'birthDay' && birthYear && birthMonth) {
                console.log(birthYear, birthMonth, value);
                const year = parseInt(birthYear, 10);
                const month = parseInt(birthMonth, 10);
                const day = parseInt(value, 10); // value를 day로 변환

                if (!isValidDate(year, month, day)) {
                    return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [type]: '유효한 날짜를 입력해주세요.' }));
                }
                return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [type]: validateCheck[type](value) }));
            } else if (type === 'birthDay') {
                return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [type]: validateCheck[type](value) }));
            }
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [type]: validateCheck[type](value) }));
        } else if (validateCheck[type](value)) {
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [type]: validateCheck[type](value) }));
        } else {
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [type]: '' }));
        }
    };

    // Err blur check func
    const handleBlur = (type: string) => {
        if (type === 'birthMonth' && birthMonth.length === 1) {
            setBirthMonth('0' + birthMonth);
        } else if (type === 'birthDay' && birthDay.length === 1) {
            setBirthDay('0' + birthDay);
        }
    };

    // button click event
    const clickButton = () => {
        const userInfomation = {
            _id: '',
            name: name,
            gender: gender,
            birthday: birthYear + birthMonth + birthDay,
            height: Number(height),
            weight: Number(weight),
            mobile: phoneNumber,
            email: email,
            managerName: managerName,
            memo: memo,
        };
        navigate(pagePaths.reportInput, {
            state: userInfomation,
        });
    };

    useImperativeHandle(ref, () => ({
        onReset: handReset,
    }));

    return (
        <Layout>
            <MainTitle>회원정보 입력</MainTitle>
            <Divider />
            <InputContainer>
                <InputRowWrapper>
                    <TitleWrapper>
                        <Title>이름</Title>
                        <Badge>*</Badge>
                    </TitleWrapper>
                    <InputWrapper>
                        <BasicInput placeholder='회원 이름 (한글, 영문 대소문자)' value={name} onChange={(e) => handleChange(e, 'name')} $warning={errMsg.name} />
                        {errMsg.name && <ErrorMsg $marginTop='0'>{errMsg.name}</ErrorMsg>}
                    </InputWrapper>
                </InputRowWrapper>
                <InputRowWrapper $type='gender'>
                    <TitleWrapper>
                        <Title>성별</Title>
                        <Badge>*</Badge>
                    </TitleWrapper>
                    <GenderWrapper>
                        <CheckBoxWrapper onClick={() => setGender('male')}>
                            <RadioButtonWrapper>{gender === 'male' ? <RadioButton $width='18' $height='18' /> : <DefaultRadioButton />}</RadioButtonWrapper>
                            남자
                        </CheckBoxWrapper>
                        <CheckBoxWrapper onClick={() => setGender('female')}>
                            <RadioButtonWrapper>{gender === 'female' ? <RadioButton $width='18' $height='18' /> : <DefaultRadioButton />}</RadioButtonWrapper>
                            여자
                        </CheckBoxWrapper>
                    </GenderWrapper>
                </InputRowWrapper>
                <InputRowWrapper>
                    <TitleWrapper>
                        <Title>생년월일</Title>
                        <Badge>*</Badge>
                    </TitleWrapper>
                    <InputWrapper>
                        <IdentityNumberInputWrapper>
                            <IdentityInput placeholder='출생연도' onChange={(e) => handleChange(e, 'birthYear')} value={birthYear} maxLength={4} $warning={errMsg.birthYear} />
                            <IdentityInput
                                placeholder='월'
                                onChange={(e) => handleChange(e, 'birthMonth')}
                                value={birthMonth}
                                maxLength={2}
                                $warning={errMsg.birthMonth}
                                onBlur={() => handleBlur('birthMonth')}
                            />
                            <IdentityInput
                                placeholder='일'
                                onChange={(e) => handleChange(e, 'birthDay')}
                                value={birthDay}
                                maxLength={2}
                                $warning={errMsg.birthDay}
                                onBlur={() => handleBlur('birthDay')}
                            />
                        </IdentityNumberInputWrapper>
                        {(errMsg.birthDay || errMsg.birthMonth || errMsg.birthYear) && <ErrorMsg $marginTop='0'>{errMsg.birthDay || errMsg.birthMonth || errMsg.birthYear}</ErrorMsg>}
                    </InputWrapper>
                </InputRowWrapper>
                <InputRowWrapper>
                    <TitleWrapper>
                        <Title>키</Title>
                        <Badge>*</Badge>
                    </TitleWrapper>
                    <InputWrapper>
                        <div style={{ position: 'relative' }}>
                            <BasicInput onChange={(e) => handleChange(e, 'height')} value={height} $warning={errMsg.height} />
                            <AbsoluteSpan>cm</AbsoluteSpan>
                        </div>
                        {errMsg.height && <ErrorMsg $marginTop='0'>{errMsg.height}</ErrorMsg>}
                    </InputWrapper>
                </InputRowWrapper>
                <InputRowWrapper>
                    <TitleWrapper>
                        <Title>체중</Title>
                        <Badge>*</Badge>
                    </TitleWrapper>
                    <InputWrapper>
                        <div style={{ position: 'relative' }}>
                            <BasicInput onChange={(e) => handleChange(e, 'weight')} value={weight} $warning={errMsg.weight} />
                            <AbsoluteSpan>kg</AbsoluteSpan>
                        </div>
                        {errMsg.weight && <ErrorMsg $marginTop='0'>{errMsg.weight}</ErrorMsg>}
                    </InputWrapper>
                </InputRowWrapper>
                <InputRowWrapper>
                    <TitleWrapper>
                        <Title>연락처</Title>
                        <Badge>*</Badge>
                    </TitleWrapper>
                    <InputWrapper>
                        <BasicInput onChange={(e) => handleChange(e, 'phoneNumber')} value={phoneNumber} $warning={errMsg.phoneNumber} />
                        {errMsg.phoneNumber && <ErrorMsg $marginTop='0'>{errMsg.phoneNumber}</ErrorMsg>}
                    </InputWrapper>
                </InputRowWrapper>
                <InputRowWrapper>
                    <TitleWrapper>
                        <Title>이메일</Title>
                        <Badge>*</Badge>
                    </TitleWrapper>
                    <InputWrapper>
                        <BasicInput onChange={(e) => handleChange(e, 'email')} value={email} $warning={errMsg.email} />
                        {errMsg.email && <ErrorMsg $marginTop='0'>{errMsg.email}</ErrorMsg>}
                    </InputWrapper>
                </InputRowWrapper>
                <InputRowWrapper>
                    <TitleWrapper>
                        <Title>담당강사</Title>
                    </TitleWrapper>
                    <BasicInput onChange={(e) => handleChange(e, 'managerName')} value={managerName} />
                </InputRowWrapper>
                <InputRowWrapper $type='memo'>
                    <TitleWrapper>
                        <Title>메모</Title>
                    </TitleWrapper>
                    <TextArea minRows={3} onChange={(e) => handleChange(e, 'memo')} value={memo} />
                </InputRowWrapper>
                <ButtonWrapper>
                    <BasicButton $width='200px' $height='52px' $type='fill' onClick={clickButton} disabled={buttonDisabled}>
                        입력 완료
                    </BasicButton>
                </ButtonWrapper>
            </InputContainer>
        </Layout>
    );
});

export default MTEMembershipInformationBody;

const ErrorMsg = styled(Caption1_Regular)<{ $marginTop?: string }>`
    margin-top: ${(props) => props.$marginTop || '8px'};
    color: ${WarningColor.color_warning_normal};
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const MainTitle = styled(Title1_SemiBold)`
    margin-bottom: 18px;
`;

const Title = styled(Title1_SemiBold)``;

const Badge = styled(Title)`
    color: #fe0b0b;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 27px;
    width: 100%;
    margin-top: 16px;
`;

const RadioButtonWrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 6px;
`;

const InputRowWrapper = styled.div<{ $type?: string }>`
    display: flex;
    align-items: ${(props) => props.$type !== 'memo' && 'center'};
    justify-content: ${(props) => props.$type !== 'gender' && 'space-between'};
    width: 552px;
    ${(props) => props.$type && 'gap: 110px'};
    ${(props) => props.$type === 'memo' && 'margin-bottom: 107px'};
`;

const TitleWrapper = styled.div`
    display: flex;
    gap: 4px;
`;
const GenderWrapper = styled.div`
    display: flex;
    gap: 34px;
`;

const CheckBoxWrapper = styled.button`
    display: flex;
    align-items: center;
    line-height: 28px;
    font-size: 20px;
    font-weight: ${font.weight.medium};
`;

const BasicInput = styled.input<{ $warning?: string }>`
    width: 381px;
    border: ${(props) => (props.$warning ? `0.5px solid ${WarningColor.color_warning_normal}` : `0.5px solid #6f6f6f`)};
    border-radius: 10px;
    padding: 6px 21px;
    font-size: 20px;
    font-weight: ${font.weight.medium};
    line-height: 28px;
    caret-color: ${(props) => props.$warning && 'red'};

    ::placeholder {
        color: #9a9a9a;
    }
`;

const AbsoluteSpan = styled.span`
    position: absolute;
    top: 5px;
    right: 25px;
    font-size: 20px;
    font-weight: ${font.weight.medium};
    line-height: 28px;
    color: #9a9a9a;
`;

const IdentityInput = styled.input<{ $warning?: string }>`
    width: 100%;
    border: ${(props) => (props.$warning ? `0.5px solid ${WarningColor.color_warning_normal}` : `0.5px solid #6f6f6f`)};
    border-radius: 10px;
    padding: 6px 21px;
    font-size: 20px;
    font-weight: ${font.weight.medium};
    line-height: 28px;
    text-align: center;
    height: 40px;
    caret-color: ${(props) => props.$warning && 'red'};

    ::placeholder {
        color: #9a9a9a;
        text-align: center;
    }

    &:focus::placeholder {
        color: transparent; /* 포커스 시 placeholder 없애기 */
    }
`;

const IdentityNumberInputWrapper = styled.div`
    display: flex;
    width: 381px;
    gap: 12px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const TextArea = styled(TextareaAutosize)`
    display: flex;
    alignitems: flex-start;
    width: calc(100% - 170px);
    height: 180px;
    padding: 18px 20px;
    border: 0.5px solid #6f6f6f;
    border-radius: 10px;
    font-size: ${font.size[20]};
    font-weight: ${font.weight.medium};
    line-height: 28px;
    resize: none;
    outline: none;
`;

const DefaultRadioButton = styled.div<{ disabled?: boolean }>`
    width: 18px;
    height: 18px;
    border-radius: ${Radius.radius_circle};
    border: 1px solid ${IconColor.color_icon_light};
    background-color: ${BackgroundColor.color_background_white};
    &:hover {
        border: 1px solid ${IconColor.color_icon_neutral};
    }
    &:active {
        border: 1px solid ${PrimaryColor.color_primary_normal};
    }
    &:focus {
        border: 1px solid ${PrimaryColor.color_primary_normal};
    }
    ${(props) =>
        props.disabled &&
        `
        border: 1px solid ${BorderColor.color_border_neutral};
        background-color: ${BackgroundColor.color_background_disabled};
    `}
`;
