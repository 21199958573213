import { SPPBBodyListPropsTypes, SPPBBodyPropsTypes, SPPBListType } from 'models/MTE/sarcopeniaAssessmentTypes';
import styled from 'styled-components';
import { BorderColor } from 'styles/Global/colorSemantic';
import { palette } from 'styles/Global/globalStyles';
import { Body4_Medium, Body5_SemiBold, Caption2_Medium, Caption2_SemiBold } from 'styles/Global/typography';

const SPPBAssessmentData = ({ SPPBAssessmentList }: { SPPBAssessmentList: SPPBListType[] }) => {
    return (
        <Layout>
            {SPPBAssessmentList.map((item: any, index: number) => (
                <InnerLayout>
                    <SPPBBody key={index} $title={item.title} $score={item.score} />
                    <SPPBBodyList $item={item} $index={index} />
                </InnerLayout>
            ))}
        </Layout>
    );
};

export default SPPBAssessmentData;

const SPPBBody = ({ $title, $score }: SPPBBodyPropsTypes) => {
    const gaugeBoxes = Array(4).fill(null);
    return (
        <SPPBBodyLayout>
            <SPPBBodyGaugeContainer>
                <SPPBBodyGaugeTitleWrapper>
                    <span>{$title}</span>
                    <div>
                        <span style={{ color: palette.PrimaryBlue }}>{$score}</span>
                        <span style={{ color: palette.basic.gray[70] }}> / 4</span>
                    </div>
                </SPPBBodyGaugeTitleWrapper>
                <SPPBBodyGaugeBoxWrapper>
                    {gaugeBoxes.map((_, index) => (
                        <SPPBBodyGaugeBox key={index} $selected={index < $score} />
                    ))}
                </SPPBBodyGaugeBoxWrapper>
            </SPPBBodyGaugeContainer>
        </SPPBBodyLayout>
    );
};

const SPPBBodyList = ({ $item, $index }: SPPBBodyListPropsTypes) => {
    return (
        <GridLayout>
            {$item.list.map((item, index) => (
                <>
                    <LeftCell>
                        <IndexCell>{index}</IndexCell>
                        {item}
                    </LeftCell>
                    <RightCell>{$item.score === index && <TextWrapper>{$item.second + '초'}</TextWrapper>}</RightCell>
                </>
            ))}
        </GridLayout>
    );
};

const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${palette.PrimaryBlue};
    width: 100%;
    border-radius: 99px;
    height: 24px;
    color: white;
`;

const GridLayout = styled.div`
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-template-rows: repeat(5, 36px);
    width: 100%;
    border: 1px solid ${BorderColor.color_border_neutral};
    border-radius: 6px;
    background-color: ${palette.PrimaryBlueWeak};
    padding: 6px 8px;
`;

const LeftCell = styled(Caption2_Medium)`
    display: flex;
    align-items: center;
    gap: 5px;
    height: 100%;
    border-bottom: 1px solid ${BorderColor.color_border_neutral};
    padding: 5px 0;

    &:nth-last-of-type(2) {
        border-bottom: none;
    }
`;
const RightCell = styled(Caption2_SemiBold)`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.PrimaryBlueStrong};
    border-bottom: 1px solid ${BorderColor.color_border_neutral};

    &:nth-last-of-type(1) {
        border-bottom: none;
    }
`;

const IndexCell = styled(Caption2_SemiBold)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 22px;
    padding: 1px 2px;
    border: 1px solid ${BorderColor.color_border_normal};
    border-radius: 4px;
    background-color: #fff;
    color: ${palette.PrimaryBlueStrong};
`;

const Layout = styled.div`
    display: flex;
    width: 100%;
    padding: 12px 0 0;
    margin-bottom: 24px;
    gap: 20px;
`;

const InnerLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
`;

const SPPBBodyLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
`;

const SPPBBodyGaugeContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const SPPBBodyGaugeTitleWrapper = styled(Body5_SemiBold)`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 6px;
`;

const SPPBBodyGaugeBoxWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 25px;
    gap: 2px;
`;

const SPPBBodyGaugeBox = styled.div<{ $selected: boolean }>`
    width: 100%;
    height: 100%;
    background-color: ${(props) => (props.$selected ? palette.PrimaryBlue : palette.PrimaryBlueWeak)};

    &:first-of-type {
        border-radius: 6px 0 0 6px;
    }

    &:last-of-type {
        border-radius: 0 6px 6px 0;
    }
`;
