import { useEffect, useState } from 'react';
import { InnerBodyContainer, InnerBodyLayout } from 'styles/ReportStyles/reportStyles';
import HeaderLine from '../../components/Common/HeaderLine';
import MTEReportFooter from '../../components/Common/MTEReportFooter';
import MTEReportHeader from 'components/Common/MTEReportHeader';
import RunningProgram from 'components/AerobicExerciseAssessment/RunningProgram';
import WeeklyExerciseProgram from 'components/AerobicExerciseAssessment/WeeklyExerciseProgram';
import WalkAndRunningExerciseGraph from 'components/AerobicExerciseAssessment/WalkAndRunningExerciseGraph';
import { UserInfo } from 'models/MTE/totalAssessmentPageTypes';
import { Vo2MaxDiagnosisType } from 'models/MTE/walkingAssessmentTypes';

const AerobicExerciseAssessment = ({
    userInfo,
    userAge,
    vo2maxData,
    formattedDate,
    formattedTime,
}: {
    userInfo: UserInfo;
    userAge: string;
    formattedDate: string;
    formattedTime: string;
    vo2maxData: Vo2MaxDiagnosisType;
}) => {
    const [recoveryProgram, setRecoveryProgram] = useState<string[]>(['']);
    const [forCardioProgram, setForCardioProgram] = useState<string[]>(['']);
    const [recoveryTime, setRecoveryTime] = useState<string[]>(['']);
    const [forCardioTime, setForCardioTime] = useState<string[]>(['']);

    useEffect(() => {
        personalRunningProgram('recovery');
        personalRunningProgram('forCardio');
    }, [userInfo]);

    const personalRunningProgram = (type: string) => {
        const meter = [150, 200, 250, 300, 350, 400];
        const result: string[] = [];

        // 회복력 향상 속도
        const speedResilienceImprovement = Number((((vo2maxData.vo2Max - 3.5) * (type === 'recovery' ? 0.5 : 0.8)) / 3.35).toFixed(1));

        // 1시간 동안 회복 향상 속도
        const timeInHours = 1 / speedResilienceImprovement;
        // 시간을 분으로 바꾸기
        const timeInMinutes = timeInHours * 60;
        // 소수점 버리고 정수만 남기기(분 구하기)
        const minutes = Math.floor(timeInMinutes);
        // 소수점 이하의 나머지 값을 구하여 초를 구하고, 반올림
        const seconds = Math.round((timeInMinutes - minutes) * 60);
        // 회복력 향상 페이스
        const formattedTime = `${String(minutes).padStart(2, '0')}'${String(seconds).padStart(2, '0')}"`;
        // 분당 소비 kcal
        const kcalPerMinute = Number((((speedResilienceImprovement * 3.35 + 3.5) * userInfo.weight) / 200).toFixed(1));

        meter.forEach((value, idx) => {
            const runningValue = Math.floor(((value / 70) * userInfo.weight) / kcalPerMinute);
            result.push(String(runningValue));
        });

        if (type === 'recovery') {
            setRecoveryTime([String(speedResilienceImprovement), formattedTime]);
            return setRecoveryProgram(result);
        } else {
            setForCardioTime([String(speedResilienceImprovement), formattedTime]);
            return setForCardioProgram(result);
        }
    };

    return (
        <InnerBodyLayout>
            <HeaderLine />
            <InnerBodyContainer style={{ justifyContent: 'space-between' }}>
                <MTEReportHeader $title='TLC MTE 처방 리포트 | 유산소 운동' userInfo={userInfo} userAge={userAge} formattedDate={formattedDate} formattedTime={formattedTime} />
                <RunningProgram recoveryProgram={recoveryProgram} forCardioProgram={forCardioProgram} recoveryTime={recoveryTime} forCardioTime={forCardioTime} />
                <WeeklyExerciseProgram />
                <WalkAndRunningExerciseGraph />
            </InnerBodyContainer>
            <MTEReportFooter />
        </InnerBodyLayout>
    );
};

export default AerobicExerciseAssessment;
