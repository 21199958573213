import { getMuscleAgeFuncType, getSitStandRankingFuncType, MobilityGrade, mobilityTable, mobilityTableAvarage, setMobilityAssessmentDataFuncType } from 'models/MTE/mobilityAssessmentTypes';

// 가동성 검사, 기능성 검사 데이터 설정
export const setMobilityAssessmentDataFunc: setMobilityAssessmentDataFuncType = (reportData, setMobilityAssessmentData, userAge) => {
    const { neck, hip, shoulder, functionalMotion } = reportData.data[0].gait;
    const { sitStand } = reportData.data[0].muscle;
    const gender: 'male' | 'female' = reportData.gender;
    const neckScore = neck.sort((a, b) => a - b)[0];
    const hipScore = hip.sort((a, b) => a - b)[0];
    const shoulderScore = shoulder.sort((a, b) => a - b)[0];

    const neckGrade = getMobilityAssessmentGrade(neckScore);
    const hipGrade = getMobilityAssessmentGrade(hipScore);
    const shoulderGrade = getMobilityAssessmentGrade(shoulderScore);
    const functionalMotionGrade = getMobilityAssessmentGrade(functionalMotion);
    const sitStandRanking = getSitStandRanking(userAge, sitStand, gender);
    const sitStandGrade = getSitStandGrade(sitStandRanking);
    const [averageScore, muscleAge] = getMuscleAge(sitStand, gender, userAge);

    return setMobilityAssessmentData({
        neck: { neckGrade: neckGrade, neckScore: neckScore },
        hip: { hipGrade: hipGrade, hipScore: hipScore },
        shoulder: { shoulderGrade: shoulderGrade, shoulderScore: shoulderScore },
        functionalMotion: { functionalMotionGrade: functionalMotionGrade, functionalMotionScore: functionalMotion },
        muscle: { sitStandScore: sitStand, sitStandRanking: sitStandRanking, muscleAge: muscleAge, averageScore: averageScore, sitStandGrade: sitStandGrade },
    });
};

// 하지근력 나이 구하는 공식
const getMuscleAge: getMuscleAgeFuncType = (sitStand: number, gender: 'male' | 'female', userAge: string) => {
    if (sitStand < Number(Object.keys(mobilityTableAvarage[gender][0])[0])) {
        return [Number(Object.keys(mobilityTableAvarage[gender][0])[0]), '60'];
    } else if (sitStand > Number(Object.keys(mobilityTableAvarage[gender][40])[0])) {
        return [Number(Object.keys(mobilityTableAvarage[gender][40])[0]), '20'];
    } else {
        for (let average of mobilityTableAvarage[gender]) {
            if (sitStand === Number(Object.keys(average)[0])) {
                return [findKeyByValue(gender, userAge), Object.values(average)[0]];
            }
        }
    }
    return [0, '0'];
};

// 해당 user 나이대의 평균 앉아 일어서기 개수 구하는 로직
function findKeyByValue(gender: 'male' | 'female', value: string): number {
    for (const obj of mobilityTableAvarage[gender]) {
        for (const key in obj) {
            if (obj[key] === value) {
                return Number(key);
            }
        }
    }
    return 0;
}

// 앉아 일어서기 개수로 해당 나이대 순위
const getSitStandRanking: getSitStandRankingFuncType = (age: string, value: number, gender: 'male' | 'female') => {
    let editAge = age;

    if (Number(age) > 60) {
        editAge = '60';
    } else if (Number(age) < 20) {
        editAge = '20';
    }
    // console.log(editAge, Number(age.sl));
    if (value > mobilityTable[gender][editAge][98]) {
        // return [mobilityTable[gender][editAge][98], MobilityGrade[98]];
        return MobilityGrade[98];
    } else if (value < mobilityTable[gender][editAge][0]) {
        // return [mobilityTable[gender][editAge][0], MobilityGrade[0]];
        return MobilityGrade[0];
    } else {
        for (let i = 0; i < mobilityTable[gender][editAge].length; i++) {
            if (mobilityTable[gender][editAge][i] === value) {
                // return [mobilityTable[gender][editAge][i], MobilityGrade[i]];
                return MobilityGrade[i];
            }
        }
    }
    // return [0, 0];
    return 0;
};

// 가동성 검사, 기능성 검사 등급
const getMobilityAssessmentGrade = (score: number): string => {
    switch (score) {
        case 0:
            return '통증';
        case 1:
            return '교정필요';
        case 2:
            return '개선필요';
        case 3:
            return '정상';
        default:
            return '';
    }
};

// 앉아 일어서기 등급 텍스트
const getSitStandGrade = (sitStandRanking: number) => {
    if (sitStandRanking >= 95) {
        return '매우 훌륭함';
    } else if (sitStandRanking >= 80) {
        return '훌륭함';
    } else if (sitStandRanking >= 60) {
        return '강함';
    } else if (sitStandRanking >= 40) {
        return '보통';
    } else if (sitStandRanking >= 20) {
        return '약함';
    } else {
        return '매우 약함';
    }
};
