const walkConstant = {
    male: 16.34,
    female: -9.48,
};

const distanceConstant = {
    male: 0.06,
    female: 0.06,
};

const ageConstant = {
    male: -0.12,
    female: -0.09,
};

const heightConstant = {
    male: -0.02,
    female: -0.03,
};

const weightConstant = {
    male: -0.04,
    female: 0.1,
};

export type Vo2MaxFunc = (params: { age: number; walkdistance: number; height: number; weight: number; gender: string }) => number;
export type DistanceFunc = (params: { age: number; vo2Max: number; height: number; weight: number; gender: string }) => number;

// VO2 Max 계산 함수
export const getVo2Max: Vo2MaxFunc = ({ age, gender, height, walkdistance, weight }) => {
    const genderType = gender as 'male' | 'female';
    const vo2Max = walkConstant[genderType] + distanceConstant[genderType] * walkdistance + ageConstant[genderType] * age + heightConstant[genderType] * height + weightConstant[genderType] * weight;
    return vo2Max;
};

// 거리 계산 함수(vo2Max 역산)
export const getDistanceFromVo2Max: DistanceFunc = ({ vo2Max, age, gender, height, weight }) => {
    const genderType = gender as 'male' | 'female';
    const distance = (vo2Max - walkConstant[genderType] - ageConstant[genderType] * age - heightConstant[genderType] * height - weightConstant[genderType] * weight) / distanceConstant[genderType];
    return distance;
};
