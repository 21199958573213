import { WalkingAssessmentTableType } from 'models/MTE/walkingAssessmentTypes';
import styled from 'styled-components';
import { palette } from 'styles/Global/globalStyles';
import { Body4_Bold, Caption2_SemiBold } from 'styles/Global/typography';
import { ContainerLayout, Title } from 'styles/ReportStyles/walkingAssessmentStyles';

export const WalkingAssessmentTable = (props: WalkingAssessmentTableType) => {
    return (
        <ContainerLayout>
            <Title>6분 걷기 검사</Title>
            <TableContainer>
                <TableHeader>
                    <TableTitleRow $color='white'>
                        <TableHeaderText>
                            거리
                            <br />
                            Distance
                        </TableHeaderText>
                        <TableHeaderText>
                            최대산소능력
                            <br />
                            VO2 max
                        </TableHeaderText>
                        <TableHeaderText>
                            연령평균 <br /> VO2 Average
                        </TableHeaderText>
                        <TableHeaderText>
                            산소이용률
                            <br /> METs
                        </TableHeaderText>
                        <TableHeaderText>
                            속도
                            <br />
                            Speed
                        </TableHeaderText>
                        <TableHeaderText>
                            호흡곤란지수
                            <br />
                            (전/후) BDI
                        </TableHeaderText>
                        <TableHeaderText>
                            심박수
                            <br />
                            HR
                        </TableHeaderText>
                        <TableHeaderText>
                            산소포화도
                            <br />
                            SPO2
                        </TableHeaderText>
                    </TableTitleRow>
                </TableHeader>
                <TableBody>
                    <TableBodyRow>
                        <TableCellWithDivider>{props.distance.toFixed(2)}m</TableCellWithDivider>
                        <TableCellWithDivider>{props.vo2Max}</TableCellWithDivider>
                        <TableCellWithDivider>{props.vo2Average}</TableCellWithDivider>
                        <TableCellWithDivider>{props.mets}</TableCellWithDivider>
                        <TableCellWithDivider>{props.speed}km/h</TableCellWithDivider>
                        <TableCellWithDivider>
                            {props.bidBefore} -&gt; {props.bidAfter}
                        </TableCellWithDivider>
                        <TableCellWithDivider>
                            {props.hrBefore} -&gt; {props.hrAfter}
                        </TableCellWithDivider>
                        <TableCellWithDivider>
                            {props.spo2Before}% -&gt; {props.spo2After}%
                        </TableCellWithDivider>
                    </TableBodyRow>
                </TableBody>
            </TableContainer>
        </ContainerLayout>
    );
};

const TableContainer = styled.div`
    width: 100%;
    border: 1px solid ${palette.PrimaryBlueStrong};
    border-radius: 10px;
    overflow: hidden;
`;

const TableTitleRow = styled.div<{ $color?: string }>`
    display: grid;
    flex-direction: row;
    color: ${({ $color }) => $color || `black`};
    align-items: center;
    padding: 3px 0;
    grid-template-columns: 1.3fr 1.3fr 1.2fr 1.2fr 1.3fr 1.6fr 1.2fr 1.8fr;

    * {
        color: inherit;
    }
`;

const TableBodyRow = styled.div<{ $color?: string }>`
    display: grid;
    padding: 8px 0;
    flex-direction: row;
    grid-template-columns: 1.4fr 1.4fr 1.2fr 1.2fr 1.5fr 1.5fr 1.4fr 1.8fr;
`;

const TableHeader = styled.div`
    background-color: ${palette.PrimaryBlueStrong};
`;

const TableHeaderText = styled(Caption2_SemiBold)`
    text-align: center;
`;

const TableBody = styled.div`
    background-color: white;
    width: 100%;
`;
const TableCell = styled(Body4_Bold)`
    text-align: center;
`;

const TableCellWithDivider = styled(TableCell)`
    border-right: 1px solid ${palette.PrimaryBlueStrong};
    padding: 6px 0;
    &:last-child {
        border-right: none;
    }
`;
