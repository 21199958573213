import { RunningIntensityType } from 'models/MTE/walkingAssessmentTypes';
import styled from 'styled-components';
import { BorderColor } from 'styles/Global/colorSemantic';
import { palette } from 'styles/Global/globalStyles';
import { Body4_SemiBold, Caption1_Bold, Caption1_SemiBold, Caption2_Regular, Caption2_SemiBold } from 'styles/Global/typography';
import { ContainerLayout, SubTitle, Title, TitleWrapper } from 'styles/ReportStyles/walkingAssessmentStyles';

export const RunningIntensity = ({
    targetAerobicExercise,
    targetBurnFatExercise,
    targetHighIntensityExercise,
    outdoorGoalHigh,
    outdoorGoalLow,
    outdoorGoalMid,
    circulatorySafezoneGuide: safezoneGuideHigh,
    metabolicSafezoneGuide: safezoneGuideLow,
}: RunningIntensityType) => {
    return (
        <ContainerLayout>
            <TitleWrapper>
                <Title>달리기 강도</Title>
                <SubTitle>Running Intensity</SubTitle>
            </TitleWrapper>
            <InnerTitleLayout>
                <InnerTitleWrapper>
                    <InnerTitle>트레드 밀</InnerTitle>
                    <InnerSubTitle>for Treadmill exercise</InnerSubTitle>
                </InnerTitleWrapper>
                <InnerTitleWrapper>
                    <InnerTitle>야외달리기</InnerTitle>
                    <InnerSubTitle>For Outdoor Goal</InnerSubTitle>
                </InnerTitleWrapper>
                <InnerTitleWrapper>
                    <InnerTitle>질환자가이드</InnerTitle>
                    <InnerSubTitle>For medical patients</InnerSubTitle>
                </InnerTitleWrapper>
            </InnerTitleLayout>
            <BodyLayout>
                <TreadmillLayout>
                    <TreadmillWrapper>
                        <TreadmillTagLayout>
                            <TreadmillTagWrapper>
                                <TreadmillTag>
                                    <TreadmillTagText>걷기</TreadmillTagText>
                                </TreadmillTag>
                                <TreadmillTag>
                                    <TreadmillTagText>뛰기</TreadmillTagText>
                                </TreadmillTag>
                            </TreadmillTagWrapper>
                        </TreadmillTagLayout>
                        <TreadmillCircle $color={palette.Graph.good}>
                            <TreadmillCircleTitle>{targetBurnFatExercise.kilometerPerHour.toFixed(1)}</TreadmillCircleTitle>
                            <TreadmillCircleSubTitle>km/h</TreadmillCircleSubTitle>
                        </TreadmillCircle>
                        <TreadmillDescriptionWrapper>
                            <TreadmillDescription>지방 연소 목표</TreadmillDescription>
                            <TreadmillDescription>: 30분간 뛰기</TreadmillDescription>
                        </TreadmillDescriptionWrapper>
                        <TreadmillTitle>피로도 없음</TreadmillTitle>
                        <TreadmillCaption>= {targetBurnFatExercise.kcalPerMinute.toFixed(1)}kcal/ min</TreadmillCaption>
                    </TreadmillWrapper>
                    <TreadmillWrapper>
                        <TreadmillTagLayout>
                            <TreadmillTagWrapperOnlyRight>
                                <TreadmillTag>
                                    <TreadmillTagText>뛰기</TreadmillTagText>
                                </TreadmillTag>
                            </TreadmillTagWrapperOnlyRight>
                        </TreadmillTagLayout>
                        <TreadmillCircle $color={palette.SecondaryBlueWeak}>
                            <TreadmillCircleTitle $color='white'>{targetAerobicExercise.kilometerPerHour.toFixed(1)}</TreadmillCircleTitle>
                            <TreadmillCircleSubTitle $color='white'>km/h</TreadmillCircleSubTitle>
                        </TreadmillCircle>
                        <TreadmillDescriptionWrapper>
                            <TreadmillDescription>유산소 운동 목표</TreadmillDescription>
                        </TreadmillDescriptionWrapper>
                        <TreadmillTitle>피로도 중</TreadmillTitle>
                        <TreadmillCaption>= {targetAerobicExercise.kcalPerMinute.toFixed(1)}kcal/ min</TreadmillCaption>
                    </TreadmillWrapper>
                    <TreadmillWrapper>
                        <TreadmillTagLayout>
                            <TreadmillTagWrapperOnlyRight>
                                <TreadmillTag>
                                    <TreadmillTagText>뛰기</TreadmillTagText>
                                </TreadmillTag>
                            </TreadmillTagWrapperOnlyRight>
                        </TreadmillTagLayout>
                        <TreadmillCircle>
                            <TreadmillCircleTitle $color='white'>{targetHighIntensityExercise.kilometerPerHour.toFixed(1)}</TreadmillCircleTitle>
                            <TreadmillCircleSubTitle $color='white'>km/h</TreadmillCircleSubTitle>
                        </TreadmillCircle>
                        <TreadmillDescriptionWrapper>
                            <TreadmillDescription>고강도 운동 목표</TreadmillDescription>
                        </TreadmillDescriptionWrapper>
                        <TreadmillTitle>피로도 상</TreadmillTitle>
                        <TreadmillCaption>= {targetHighIntensityExercise.kcalPerMinute.toFixed(1)}kcal/ min</TreadmillCaption>
                    </TreadmillWrapper>
                </TreadmillLayout>
                <TableLayout>
                    <OutdoorGoalTableHeaderWrapper>
                        <TableHeaderCell>
                            <TableHeaderTitle>단계</TableHeaderTitle>
                            <TableSubTitle>Step</TableSubTitle>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <TableHeaderTitle>도전하기</TableHeaderTitle>
                            <TableSubTitle>Goal</TableSubTitle>
                        </TableHeaderCell>
                    </OutdoorGoalTableHeaderWrapper>
                    <TableBody>
                        <OutdoorGoalTableRowWrapper>
                            <TableBodyCell>
                                <TableCellText>1</TableCellText>
                            </TableBodyCell>
                            <TableBodyCell>
                                <TableCellText>
                                    {outdoorGoalLow.kilometer.toFixed(1)} <TableCellSubText $marginRight='8px'>km</TableCellSubText> ({outdoorGoalLow.minute}
                                    <TableCellSubText>분 이내</TableCellSubText>)
                                </TableCellText>
                            </TableBodyCell>
                        </OutdoorGoalTableRowWrapper>
                        <OutdoorGoalTableRowWrapper>
                            <TableBodyCell>
                                <TableCellText>2</TableCellText>
                            </TableBodyCell>
                            <TableBodyCell>
                                <TableCellText>
                                    {outdoorGoalMid.kilometer.toFixed(1)} <TableCellSubText $marginRight='8px'>km</TableCellSubText> ({outdoorGoalMid.minute}
                                    <TableCellSubText>분 이내</TableCellSubText>)
                                </TableCellText>
                            </TableBodyCell>
                        </OutdoorGoalTableRowWrapper>
                        <OutdoorGoalTableRowWrapper>
                            <TableBodyCell>
                                <TableCellText>3</TableCellText>
                            </TableBodyCell>
                            <TableBodyCell>
                                <TableCellText>
                                    {outdoorGoalHigh.kilometer.toFixed(1)} <TableCellSubText $marginRight='8px'>km</TableCellSubText> ({outdoorGoalHigh.minute}
                                    <TableCellSubText>분 이내</TableCellSubText>)
                                </TableCellText>
                            </TableBodyCell>
                        </OutdoorGoalTableRowWrapper>
                    </TableBody>
                </TableLayout>
                <TableLayout>
                    <MedicalPatientTableHeaderWrapper>
                        <TableHeaderCell>
                            <TableHeaderTitle>세이프 존</TableHeaderTitle>
                            <TableSubTitle>Safe Zone</TableSubTitle>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <TableHeaderTitle>질환</TableHeaderTitle>
                            <TableSubTitle>Disease</TableSubTitle>
                        </TableHeaderCell>
                    </MedicalPatientTableHeaderWrapper>
                    <TableBody>
                        <MedicalPatientTableRowWrapper>
                            <TableBodyCell>
                                <TableCellText>
                                    {safezoneGuideLow.min.toFixed(1)} ~ {safezoneGuideLow.max.toFixed(1)} <TableCellSubText>km</TableCellSubText>
                                </TableCellText>
                            </TableBodyCell>
                            <TableBodyCell>
                                <TableCellCaptionText>고혈압, 비만, 골다공증</TableCellCaptionText>
                                <TableCellCaptionText>암 질환자, 대사 증후근</TableCellCaptionText>
                            </TableBodyCell>
                        </MedicalPatientTableRowWrapper>
                        <MedicalPatientTableRowWrapper>
                            <TableBodyCell>
                                <TableCellText>
                                    {safezoneGuideHigh.min.toFixed(1)} ~ {safezoneGuideHigh.max.toFixed(1)} <TableCellSubText>km</TableCellSubText>
                                </TableCellText>
                            </TableBodyCell>
                            <TableBodyCell>
                                <TableCellCaptionText>폐 질환자, 당뇨병</TableCellCaptionText>
                                <TableCellCaptionText>심장 질환자, 이상지혈증</TableCellCaptionText>
                            </TableBodyCell>
                        </MedicalPatientTableRowWrapper>
                    </TableBody>
                </TableLayout>
            </BodyLayout>
        </ContainerLayout>
    );
};

const TableLayout = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 8px;
    border: 1.5px solid ${palette.PrimaryBlueStrong};
    & > :last-child {
        border: none;
    }
`;

const TableHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${palette.PrimaryBlueStrong};
    & > :last-child {
        border: none;
    }
`;

const TableBody = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    & > :last-child {
        border-bottom: none;
    }
`;

const TableRowWrapper = styled.div`
    display: flex;
    height: 100%;
    flex-direction: row;
    border-bottom: 0.5px solid ${BorderColor.color_border_neutral};
    & > :last-child {
        border-right: none;
    }
`;

const TableHeaderCell = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 6px;
    border-right: 0.5px solid ${BorderColor.color_border_neutral};
`;

const OutdoorGoalTableHeaderWrapper = styled(TableHeaderWrapper)`
    display: grid;
    grid-template-columns: 1fr 3fr;
`;

const MedicalPatientTableHeaderWrapper = styled(TableHeaderWrapper)`
    display: grid;
    grid-template-columns: 1fr 1.5fr;
`;

const OutdoorGoalTableRowWrapper = styled(TableRowWrapper)`
    display: grid;
    grid-template-columns: 1fr 3fr;
`;

const MedicalPatientTableRowWrapper = styled(TableRowWrapper)`
    display: grid;
    grid-template-columns: 1fr 1.5fr;
`;
const TableCellText = styled(Caption1_Bold)``;

const TableCellSubText = styled(Caption1_Bold)<{ $marginRight?: string }>`
    font-size: 10px;
    line-height: 12px;
    ${(props) => props.$marginRight && `margin-right: ${props.$marginRight}`}
`;

const TableCellCaptionText = styled(Caption1_SemiBold)`
    font-size: 8px;
    line-height: 14px;
`;

const TableBodyCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-right: 0.5px solid ${BorderColor.color_border_neutral};
    padding: 2px;
`;

const TableHeaderTitle = styled(Body4_SemiBold)`
    color: ${palette.basic.white[100]};
`;
const TableSubTitle = styled(Caption2_Regular)`
    color: ${palette.basic.white[100]};
    font-size: 10px;
    font-height: 10px;
    line-height: 10px;
`;

const InnerTitleLayout = styled.div`
    display: grid;
    grid-template-columns: 1.4fr 1fr 1.2fr;
    gap: 20px;
    border-bottom: 1px solid ${BorderColor.color_border_neutral};
`;

const InnerTitleWrapper = styled.div`
    padding-left: 6px;
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: baseline;
`;

const InnerTitle = styled(Body4_SemiBold)``;

const InnerSubTitle = styled(Caption2_Regular)`
    font-size: 10px;
    line-height: 12px;
`;

const BodyLayout = styled.div`
    display: grid;
    height: 120px;
    gap: 20px;
    grid-template-columns: 1.4fr 1fr 1.2fr;
`;

/// ----------------------Treadmill----------------------
const TreadmillLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    & > :nth-child(2) {
        border-left: 1px solid ${BorderColor.color_border_neutral};
        border-right: 1px solid ${BorderColor.color_border_neutral};
    }
`;

const TreadmillWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 6px;
`;

const TreadmillTagLayout = styled.div`
    position: relative;
    width: 100%;
    display: flex;
`;

const TreadmillTagWrapper = styled.div`
    position: absolute;
    top: 44px;
    width: 100%;
    padding: 0 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 1;
`;

const TreadmillTagWrapperOnlyRight = styled(TreadmillTagWrapper)`
    justify-content: flex-end;
`;

const TreadmillTag = styled.div`
    display: flex;
    border-radius: 6px;
    background-color: ${palette.SecondaryGray};
    padding: 2px 4px;
    align-items: center;
    justify-content: center;
`;

const TreadmillTagText = styled(Caption2_SemiBold)`
    color: ${palette.PrimaryBlueAccent};
`;

const TreadmillCircle = styled.div<{ $color?: string }>`
    width: 50px;
    height: 50px;
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${({ $color }) => $color || palette.PrimaryBlue};
`;
const TreadmillCircleTitle = styled(Body4_SemiBold)<{ $color?: string }>`
    ${(props) => props.$color && `color: ${props.$color}`}
`;
const TreadmillCircleSubTitle = styled(Caption2_Regular)<{ $color?: string }>`
    font-size: 8px;
    line-height: 5px;
    ${(props) => props.$color && `color: ${props.$color}`}
`;

const TreadmillDescription = styled(Caption1_Bold)`
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    color: ${palette.PrimaryBlueAccent};
`;

const TreadmillCaption = styled(Caption2_Regular)`
    font-size: 8px;
    line-height: 10px;
`;

const TreadmillDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 0.5;
`;

const TreadmillTitle = styled(Caption1_Bold)`
    color: ${palette.PrimaryBlueAccent};
`;
